import { useState } from "react";
import { useTranslation } from "react-i18next";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

// Icons
import { MdMail } from "react-icons/md";

// Firebase
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { useSnackbar } from "notistack";

interface Props {
    open: boolean;
    onClose: any;
}

export const ForgotPassword = (props: Props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (e: any) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                props.onClose();
                enqueueSnackbar(t("emailSent"), { variant: "success" });
            })
            .catch(err => {
                console.error("sending message error", err);
                if (err.code === "auth/invalid-email") enqueueSnackbar(t("emailInvalid"), { variant: "error" });
                else if (err.code === "auth/user-not-found") enqueueSnackbar(t("userNotFound"), { variant: "error" });
            });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
            <DialogTitle id="dialog-title" className="forgotPassword__title">
                {t("forgotPassword")}?
            </DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <DialogContentText className="forgotPassword__text">{t("forgotPasswordDesc")}</DialogContentText>
                    <TextField fullWidth variant="outlined" label={t("yourEmail")} value={email} onChange={e => setEmail(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" type="submit">
                        <MdMail className="addIcon" />
                        {t("send")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
