import React, { useEffect, useState } from "react";

// Interfaces
import { Participant } from "../../../interfaces/Participant";

// Charts
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title as ChartTitle,
    BarElement,
} from "chart.js";
import { Pie, Line, Bar } from "react-chartjs-2";

// Utils
import { calculateAge, weeksBetween } from "../../../utils/date";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, ChartTitle, Tooltip, Legend, BarElement);

const Home: React.FC = () => {
    const { setLoading, currentAdmin } = useFetchUser();

    // States
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [totalParticipantsBySiteData, setTotalParticipantsBySiteData] = useState<any>(null);
    const [participantsPerMonthData, setParticipantsPerMonthData] = useState<any>(null);
    const [participantsAgeAtRegistrationData, setParticipantsAgeAtRegistrationData] = useState<any>(null);
    const [participantsParticipation, setParticipantsParticipation] = useState<any>(null);

    const fetchParticipants = async () => {
        try {
            setLoading(true);

            const data = await getDocs(query(collection(firestore, "Participants"), where("pscid", "not-in", ["pilot", "test"])));

            setParticipants(data.docs.map(genDoc<Participant>()).filter(p => p.pscid));
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchTotalParticipantsBySite = () => {
        const pscids: string[] = [];

        participants.forEach(p => {
            if (p.pscid) pscids.push(p.pscid);
        });

        const data = {
            labels: ["Baycrest", "University of British Columbia", "New Brunswick", "Cape Breton", "Montreal", "Parkwood (MMO)", "Parkwood (Borrie)"],
            datasets: [
                {
                    label: "# of Participants",
                    data: [
                        pscids.filter(p => p.includes("byc")).length,
                        pscids.filter(p => p.includes("ubr")).length,
                        pscids.filter(p => p.includes("nbp")).length,
                        pscids.filter(p => p.includes("cbu")).length,
                        pscids.filter(p => p.includes("mtr")).length,
                        pscids.filter(p => p.includes("pgb")).length,
                        pscids.filter(p => p.includes("pcr")).length,
                    ],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 1,
                },
            ],
        };

        setTotalParticipantsBySiteData(data);
    };

    const fetchParticipantsPerMonth = () => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const data = {
            labels: monthNames,
            datasets: [
                {
                    label: "Baycrest",
                    data: [
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 0).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 1).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 2).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 3).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 4).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 5).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 6).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 7).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 8).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 9).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 10).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("byc") && new Date(p.createdAt).getMonth() === 11).length,
                    ],
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                    label: "University of British Columbia",
                    data: [
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 0).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 1).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 2).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 3).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 4).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 5).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 6).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 7).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 8).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 9).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 10).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("ubr") && new Date(p.createdAt).getMonth() === 11).length,
                    ],
                    borderColor: "rgba(54, 162, 235, 1)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
                {
                    label: "New Brunswick",
                    data: [
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 0).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 1).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 2).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 3).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 4).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 5).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 6).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 7).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 8).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 9).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 10).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("nbp") && new Date(p.createdAt).getMonth() === 11).length,
                    ],
                    borderColor: "rgba(255, 206, 86, 1)",
                    backgroundColor: "rgba(255, 206, 86, 0.2)",
                },
                {
                    label: "Montreal",
                    data: [
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 0).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 1).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 2).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 3).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 4).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 5).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 6).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 7).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 8).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 9).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 10).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("mtr") && new Date(p.createdAt).getMonth() === 11).length,
                    ],
                    borderColor: "rgba(75, 192, 192, 1)",
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                },
                {
                    label: "Parkwood (MMO)",
                    data: [
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 0).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 1).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 2).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 3).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 4).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 5).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 6).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 7).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 8).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 9).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 10).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pgb") && new Date(p.createdAt).getMonth() === 11).length,
                    ],
                    borderColor: "rgba(153, 102, 255, 1)",
                    backgroundColor: "rgba(153, 102, 255, 0.2)",
                },
                {
                    label: "Parkwood (Borrie)",
                    data: [
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 0).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 1).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 2).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 3).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 4).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 5).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 6).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 7).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 8).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 9).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 10).length,
                        participants.filter(p => p.createdAt && p.pscid && p.pscid.includes("pcr") && new Date(p.createdAt).getMonth() === 11).length,
                    ],
                    borderColor: "rgba(255, 159, 64, 1)",
                    backgroundColor: "rgba(255, 159, 64, 0.2)",
                },
            ],
        };

        setParticipantsPerMonthData(data);
    };

    const fetchParticipantsAgeAtRegistration = () => {
        const data = {
            labels: ["<60", "60-65", "65-70", "70-75", "75-80", "80-85", ">85"],
            datasets: [
                {
                    label: "Participants",
                    data: [
                        participants.filter(p => p.createdAt && p.dateOfBirth && calculateAge(new Date(p.dateOfBirth)) < 60).length,
                        participants.filter(
                            p =>
                                p.createdAt &&
                                p.dateOfBirth &&
                                calculateAge(new Date(p.dateOfBirth)) >= 60 &&
                                calculateAge(new Date(p.dateOfBirth)) < 65
                        ).length,
                        participants.filter(
                            p =>
                                p.createdAt &&
                                p.dateOfBirth &&
                                calculateAge(new Date(p.dateOfBirth)) >= 65 &&
                                calculateAge(new Date(p.dateOfBirth)) < 70
                        ).length,
                        participants.filter(
                            p =>
                                p.createdAt &&
                                p.dateOfBirth &&
                                calculateAge(new Date(p.dateOfBirth)) >= 70 &&
                                calculateAge(new Date(p.dateOfBirth)) < 75
                        ).length,
                        participants.filter(
                            p =>
                                p.createdAt &&
                                p.dateOfBirth &&
                                calculateAge(new Date(p.dateOfBirth)) >= 75 &&
                                calculateAge(new Date(p.dateOfBirth)) < 80
                        ).length,
                        participants.filter(
                            p =>
                                p.createdAt &&
                                p.dateOfBirth &&
                                calculateAge(new Date(p.dateOfBirth)) >= 80 &&
                                calculateAge(new Date(p.dateOfBirth)) <= 85
                        ).length,
                        participants.filter(p => p.createdAt && p.dateOfBirth && calculateAge(new Date(p.dateOfBirth)) > 85).length,
                    ],
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
            ],
        };

        setParticipantsAgeAtRegistrationData(data);
    };

    const computeRatio = (p: Participant) => {
        const today = new Date();
        let ratio = 0;
        let currentWeek = 1;

        if (p.cycleStart && today >= p.cycleStart) {
            currentWeek = weeksBetween(today, p.cycleStart);
        }

        if (currentWeek > 51) currentWeek = 51;

        // count total completed chapters
        const totalCompletedChapters =
            p.completedChapters["Brain Overview"].length +
            p.completedChapters["Cognitive"].length +
            p.completedChapters["Nutrition"].length +
            p.completedChapters["Physical"].length +
            p.completedChapters["Sleep"].length +
            p.completedChapters["Social Psychological"].length +
            p.completedChapters["Vascular Health"].length +
            p.completedChapters["Vision Hearing"].length;

        // count total expected chapters
        let totalExpectedChapters = 0;

        if (currentWeek === 1) totalExpectedChapters = 2;
        if (currentWeek === 2) totalExpectedChapters = 5;
        if (currentWeek === 3) totalExpectedChapters = 10;
        else {
            totalExpectedChapters = currentWeek * 4 - 2;
        }

        // return the total ratio
        ratio = totalCompletedChapters / totalExpectedChapters;

        // console.log("---------------------------");
        // console.log(p.id, totalCompletedChapters, totalExpectedChapters, currentWeek);
        // console.log("ratio", ratio);
        // console.log("---------------------------");
        return ratio;
    };

    const fetchParticipantParticipation = async () => {
        const data = {
            labels: [">80% of the chapters as intended", "60-80%", "40-60%", "20-40%", "0-20%"],
            datasets: [
                {
                    label: "Participants",
                    data: [
                        participants.filter(p => {
                            const ratio = computeRatio(p);

                            if (ratio > 0.8) return true;
                            else return false;
                        }).length,
                        participants.filter(p => {
                            const ratio = computeRatio(p);

                            if (ratio <= 0.8 && ratio > 0.6) return true;
                            else return false;
                        }).length,
                        participants.filter(p => {
                            const ratio = computeRatio(p);

                            if (ratio <= 0.6 && ratio > 0.4) return true;
                            else return false;
                        }).length,
                        participants.filter(p => {
                            const ratio = computeRatio(p);

                            if (ratio <= 0.4 && ratio > 0.2) return true;
                            else return false;
                        }).length,
                        participants.filter(p => {
                            const ratio = computeRatio(p);

                            if (ratio <= 0.2) return true;
                            else return false;
                        }).length,
                    ],
                    borderColor: "rgba(54, 162, 235, 1)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
            ],
        };

        setParticipantsParticipation(data);
    };

    useEffect(() => {
        fetchParticipants();
    }, []);

    useEffect(() => {
        fetchTotalParticipantsBySite();
        fetchParticipantsPerMonth();
        fetchParticipantsAgeAtRegistration();
        fetchParticipantParticipation();
    }, [participants]);

    return (
        <>
            {participants && (
                <div style={{ fontSize: 20, marginBottom: 20 }}>
                    Total participants: <span style={{ fontWeight: "bold" }}>{participants.length}</span>
                </div>
            )}

            {totalParticipantsBySiteData && (
                <div style={{ marginBottom: 50 }}>
                    <h4 style={{ marginBottom: 20 }}>Total Participants by Site</h4>
                    <Pie data={totalParticipantsBySiteData} style={{ maxHeight: 300 }} />
                </div>
            )}

            {participantsPerMonthData && (
                <div style={{ marginBottom: 50 }}>
                    <h4 style={{ marginBottom: 20 }}>New Participants per month</h4>
                    <Line
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: "top" as const,
                                },
                            },
                        }}
                        data={participantsPerMonthData}
                        style={{ maxHeight: 300 }}
                    />
                </div>
            )}

            {currentAdmin && currentAdmin.roles.includes("superAdmin") && participantsAgeAtRegistrationData && (
                <div style={{ marginBottom: 50 }}>
                    <h4 style={{ marginBottom: 20 }}>Age of participants</h4>
                    <Bar
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: "top" as const,
                                    display: false,
                                },
                            },
                        }}
                        data={participantsAgeAtRegistrationData}
                        style={{ maxHeight: 300 }}
                    />
                </div>
            )}

            {currentAdmin && currentAdmin.roles.includes("superAdmin") && participantsParticipation && (
                <div style={{ marginBottom: 50 }}>
                    <h4 style={{ marginBottom: 20 }}>Participant participation (chapters or weeks)</h4>
                    <Bar
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: "top" as const,
                                    display: false,
                                },
                                title: {
                                    text: "Chapter completion as intended",
                                    display: false,
                                },
                            },
                        }}
                        data={participantsParticipation}
                        style={{ maxHeight: 300 }}
                    />
                </div>
            )}
        </>
    );
};

export default Home;
