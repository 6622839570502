import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

// Mui
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// Custom components
import DrawerContent from "./DrawerContent";
import UserMenu from "../../general/UserMenu";

// Assets
import { MdMenu, MdChevronLeft } from "react-icons/md";
import logo from "../../../assets/logos/logoWhiteEN.png";

const DesktopDrawer: React.FC = () => {
    // States
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className="dashboard">
            <div className="drawer__container">
                <AppBar position="absolute" className={clsx("drawer__appBar", open && "drawer__appBar__shift")}>
                    <Toolbar className="drawer__toolbar">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx("drawer__menuButton", open && "drawer__menuButton__hidden")}
                        >
                            <MdMenu className="drawer__menu__icon" />
                        </IconButton>
                        <Typography component="h1" variant="h6" noWrap className="drawer__title">
                            {/* {settings.app.title} */}
                        </Typography>
                        <div className="nav__header__container">
                            <div className="nav__header__options">
                                <UserMenu />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx("drawer__paper", !open && "drawer__paper__close"),
                    }}
                    open={open}
                >
                    <div className="nav__logo__container">
                        <Link to="/dashboard">
                            <img src={logo} alt="placeholder" className="nav__logo" />
                        </Link>
                        <div className="drawer__toolbaricon">
                            <IconButton onClick={handleDrawerClose}>
                                <MdChevronLeft />
                            </IconButton>
                        </div>
                    </div>

                    <Divider />
                    <DrawerContent />
                </Drawer>
            </div>
        </div>
    );
};

export default DesktopDrawer;
