import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Mui core
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Custom components
import TabPanel from "../../../general/TabPanel";

// Tabs
import Profile from "./tabs/Profile";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb from "../../../../hooks/useDb";

// Icons
import { MdHome } from "react-icons/md";

const COLLECTION = "Users";

interface paramsType {
    id: string;
}

const tabs: any = [
    {
        label: "Profil",
        icon: <MdHome />,
        component: Profile,
    },
    {
        label: "Tab2",
        icon: <MdHome />,
        component: Tab2,
    },
    {
        label: "Tab3",
        icon: <MdHome />,
        component: Tab3,
    },
];

const User: React.FC = () => {
    const { id } = useParams<paramsType>();
    const { currentAdmin } = useFetchUser();
    const { onSnapshot } = useDb<AdminUser>(COLLECTION, currentAdmin);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const mobileScreenSize = 500;
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    // States
    const [user, setUser] = useState<AdminUser | null>(null);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        const unsub = onSnapshot({
            callback: (data: AdminUser) => {
                setUser(data);
            },
            id,
        });

        return () => unsub();
    }, []);

    return (
        <div>
            <div className="tab__background__color">
                <Tabs variant="fullWidth" value={tab} onChange={(e, v) => setTab(v)}>
                    {tabs.map((t: any) => (
                        <Tab key={t.label} icon={t.icon} label={screenWidth >= mobileScreenSize ? t.label : ""} />
                    ))}
                </Tabs>
            </div>
            {tabs.map((t: any, i: number) => (
                <TabPanel key={i} value={tab} index={i}>
                    <t.component user={user} />
                </TabPanel>
            ))}
        </div>
    );
};

export default User;
