import React from "react";

const Tab2: React.FC = () => {
    return (
        <>
            <h1>Tab2</h1>
        </>
    );
};

export default Tab2;
