import { useEffect, useState } from "react";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";
import { Log } from "../../../../interfaces/Log";

// Utils
import { formatDateIntoElapsedTime } from "../../../../utils/date";
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb from "../../../../hooks/useDb";

const COLLECTION = "Users";

const LogTableRows = () => {
    const { currentAdmin } = useFetchUser();
    const { onSnapshot } = useDb<AdminUser>(COLLECTION, currentAdmin);

    const [users, setUsers] = useState<AdminUser[]>([]);

    useEffect(() => {
        const unsub = onSnapshot({
            callback: setUsers,
            filters: [{ field: "isDeleted", operator: "in", value: [true, false] }],
        });

        return () => unsub();
    }, []);

    return [
        {
            header: "Date",
            body: (l: Log) => l.createdAt && formatDateIntoElapsedTime(l.createdAt),
        },
        {
            header: "User",
            body: (l: Log) => users.find(u => u.id === l.adminId)?.email,
        },
        {
            header: "Reason",
            body: (l: Log) => l.reason,
        },
    ];
};

export default LogTableRows;
