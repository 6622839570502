import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";

// Custom components
import AdminTable from "../../../general/SimpleTable";
import AdminDialog from "./AdminDialog";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Firebase
import UserTableRows from "./AdminsTableRows";
import SearchBar from "../../../general/SearchBar";

const COLLECTION = "Users";

const Users: React.FC = () => {
    const hist = useHistory();
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { firstFetch, fetchNextPage, fetchLastPage } = useDb<AdminUser>(COLLECTION, currentAdmin);
    const { onSnapshotWithDoc } = useDb<any>("", currentAdmin, null, "Metadata/Users");

    // States
    const [users, setUsers] = useState<AdminUser[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState<boolean>(false);

    const handleNextPage = async (item: any) => {
        if (users.length !== 0) {
            setLoading(true);
            await fetchNextPage(item, setUsers, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
            setLoading(false);
        }
    };

    const handlePreviousPage = async (item: any) => {
        setLoading(true);
        await fetchLastPage(item, setUsers, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
        setLoading(false);
    };

    const fetchCount = async () => {
        try {
            return onSnapshotWithDoc((data: any) => {
                setLoading(true);
                if (data) setCount(data.count);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        if (page < newPage) handleNextPage(users[users.length - 1]);
        else if (page > newPage) handlePreviousPage(users[0]);

        setPage(newPage);
    };

    const fetchFirstUsers = async () => {
        await firstFetch(setUsers, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
    };

    useEffect(() => {
        setLoading(true);
        fetchCount();
        fetchFirstUsers();
        setLoading(false);
    }, []);

    return (
        <>
            <SearchBar title="Admins" />

            <AdminTable
                loading={loading}
                data={users}
                count={count}
                rows={UserTableRows({ open, setOpen })}
                noMoreData={users.length <= count}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onChangePage={handlePageChange}
                onDoubleClick={(u: AdminUser) => hist.push(`/dashboard/admin/${u.id}`)}
            />
            {open && <AdminDialog open={open} setOpen={setOpen} userId={""} />}
        </>
    );
};

export default Users;
