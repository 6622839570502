import React, { useEffect, useState, useRef } from "react";
import FileSaver from "file-saver";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { useTranslation } from "react-i18next";

// Assets
import pdfEN from "../../../../assets/pdfs/notebookEN.pdf";
import pdfFR from "../../../../assets/pdfs/notebookFR.pdf";
import brainOverviewPdfEN from "../../../../assets/pdfs/brainOverviewNotebookEN.pdf";
import brainOverviewPdfFR from "../../../../assets/pdfs/brainOverviewNotebookFR.pdf";
import { ChapterTopic } from "../../../../interfaces/Chapter";
import star from "../../../../assets/icons/star.png";
import warning from "../../../../assets/icons/warning.png";

// Mui
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Interfaces
import { Stat, StatTopic } from "../../../../interfaces/Stat";
import { getCurrentStageNumber } from "../../../../utils/general";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb from "../../../../hooks/useDb";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdArrowDropDown } from "react-icons/md";

// Custom components
import Title from "../../../general/Title";
import GaugeCard, { Gauge } from "../../../general/GaugeCard";
import EditNotebook from "./EditNotebook";

// Utils
import settings from "../../../../settings.json";
import { getGaugeInfoAndDangerLevel, getIconFromTopic, getTitleFromTopic, translateTopic } from "../../../../utils/topic";

const Notebook: React.FC = () => {
    const { t } = useTranslation();
    const { currentParticipant, currentWeek, setLoading } = useFetchUser();
    const { getAll } = useDb<Stat>("Stats", null, currentParticipant);
    const anchorRef = useRef(null);

    // States
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedTopic, setSelectedTopic] = useState<StatTopic | null>(null);
    const [stats, setStats] = useState<Stat[]>([]);
    const [selectedTab, setSelectedTab] = useState<ChapterTopic>("Brain Overview");

    const options = [t("everyTopic")].concat(settings.app.topics);

    const parseIntoPdf = async (topic: string, gauges: any[], allTopics: boolean) => {
        if (allTopics) {
            const pdfs: PDFDocument[] = await generatePdfs(gauges);

            // Merge PDFs
            const mergedPdf = await PDFDocument.create();

            for (const pdf of pdfs) {
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                copiedPages.forEach(page => mergedPdf.addPage(page));
            }
            return await mergedPdf.save();
        } else if (topic === "Brain Overview") {
            return (await generateBrainOverviewPdf()).save();
        } else {
            return (await generatePdf(topic, gauges[0])).save();
        }
    };

    const generateBrainOverviewPdf = async () => {
        const icon = getIconFromTopic("Brain Overview");

        const existingPdfBytes = await fetch(localStorage.getItem("language") === "en" ? brainOverviewPdfEN : brainOverviewPdfFR).then(res =>
            res.arrayBuffer()
        );
        const template = await PDFDocument.load(existingPdfBytes);
        const form = template.getForm();

        const iconBytes = await fetch(icon).then(res => res.arrayBuffer());
        const warningBytes = await fetch(warning).then(res => res.arrayBuffer());

        // fetching the fields
        const titleTextfield = form.getTextField("title");
        const warning1Button = form.getButton("warning1");
        const warning2Button = form.getButton("warning2");
        const warning3Button = form.getButton("warning3");
        const topicButton = form.getButton("topicLogo");
        const prioritizedTopic1Textfield = form.getTextField("prioritizedTopic1");
        const prioritizedTopic2Textfield = form.getTextField("prioritizedTopic2");
        const prioritizedTopic3Textfield = form.getTextField("prioritizedTopic3");

        // setting the values
        topicButton.setImage(await template.embedPng(iconBytes));
        titleTextfield.setText(localStorage.getItem("language") === "en" ? "Overall Brain Health Habits" : "Habitudes générales de santé mentale");
        if (currentParticipant && currentParticipant.prioritizedTopics.length) {
            prioritizedTopic1Textfield.setText(getTitleFromTopic(currentParticipant.prioritizedTopics[0] as StatTopic));
            prioritizedTopic2Textfield.setText(getTitleFromTopic(currentParticipant.prioritizedTopics[1] as StatTopic));
            prioritizedTopic3Textfield.setText(getTitleFromTopic(currentParticipant.prioritizedTopics[2] as StatTopic));

            warning1Button.setImage(await template.embedPng(warningBytes));
            warning2Button.setImage(await template.embedPng(warningBytes));
            warning3Button.setImage(await template.embedPng(warningBytes));
        } else {
            const titleTextfield2 = form.createTextField("title2");
            titleTextfield2.setText(
                localStorage.getItem("language") === "en" ? "Overall Brain Health Habits" : "Habitudes générales de santé mentale"
            );

            titleTextfield2.addToPage(template.getPage(0), {
                x: 85,
                y: 640,
                height: 50,
                width: 300,
                borderWidth: 0,
            });
            titleTextfield2.setFontSize(15);
            titleTextfield2.enableReadOnly();

            form.removeField(warning1Button);
            form.removeField(warning2Button);
            form.removeField(warning3Button);
            titleTextfield.setText("");
        }

        form.flatten();

        return template;
    };

    const generatePdf = async (topic: string, gauge: any) => {
        let template = await PDFDocument.create();

        if (currentParticipant) {
            const icon = getIconFromTopic(topic as ChapterTopic);

            const existingPdfBytes = await fetch(localStorage.getItem("language") === "en" ? pdfEN : pdfFR).then(res => res.arrayBuffer());
            template = await PDFDocument.load(existingPdfBytes);
            const form = template.getForm();

            const iconBytes = await fetch(icon).then(res => res.arrayBuffer());
            let priorityBytes = new ArrayBuffer(0);

            if (currentParticipant.prioritizedTopics.includes(topic as ChapterTopic)) {
                priorityBytes = await fetch(star).then(res => res.arrayBuffer());
            }
            const gaugeBytes = await fetch(gauge).then(res => res.arrayBuffer());

            // fetching the fields
            const gaugeButton = form.getButton("gauge");
            const topicButton = form.getButton("topicLogo");
            const titleTextfield = form.getTextField("title");
            const priorityButton = form.getButton("priorityImage");
            const priorityDescriptionTextfield = form.getTextField("priorityDescription");
            const factTextfield = form.getTextField("fact");

            // setting the values
            gaugeButton.setImage(await template.embedPng(gaugeBytes));
            topicButton.setImage(await template.embedPng(iconBytes));
            titleTextfield.setText(getTitleFromTopic(topic as StatTopic));

            // PrioritizedTopics
            if (currentParticipant.prioritizedTopics.includes(topic as ChapterTopic)) {
                priorityButton.setImage(await template.embedPng(priorityBytes));
                priorityDescriptionTextfield.setText(localStorage.getItem("language") === "en" ? "Priority Topic" : "Thème Prioritaire");
            } else {
                form.removeField(priorityButton);
            }

            factTextfield.setText(
                localStorage.getItem("language") === "en"
                    ? `Use Brain Health to improve your ${getTitleFromTopic(topic as StatTopic).toLowerCase()}`
                    : `Utilise Santé Cerveau pour améliorer ${getTitleFromTopic(topic as StatTopic).toLowerCase()}`
            );

            form.flatten();
        }

        return template;
    };

    const generatePdfs = async (gauges: any[]) => {
        const pdfs: PDFDocument[] = [];
        const availableTopics = settings.app.topics.filter((t: any) => t !== "Brain Overview");

        pdfs.push(await generateBrainOverviewPdf());

        for (const [i, topic] of (availableTopics as any).entries()) {
            pdfs.push(await generatePdf(topic, gauges[i]));
        }

        return pdfs;
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const getGaugeImages = async () => {
        const gaugeImages = [];

        // Refs
        const gaugeCognitiveRef = document.getElementById("gaugeCognitive");
        const gaugeSocialPsychologicalRef = document.getElementById("gaugeSocialPsychological");
        const gaugeSleepRef = document.getElementById("gaugeSleep");
        const gaugeVascularRef = document.getElementById("gaugeVascular");
        const gaugeNutritionRef = document.getElementById("gaugeNutrition");
        const gaugePhysicalRef = document.getElementById("gaugePhysical");
        const gaugeVisionHearingRef = document.getElementById("gaugeVisionHearing");

        if (gaugeCognitiveRef) {
            const canvas = await html2canvas(gaugeCognitiveRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        if (gaugeSocialPsychologicalRef) {
            const canvas = await html2canvas(gaugeSocialPsychologicalRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        if (gaugeSleepRef) {
            const canvas = await html2canvas(gaugeSleepRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        if (gaugeVascularRef) {
            const canvas = await html2canvas(gaugeVascularRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        if (gaugeNutritionRef) {
            const canvas = await html2canvas(gaugeNutritionRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        if (gaugePhysicalRef) {
            const canvas = await html2canvas(gaugePhysicalRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        if (gaugeVisionHearingRef) {
            const canvas = await html2canvas(gaugeVisionHearingRef, {
                backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            gaugeImages.push(canvas.toDataURL("png"));
        }

        return gaugeImages;
    };

    const handleDownload = async () => {
        try {
            const gaugeImages = await getGaugeImages();

            setLoading(true);
            const payload = await parseIntoPdf(options[selectedIndex], gaugeImages, selectedIndex === 0);
            const data = new Blob([payload], { type: "application/pdf;charset=utf-8" });

            FileSaver.saveAs(
                data,
                selectedIndex === 0 ? "notebooks.pdf" : `${options[selectedIndex].toLowerCase().replace(" ", "").trim()}_notebook.pdf`
            );
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchStats = async () => {
        if (currentParticipant && currentParticipant.id) {
            const stageNumber = getCurrentStageNumber(currentWeek);
            const fetchedStats = await getAll({
                filters: [
                    { field: "participantId", operator: "==", value: currentParticipant.id },
                    { field: "stage", operator: "==", value: `stage${stageNumber}` },
                ],
            });

            setStats(fetchedStats as Stat[]);
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);

    const getGaugeValue = (optionIndex: number) => {
        const value = getGaugeInfoAndDangerLevel(
            options[optionIndex] as StatTopic,
            stats.length > 0 && stats.filter(s => s.topic === (options[optionIndex] as StatTopic)).length > 0
                ? stats.filter(s => s.topic === (options[optionIndex] as StatTopic))[0].score
                : 0
        ).gaugeValue;

        if (value) return value;
        else return 0;
    };

    useEffect(() => {
        if (currentParticipant && currentParticipant.id && currentWeek > 0) {
            fetchStats();
        }
    }, [currentParticipant, currentWeek]);

    return (
        <>
            <div className="notebook__header">
                <Grid item container>
                    <Grid item xs={12}>
                        <Title>{t("notebook")}</Title>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <p>{t("exportInfo")}</p>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                            <Button onClick={handleDownload}>{options[selectedIndex]}</Button>
                            <Button
                                size="small"
                                aria-controls={open ? "split-button-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={() => setOpen(prevOpen => !prevOpen)}
                            >
                                <MdArrowDropDown />
                            </Button>
                        </ButtonGroup>
                        <Popper className="notebook__popper" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                                            <MenuList id="split-button-menu" defaultValue="Every topic">
                                                {options.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        selected={index === selectedIndex}
                                                        onClick={event => handleMenuItemClick(event, index)}
                                                    >
                                                        {translateTopic(option)}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginTop: 20 }}>
                <p>{t("notebookInfo1")}</p>
                <ol>
                    <li>
                        {t("notebookInfo2")}
                        <ul>
                            <li>{t("notebookInfo3")}</li>
                            <li>{t("notebookInfo4")}</li>
                            <li>{t("notebookInfo5")}</li>
                        </ul>
                    </li>
                    <li>{t("notebookInfo6")}</li>
                    <li>{t("notebookInfo7")}</li>
                </ol>
            </div>

            <Box sx={{ width: "100%" }}>
                <Tabs
                    variant="fullWidth"
                    value={selectedTab}
                    onChange={(event: React.SyntheticEvent, newValue: ChapterTopic) => setSelectedTab(newValue)}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    {[
                        "Brain Overview",
                        "Physical",
                        "Cognitive",
                        "Nutrition",
                        "Sleep",
                        "Social Psychological",
                        "Vascular Health",
                        "Vision Hearing",
                    ].map(t => (
                        <Tab
                            value={t}
                            key={t}
                            label={
                                <>
                                    <IconButton>
                                        <img src={getIconFromTopic(t as ChapterTopic)} alt={t} style={{ maxWidth: 35 }} />
                                    </IconButton>
                                    <span style={{ minHeight: 40 }}>
                                        {localStorage.getItem("language") === "en" ? t : translateTopic(t as ChapterTopic)}
                                    </span>
                                </>
                            }
                        />
                    ))}
                </Tabs>
            </Box>

            <div className="topicsFavourited">
                <Grid container>
                    <>
                        {selectedTab === "Brain Overview" && (
                            <div className="tf__cardContainer">
                                <h5>{t("brainOverviewTitle")}</h5>
                                <br />
                                <EditNotebook topic="Brain Overview" />
                            </div>
                        )}

                        {selectedTab === "Physical" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("physicalTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Physical");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Physical" />
                            </div>
                        )}

                        {selectedTab === "Cognitive" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("cognitiveTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Cognitive");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Cognitive" />
                            </div>
                        )}

                        {selectedTab === "Nutrition" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("nutritionTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Nutrition");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Nutrition" />
                            </div>
                        )}

                        {selectedTab === "Sleep" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("sleepTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Sleep");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Sleep" />
                            </div>
                        )}

                        {selectedTab === "Social Psychological" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("socialTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Social Psychological");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Social Psychological" />
                            </div>
                        )}

                        {selectedTab === "Vascular Health" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("vascularTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Vascular Health");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Vascular Health" />
                            </div>
                        )}

                        {selectedTab === "Vision Hearing" && (
                            <div className="tf__cardContainer">
                                <h5>
                                    {t("visionTitle")}
                                    <IconButton
                                        color="primary"
                                        style={{ cursor: "pointer" }}
                                        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={e => {
                                            handlePopoverOpen(e);
                                            setSelectedTopic("Vision Hearing");
                                        }}
                                        onMouseLeave={() => {
                                            handlePopoverClose();
                                            setSelectedTopic(null);
                                        }}
                                    >
                                        <GrCircleInformation style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </h5>
                                <br />
                                <EditNotebook topic="Vision Hearing" />
                            </div>
                        )}
                    </>
                </Grid>
            </div>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                style={{ backgroundColor: "transparent", zIndex: 1000 }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
            >
                {stats.length > 0 && selectedTopic && (
                    <GaugeCard
                        topic={selectedTopic}
                        score={stats.find(s => s.topic === selectedTopic)?.score ?? 0}
                        title={selectedTopic ? getTitleFromTopic(selectedTopic) : ""}
                        isClickable={false}
                        hasAnimation={false}
                    />
                )}
            </Popover>

            <div style={{ maxWidth: 300, position: "absolute", top: -99999 }}>
                <div id="gaugeCognitive">
                    <Gauge gaugeValue={getGaugeValue(1)} />
                </div>
                <div id="gaugeSocialPsychological">
                    <Gauge gaugeValue={getGaugeValue(2)} />
                </div>
                <div id="gaugeSleep">
                    <Gauge gaugeValue={getGaugeValue(3)} />
                </div>
                <div id="gaugeVascular">
                    <Gauge gaugeValue={getGaugeValue(4)} />
                </div>
                <div id="gaugeNutrition">
                    <Gauge gaugeValue={getGaugeValue(5)} />
                </div>
                <div id="gaugePhysical">
                    <Gauge gaugeValue={getGaugeValue(6)} />
                </div>
                <div id="gaugeVisionHearing">
                    <Gauge gaugeValue={getGaugeValue(7)} />
                </div>
            </div>
        </>
    );
};

export default Notebook;
