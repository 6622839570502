import React from "react";
import { useTranslation } from "react-i18next";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen: (value: boolean) => void;
}

const Disclaimer: React.FC<Props> = ({ open, setOpen }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open}>
            <DialogTitle>{t("disclaimer")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p style={{ textAlign: "justify" }}>{t("disclaimerText")}</p>
                    <br />
                    <p style={{ textAlign: "justify" }}>{t("disclaimerText2")}</p>
                    <br />
                    <p style={{ textAlign: "justify" }}>{t("disclaimerText3")}</p>
                    <br />
                    <p style={{ textAlign: "justify" }}>{t("disclaimerText4")}</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>
                    {t("agree")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Disclaimer;
