import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Utils
import { switchLanguage } from "../../utils/language";

// Components
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

// Assets
import logoEN from "../../assets/logos/navLogoEN.png";
import logoFR from "../../assets/logos/navLogoFR.png";
import smallLogo from "../../assets/logos/smallLogo.png";
import theme from "../../assets/nav/theme.png";

const Nav = () => {
    const { t } = useTranslation();
    const { pathname }: any = useLocation();

    // States
    const [siteWidth, setSiteWidth] = useState(window.innerWidth);
    const [language, setLanguage] = useState(localStorage.getItem("language") || "en");

    const handleSize = () => {
        setSiteWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // toggle theme
    const changeTheme = () => {
        const divColor = (document.getElementById("color") as any).classList;
        if (divColor.contains("normal")) {
            divColor.replace("normal", "inverse");
            localStorage.setItem("theme", "inverse");
        } else {
            divColor.replace("inverse", "normal");
            localStorage.setItem("theme", "normal");
        }
    };

    // toggle font
    const changeFont = (value: string) => {
        const divFont = (document.getElementById("font") as any).classList;
        if (value === "font1") {
            divFont.add("font1");
            divFont.remove("font2", "font3");
            localStorage.setItem("font", "font1");
        } else if (value === "font2") {
            divFont.add("font2");
            divFont.remove("font1", "font3");
            localStorage.setItem("font", "font2");
        } else if (value === "font3") {
            divFont.add("font3");
            divFont.remove("font1", "font2");
            localStorage.setItem("font", "font3");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("language")) {
            const lang = localStorage.getItem("language");
            if (lang) setLanguage(lang);
        } else {
            setLanguage("en");
        }
    }, []);

    return (
        <div className="nav">
            <div className="nav__container">
                <div className="nav__logo">
                    {siteWidth > 1224 ? (
                        <Link to="/">
                            <img src={localStorage.getItem("language") === "en" ? logoEN : logoFR} alt="" style={{ maxHeight: 125 }} />
                        </Link>
                    ) : (
                        <Link to="/">
                            <img src={smallLogo} alt="" />
                        </Link>
                    )}
                </div>
                <div className="nav__content">
                    <div className="nav__content__fontSize">
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select className="sizeSelector" value={"none"} onChange={e => changeFont(e.target.value)} autoWidth>
                                <MenuItem disabled value="none">
                                    <span className="nav__fontSize--placeholder">{t("textSize")}</span>
                                </MenuItem>
                                <MenuItem value="font1">
                                    <span className="nav__fontSize--font1">Aa</span>
                                </MenuItem>
                                <MenuItem value="font2">
                                    <span className="nav__fontSize--font2">Aa</span>
                                </MenuItem>
                                <MenuItem value="font3">
                                    <span className="nav__fontSize--font3">Aa</span>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="nav__content__theme">
                        <Button onClick={changeTheme} size="medium">
                            <img src={theme} alt="" />
                        </Button>
                    </div>
                    <div className="nav__content__fontSize">
                        <FormControl>
                            <Select className="nav__button__textSize" value={language} onChange={() => switchLanguage(setLanguage)} autoWidth>
                                <MenuItem value="en">
                                    <span className="nav__fontSize--placeholder">English</span>
                                </MenuItem>
                                <MenuItem value="fr">
                                    <span className="nav__fontSize--placeholder">Français</span>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="nav__content__login">
                        <Link to="/login">
                            <Button size="medium">
                                <span className="nav__button__textSize">{t("login")}</span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;
