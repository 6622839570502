export const selfEfficacy = {
    titleFR: "Perception de votre santé",
    titleEN: "Your health views",
    aboutFR:
        "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter.. Essayez de terminer le questionnaire en une seule séance.",
    aboutEN:
        "These questions will help us to know you better and identify areas of progress. This should take about 10 minutes. Try to complete this task in one session.",
    instructionsFR: "Veuillez indiquer votre opinion sur chaque énoncé. Répondez à chaque question au mieux de vos capacités.",
    instructionsEN: "Rate how true each statement is for you. Answer each question to the best of your abilities.",
    questions: [
        {
            questionFR: "Je peux toujours résoudre des problèmes complexes si j’y mets l’effort.",
            questionEN: "I can always manage to solve difficult problems if I try hard enough.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Si quelqu’un s’oppose à moi, je finis toujours par trouver les moyens d’obtenir ce que je veux.",
            questionEN: "If someone opposes me, I can find the means and ways to get what I want.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Il est facile pour moi de m’en tenir à mes objectifs et d’accomplir mes buts.",
            questionEN: "It is easy for me to stick to my aims and accomplish my goals.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Je suis convaincu que je pourrais faire face efficacement à des événements inattendus.",
            questionEN: "I am confident that I could deal efficiently with unexpected events.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Grâce à mon ingéniosité, je sais comment gérer les situations imprévues",
            questionEN: "Thanks to my resourcefulness, I know how to handle unforeseen situations.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Je peux résoudre la plupart des problèmes si j’investis les efforts nécessaires.",
            questionEN: "I can solve most problems if I invest the necessary effort.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Je peux rester calme face aux difficultés parce que je peux compter sur mes capacités d’adaptation.",
            questionEN: "I can remain calm when facing difficulties because I can rely on my coping abilities.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Lorsque je suis confronté à un problème, je peux généralement trouver plusieurs solutions.",
            questionEN: "When I am confronted with a problem, I can usually find several solutions.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Si j’ai des ennuis, je peux habituellement trouver une solution.",
            questionEN: "If I am in trouble, I can usually think of a solution.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "La plupart du temps, je suis capable de gérer ce qui m’arrive.",
            questionEN: "I can usually handle whatever comes my way.",
            correctAnswer: "",
            answers: [],
        },
    ],
    answers: [
        { labelFR: "Pas du tout vrai", labelEN: "Not at all true", pointValue: 1 },
        { labelFR: "À peine vrai", labelEN: "Hardly true", pointValue: 2 },
        { labelFR: "Modérément vrai", labelEN: "Moderately true", pointValue: 3 },
        { labelFR: "Tout à fait vrai", labelEN: "Exactly true", pointValue: 4 },
    ],
};

export const dementiaLiteracy = {
    titleFR: "Comprendre la démence",
    titleEN: "Dementia Facts",
    aboutFR:
        "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Quinze minutes devraient être nécessaires pour le compléter.. Essayez de terminer le questionnaire en une seule séance.",
    aboutEN:
        "These questions will help us to know you better and see your progress. This should take about 15 minutes. Try to complete this task in one session.",
    instructionsFR: "Indiquez si chaque énoncé est vrai ou faux. Répondez à chaque question au mieux de vos capacités.",
    instructionsEN: "Rate whether each statement is true or false.  Answer each question to the best of your abilities.",
    questions: [
        {
            questionFR: "Les personnes atteintes de la maladie d'Alzheimer sont particulièrement sujettes à la dépression.",
            questionEN: "People with Alzheimer’s disease are particularly prone to depression.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "Il a été scientifiquement prouvé que l’exercice mental peut prévenir l’apparition de la maladie d’Alzheimer.",
            questionEN: "It has been scientifically proven that mental exercise can prevent a person from getting Alzheimer’s disease.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "Après l’apparition des symptômes de la maladie d’Alzheimer, l’espérance de vie moyenne est de 6 à 12 ans.",
            questionEN: "After symptoms of Alzheimer’s disease appear, the average life expectancy is 6 to 12 years.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Quand une personne avec la maladie d’Alzheimer devient agitée, un examen médical peut révéler d’autres problèmes de santé pouvant avoir causé l’agitation.",
            questionEN:
                "When a person with Alzheimer’s disease becomes agitated, a medical examination might reveal other health problems that caused the agitation.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Les personnes atteintes de la maladie d’Alzheimer performent mieux avec des instructions simples, présentant une étape à la fois.",
            questionEN: "People with Alzheimer’s disease do best with simple, instructions giving one step at a time.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Quand les personnes atteintes de la maladie d’Alzheimer commencent à avoir de la difficulté à s’occuper d’elles-mêmes, des soignants devraient prendre la relève immédiatement.",
            questionEN:
                "When people with Alzheimer’s disease begin to have difficulty taking care of themselves, caregivers should take over right away.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR:
                "Si une personne atteinte de la maladie d'Alzheimer devient alerte et agitée la nuit, une bonne stratégie consiste à essayer de faire en sorte que la personne fasse beaucoup d'activité physique pendant la journée.",
            questionEN:
                "If a person with Alzheimer’s disease becomes alert and agitated at night, a good strategy is to try to make sure that the person gets plenty of physical activity during the day.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "Dans de rares cas, des personnes ont guéri de la maladie d’Alzheimer.",
            questionEN: "In rare cases, people have recovered from Alzheimer’s disease.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR:
                "Les personnes dont la maladie d’Alzheimer n’est pas encore à un stade avancé peuvent bénéficier de la psychothérapie pour la dépression et l’anxiété.",
            questionEN: "People whose Alzheimer’s disease is not yet severe can benefit from psychotherapy for depression and anxiety.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Si des problèmes de mémoire et de la confusion apparaissent soudainement, cela est probablement dû à la maladie d’Alzheimer.",
            questionEN: "If trouble with memory and confused thinking appears suddenly, it is likely due to Alzheimer’s disease.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "La majorité des personnes atteintes de la maladie d’Alzheimer vivent en centre de soins de longue durée.",
            questionEN: "Most people with Alzheimer’s disease live in nursing homes.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "Une mauvaise nutrition peut empirer les symptômes de la maladie d’Alzheimer.",
            questionEN: "Poor nutrition can make the symptoms of Alzheimer’s disease worse.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "Des personnes dans la trentaine peuvent avoir la maladie d’Alzheimer.",
            questionEN: "People in their 30s can have Alzheimer’s disease.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "Une personne atteinte de la maladie d’Alzheimer devient plus sujette aux chutes avec la progression de la maladie.",
            questionEN: "A person with Alzheimer’s disease becomes increasingly likely to fall down as the disease gets worse.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Quand les personnes atteintes de la maladie d’Alzheimer répètent la même question ou la même histoire, il est utile de leur rappeler qu’elles se répètent.",
            questionEN:
                "When people with Alzheimer’s disease repeat the same question or story several times, it is helpful to remind them that they are repeating themselves.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR:
                "Une fois que les personnes sont atteintes de la maladie d’Alzheimer, elles ne sont plus capables de prendre des décisions éclairées au sujet de leurs propres soins.",
            questionEN: "Once people have Alzheimer’s disease, they are no longer capable of making informed decisions about their own care.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "Éventuellement, une personne atteinte de la maladie d’Alzheimer aura besoin de supervision 24h/24.",
            questionEN: "Eventually, a person with Alzheimer’s disease will need 24­hour supervision.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "Avoir un haut taux de cholestérol pourrait accroître le risque d’une personne de développer la maladie d’Alzheimer.",
            questionEN: "Having high cholesterol may increase a person’s risk of developing Alzheimer’s disease.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Les tremblements au niveau des mains ou des bras sont des symptômes fréquents chez les personnes atteintes de la maladie d’Alzheimer.",
            questionEN: "Tremor or shaking of the hands or arms is a common symptom in people with Alzheimer’s disease.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "Les symptômes d’une dépression sévère peuvent être confondus avec ceux de la maladie d’Alzheimer.",
            questionEN: "Symptoms of severe depression can be mistaken for symptoms of Alzheimer’s disease.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "La maladie d’Alzheimer est un type de démence.",
            questionEN: "Alzheimer’s disease is one type of dementia.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Des difficultés à s’occuper de ses finances et à payer ses factures sont des manifestations communes et précoces de la maladie d’Alzheimer.",
            questionEN: "Trouble handling money or paying bills is a common early symptom of Alzheimer’s disease.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Un des symptômes de la maladie d’Alzheimer est la croyance, par la personne atteinte, que d’autres personnes volent ses choses.",
            questionEN: "One symptom that can occur with Alzheimer’s disease is believing that other people are stealing one’s things.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Quand une personne est atteinte de la maladie d’Alzheimer, utiliser des notes ou des mémos est une béquille qui peut contribuer davantage au déclin.",
            questionEN: "When a person has Alzheimer’s disease, using reminder notes is a crutch that can contribute to decline.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "Des médicaments sur ordonnance sont disponibles pour prévenir la maladie d’Alzheimer.",
            questionEN: "Prescription drugs that prevent Alzheimer’s disease are available.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "Avoir une haute pression sanguine pourrait accroître le risque d’une personne de développer la maladie d’Alzheimer.",
            questionEN: "Having high blood pressure may increase a person’s risk of developing Alzheimer’s disease.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR: "La génétique ne peut expliquer que partiellement le développement de la maladie d’Alzheimer.",
            questionEN: "Genes can only partially account for the development of Alzheimer’s disease.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "Il est sécuritaire pour une personne avec la maladie d’Alzheimer de conduire, pourvu qu’elle soit accompagnée en tout temps.",
            questionEN: "It is safe for people with Alzheimer’s disease to drive, as long as they have a companion in the car at all times.",
            correctAnswer: "False",
            answers: [],
        },
        {
            questionFR: "La maladie d’Alzheimer ne peut pas être guérie.",
            questionEN: "Alzheimer's disease cannot be cured.",
            correctAnswer: "True",
            answers: [],
        },
        {
            questionFR:
                "La plupart des personnes ayant la maladie d’Alzheimer se rappellent mieux d’événements récents que de choses s’étant produites par le passé.",
            questionEN: "Most people with Alzheimer’s disease remember recent events better than things that happened in the past.",
            correctAnswer: "False",
            answers: [],
        },
    ],
    answers: [
        { labelFR: "Vrai", labelEN: "True" },
        { labelFR: "Faux", labelEN: "False" },
    ],
};

export const attitudesTowardDementia = {
    titleFR: "Votre vision de la démence",
    titleEN: "Your View of Dementia",
    aboutFR:
        "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Quinze minutes devraient être nécessaires pour le compléter.. Essayez de terminer le questionnaire en une seule séance.",
    aboutEN:
        "These questions will help us to know you better and see your progress. This should take about 15 minutes. Try to complete this task in one session.",
    instructionsFR:
        "Indiquez dans quelle mesure vous êtes en accord ou en désaccord avec chaque énoncé. Répondez à chaque question au mieux de vos capacités.",
    instructionsEN: "Rate how much you agree or disagree with each statement. Answer each question to the best of your abilities.",
    questions: [
        {
            questionFR: "J’aimerais savoir si je suis plus à risque de développer la maladie d’Alzheimer que d’autres personnes.",
            questionEN: "I would like to know if I am at higher risk than others for developing Alzheimer’s disease.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "J’aimerais savoir si je suis atteint de la maladie d’Alzheimer.",
            questionEN: "I would like to know if I have Alzheimer’s Disease.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "J’aimerais savoir si j’ai un problème avec ma mémoire.",
            questionEN: "I would like to know if I have a problem with my memory.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "J’aimerais être testé pour la présence de maladie d’Alzheimer sur une base régulière, à l’aide d’un court questionnaire.",
            questionEN: "I would like to be tested for the presence of Alzheimer’s disease on a regular basis with a short questionnaire.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "J’aimerais être testé pour la présence de maladie d’Alzheimer sur une base régulière, à l’aide d’un échantillon sanguin.",
            questionEN: "I would like to be tested for the presence of Alzheimer’s disease on a regular basis with a blood sample.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR:
                "J’aimerais être testé pour la présence de maladie d’Alzheimer sur une base régulière, à l’aide d’images de ma tête ou de mon cerveau obtenues par tomodensitométrie (CT-scan) ou imagerie par résonance magnétique (IRM).J’aimerais être testé pour la présence de maladie d’Alzheimer sur une base régulière, à l’aide d’images de ma tête ou de mon cerveau obtenues par tomodensitométrie (CT-scan) ou imagerie par résonance magnétique (IRM).",
            questionEN:
                "I would like to be tested for the presence of Alzheimer’s disease on a regular basis with pictures of my head or brain (CT-scan or MRI).",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "J’aimerais qu’un médecin m’examine chaque année afin de savoir si j’ai développé des problèmes de mémoire.",
            questionEN: "I would like a doctor to examine me every year to know if I have developed memory problems.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "J’aimerais qu’un médecin m’examine chaque année afin de savoir si j’ai développé la maladie d’Alzheimer.",
            questionEN: "I would like a doctor to examine me every year to know if I have developed Alzheimer’s disease.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Je ne crois pas qu’il y ait un traitement pour la maladie d’Alzheimer présentement disponible.",
            questionEN: "I do not believe that treatment for Alzheimer’s disease is currently available.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 0 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 0 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 0 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 0 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 0 },
            ],
        },
        {
            questionFR: "Je crois que la détection précoce de la maladie d’Alzheimer augmente les chances de mieux traiter la maladie.",
            questionEN: "I believe that early detection of Alzheimer’s disease increases the chance to treat the disease better.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, ma famille souffrirait financièrement.",
            questionEN: "If I had Alzheimer’s disease, my family would suffer financially.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, ma famille souffrirait émotionnellement.",
            questionEN: "If I had Alzheimer’s disease, my family would suffer emotionally.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR:
                "Si j’apprenais que j’étais atteint de la maladie d’Alzheimer plus tôt, ma famille aurait une meilleure chance de prendre soin de moi.",
            questionEN: "If I knew that I had Alzheimer’s disease earlier, my family would have a better chance to take care of me.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je ne voudrais pas que ma famille soit au courant.",
            questionEN: "If I had Alzheimer’s disease, I would not want my family to know.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR:
                "Si j’étais atteint de la maladie d’Alzheimer, je me sentirais humilié par les membres de ma famille et/ou d’autres personnes qui me traitent mal ou se moquent de moi.",
            questionEN:
                "If I had Alzheimer’s disease, I would feel humiliated by my family members and/or others who would treat me poorly or laugh at me.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, on ne me prendrait plus au sérieux.",
            questionEN: "If I had Alzheimer’s disease, I would no longer be taken seriously.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, on penserait que je suis stupide et incapable de faire des choses.",
            questionEN: "If I had Alzheimer’s disease, I would be considered stupid and unable to do things.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si je savais que j’étais atteint de la maladie d’Alzheimer, j’aurais honte ou je me sentirais embarrassé.",
            questionEN: "If I knew that I had Alzheimer’s disease, I would be ashamed or embarrassed.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si je savais que j’étais atteint de la maladie d’Alzheimer, je serais déprimé.",
            questionEN: "If I knew that I had Alzheimer’s disease, I would be depressed.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si je savais que j’étais atteint de la maladie d’Alzheimer, je serais anxieux.",
            questionEN: "If I knew that I had Alzheimer’s disease, I would be anxious.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si je savais que j’étais atteint de la maladie d’Alzheimer, je perdrais espoir en la vie.",
            questionEN: "If I knew that I had Alzheimer’s disease, I would give up on life.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR:
                "Si je découvrais précocement que j’étais atteint de la maladie d’Alzheimer, j’aurais plus de temps pour planifier mon futur.",
            questionEN: "If I found out early that I had Alzheimer’s disease, I would have more time to plan my future.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR:
                "Si je découvrais précocement que j’étais atteint de la maladie d’Alzheimer, j’aurais plus de temps pour discuter de mes soins de santé avec ma famille.",
            questionEN: "If I found out early that I had Alzheimer’s disease, I would have more time to talk with my family about my health care.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR:
                "Si je découvrais précocement que j’étais atteint de la maladie d’Alzheimer, j’aurais plus de temps pour discuter de mes finances avec ma famille.",
            questionEN: "If I found out early that I had Alzheimer’s disease, I would have more time to talk with my family about my finances.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR:
                "Si je découvrais précocement que j’étais atteint de la maladie d’Alzheimer, je signerais mon mandat d’inaptitude ou mon testament de vie.",
            questionEN: "If I found out early that I had Alzheimer’s disease, I would sign my advance directive or my living-will.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR:
                "Si j’étais atteint de la maladie d’Alzheimer, mon médecin ne me procurerait pas les meilleurs soins pour mes autres problèmes médicaux.",
            questionEN: "If I had Alzheimer’s disease, my doctor would not provide the best care for my other medical problems.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR:
                "Si je découvrais précocement que j’étais atteint de la maladie d’Alzheimer, je serais motivé à mener un mode de vie plus sain.",
            questionEN: "If I knew that I had Alzheimer’s disease earlier, I would be motivated to have a healthier lifestyle.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, mon médecin et les autres professionnels de la santé ne m’écouteraient pas.",
            questionEN: "If I had Alzheimer’s disease, my doctor and other health professionals would not listen to me.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR:
                "Si je découvrais précocement que j’étais atteint de la maladie d’Alzheimer, je serais plus intéressé à participer à des projets de recherche concernant cette maladie.",
            questionEN: "If I knew that I had Alzheimer’s disease earlier, I would be more willing to participate in research about this disease.",
            correctAnswer: "",
            answers: [],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je ne pourrais pas me procurer d’assurance santé.",
            questionEN: "If I had Alzheimer’s disease, I would not be able to get health insurance.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je serais inquiet que ma compagnie d’assurance santé puisse l’apprendre.",
            questionEN: "If I had Alzheimer’s disease, I would be concerned that my health insurance company would find out.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je serais inquiet que mon employeur puisse l’apprendre ",
            questionEN: "If I had Alzheimer’s disease, I would be concerned that my employer would find out.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je ne pourrais pas me procurer d’assurance vie.",
            questionEN: "If I had Alzheimer’s disease, I would not be able to get life insurance.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je ne pourrais pas me procurer d’assurance pour mes soins de longue durée.",
            questionEN: "If I had Alzheimer’s disease, I would not be able to get long-term care insurance.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je perdrais ma maison.",
            questionEN: "If I had Alzheimer’s disease, I would lose my home.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je vivrais dans une résidence/en hébergement de soins longue durée.",
            questionEN: "If I had Alzheimer’s disease, I would be living in a nursing home.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
        {
            questionFR: "Si j’étais atteint de la maladie d’Alzheimer, je perdrais mon permis de conduire et d’autres privilèges.",
            questionEN: "If I had Alzheimer’s disease, I would lose my driver’s license and other privileges.",
            correctAnswer: "",
            answers: [
                { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 1 },
                { labelFR: "D'accord", labelEN: "Agree", pointValue: 2 },
                { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
                { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 4 },
                { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 5 },
            ],
        },
    ],
    answers: [
        { labelFR: "Tout à fait d'accord", labelEN: "Strongly agree", pointValue: 5 },
        { labelFR: "D'accord", labelEN: "Agree", pointValue: 4 },
        { labelFR: "Je ne sais pas", labelEN: "I don’t know", pointValue: 3 },
        { labelFR: "En désaccord", labelEN: "Disagree", pointValue: 2 },
        { labelFR: "Tout à fait en désaccord", labelEN: "Strongly disagree", pointValue: 1 },
    ],
};

export const EXAMPLE = {
    titleFR: "",
    titleEN: "",
    aboutFR: "",
    aboutEN: "",
    instructionsFR: "",
    instructionsEN: "",
    questions: [
        {
            questionFR: "",
            questionEN: "",
            correctAnswer: "",
            answers: [],
        },
    ],
    answers: [
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
    ],
};
