import { collection, getDocs, query, where } from "firebase/firestore";
import { Participant } from "../interfaces/Participant";
import { firestore } from "../firebase/firebase";

export const stage5Completed = async (participant: Participant) => {
    try {
        let completed = false;

        const statsPayload = await getDocs(
            query(collection(firestore, "Stats"), where("participantId", "==", participant.id), where("stage", "==", "stage5"))
        );
        const stats = statsPayload.docs.map(x => x.data());

        if (
            // Lifestyle
            stats.some(s => s.topic === "Physical") &&
            stats.some(s => s.topic === "Cognitive") &&
            stats.some(s => s.topic === "Nutrition") &&
            stats.some(s => s.topic === "Sleep") &&
            stats.some(s => s.topic === "Social Psychological") &&
            stats.some(s => s.topic === "Vascular Health") &&
            stats.some(s => s.topic === "Vision Hearing") &&
            // Attitude Awareness
            stats.some(s => s.topic === "Self Efficacy") &&
            stats.some(s => s.topic === "Dementia Literacy") &&
            stats.some(s => s.topic === "Attitudes towards dementia") &&
            // Usability Acceptability
            stats.some(s => s.topic === "Usability") &&
            stats.some(s => s.topic === "Acceptability")
        ) {
            completed = true;
        }

        return completed;
    } catch (e) {
        console.error(e);
    }
};
