import XLSX from "xlsx";
import FileSaver from "file-saver";

export const exportJSONDocument = (csvData: any, fileName: string, sheetName: string) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {
        Sheets: { [sheetName]: ws },
        SheetNames: [sheetName],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportAOADocument = (csvData: any, fileName: string, sheetName: string) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.aoa_to_sheet(csvData);
    const wb = {
        Sheets: { [sheetName]: ws },
        SheetNames: [sheetName],
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export const exportAOADocumentWithMultipleTabs = (csvDataArray: any[], fileName: string, sheetNames: string[]) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb: XLSX.WorkBook = {
        Sheets: {},
        SheetNames: sheetNames,
    };

    sheetNames.forEach((sheetName, i) => {
        const ws = XLSX.utils.aoa_to_sheet(csvDataArray[i]);
        wb.Sheets[sheetName] = ws;
    });

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
