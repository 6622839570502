import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Custom components
import Title from "../../../../general/Title";

// Interfaces
import { AdminUser } from "../../../../../interfaces/AdminUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";
import useDb from "../../../../../hooks/useDb";

// Settings
import settings from "../../../../../settings.json";

// Utils
import { emailRegex } from "../../../../../utils/regex";

const COLLECTION = "Users";

// yup validation
const requiredMessage = "Ce champ est requis";

const userSchema = yup.object({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    email: yup.string().matches(emailRegex, "Courriel invalide").required(requiredMessage),
    roles: yup.array(yup.string().oneOf(settings.app.dashboardRoles)).required(requiredMessage),
});

interface Props {
    user: AdminUser;
}

const Profile: React.FC<Props> = ({ user = null }) => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const { updateDoc } = useDb<AdminUser>(COLLECTION, currentAdmin);

    const defaultValues = {
        firstName: user ? user.firstName : "",
        lastName: user ? user.lastName : "",
        email: user ? user.email : "",
        roles: user ? user.roles : [""],
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        setValue,
        control,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const onSubmit = async (data: AdminUser) => {
        try {
            const adminUser: AdminUser = { ...data, id: user?.id };

            if (user) {
                await updateDoc(adminUser);
                enqueueSnackbar("User updated", { variant: "success" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (user) {
            setValue("firstName", user.firstName);
            setValue("lastName", user.lastName);
            setValue("email", user.email);
            setValue("roles", user.roles);
        }
    }, [user]);

    return (
        <div>
            <Title>{user ? `${user?.firstName} ${user?.lastName}` : "Utilisateur"}</Title>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            helperText={errors.firstName?.message}
                            error={!!errors.firstName?.message}
                            label="Prénom"
                            fullWidth
                            required
                            style={{ marginBottom: 10 }}
                            {...field}
                        />
                    )}
                    name="firstName"
                    control={control}
                />
                <Controller
                    render={({ field }) => (
                        <TextField
                            helperText={errors.lastName?.message}
                            error={!!errors.lastName?.message}
                            label="Nom famille"
                            fullWidth
                            required
                            style={{ marginBottom: 10 }}
                            {...field}
                        />
                    )}
                    name="lastName"
                    control={control}
                />
                <Controller
                    render={({ field }) => (
                        <TextField
                            helperText={errors.email?.message}
                            error={!!errors.email?.message}
                            label="Courriel"
                            fullWidth
                            required
                            style={{ marginBottom: 10 }}
                            inputProps={{ readOnly: true }}
                            {...field}
                        />
                    )}
                    name="email"
                    control={control}
                />
                <Controller
                    name="roles"
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id="roles-id">Rôles</InputLabel>
                            <Select
                                labelId="roles-id"
                                multiple
                                {...field}
                                inputProps={{ readOnly: currentAdmin && currentAdmin.roles.includes(settings.app.highestRole) ? false : true }}
                            >
                                {settings.app.dashboardRoles.map(r => (
                                    <MenuItem key={r} value={r}>
                                        {r}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Mettre à jour
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Profile;
