import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import FileSaver from "file-saver";

// Components
import TopBarForm from "./forms/TopBarForm";
import RiskDiagram from "../../general/RiskDiagram";

// Utils
import settings from "../../../settings.json";

// Assets
import { MdKeyboardArrowRight } from "react-icons/md";

// Interfaces
import { ChapterTopic } from "../../../interfaces/Chapter";

// MUI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const FinalOverview: React.FC = () => {
    const hist = useHistory();
    const { t } = useTranslation();

    const handleContinue = (e: any) => {
        e.preventDefault();
        hist.push("/home");
    };

    const exportOverview = async () => {
        const overviewRef = document.getElementById("exportContent");

        if (overviewRef) {
            const canvas = await html2canvas(overviewRef, {
                // backgroundColor: "transparent",
            });
            canvas.style.display = "none";
            const image = canvas.toDataURL("png");

            FileSaver.saveAs(image, `${t("finalOverview")}.png`);
        }
    };

    return (
        <div>
            <TopBarForm />
            <div className="profile__container">
                <div className="profile__summary">
                    <Grid item container>
                        <Grid item xs={11}>
                            <h6>{t("finalOverviewInformation")}</h6>
                        </Grid>

                        <Grid item xs={1}>
                            <Button variant="contained" onClick={exportOverview}>
                                {t("export")}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={3} className="profile__content">
                    {settings.app.topics
                        .filter(t => t !== "Brain Overview")
                        .map((t, i) => {
                            return (
                                <Grid key={i} item lg={3} xs={6}>
                                    <div
                                        style={{
                                            border: "1px solid black",
                                            borderRadius: 30,
                                            padding: 10,
                                            minHeight: 200,
                                        }}
                                    >
                                        <RiskDiagram topic={t as ChapterTopic} />
                                    </div>
                                </Grid>
                            );
                        })}
                    <Grid item lg={3} xs={6} className="prioritizedTopic__item">
                        <div className="questionnaires__buttonContainer">
                            <Button variant="contained" onClick={handleContinue} className="questionnaires__button">
                                {t("continue")}
                                <MdKeyboardArrowRight />
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <div id="exportContent" style={{ padding: 20, position: "absolute", top: -99999 }}>
                    <div className="profile__summary">
                        <h6>{t("finalOverviewInformation")}</h6>
                    </div>
                    <Grid container spacing={3} className="profile__content">
                        {settings.app.topics
                            .filter(t => t !== "Brain Overview")
                            .map((t, i) => {
                                return (
                                    <Grid key={i} item lg={3} xs={6}>
                                        <div
                                            style={{
                                                border: "1px solid black",
                                                borderRadius: 30,
                                                padding: 10,
                                                minHeight: 200,
                                            }}
                                        >
                                            <RiskDiagram topic={t as ChapterTopic} />
                                        </div>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default FinalOverview;
