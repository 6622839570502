import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Mui
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Custom components
import DrawerContent from "./DrawerContent";

// Utils
import { changeLanguage } from "../../../utils/language";

// Assets
import logoEN from "../../../assets/logos/logoWhiteEN.png";
import logoFR from "../../../assets/logos/logoWhiteFR.png";
import theme from "../../../assets/nav/theme.png";

const DesktopDrawer: React.FC = () => {
    const { t } = useTranslation();
    const { currentParticipant } = useFetchUser();

    // States
    const [language, setLanguage] = useState("en");

    // toggle theme
    const changeTheme = () => {
        const divColor = (document.getElementById("color") as any).classList;
        if (divColor.contains("normal")) {
            divColor.replace("normal", "inverse");
            localStorage.setItem("theme", "inverse");
        } else {
            divColor.replace("inverse", "normal");
            localStorage.setItem("theme", "normal");
        }
    };

    // toggle font
    const changeFont = (value: string) => {
        const divFont = (document.getElementById("font") as any).classList;
        if (value === "font1") {
            divFont.add("font1");
            divFont.remove("font2", "font3");
            localStorage.setItem("font", "font1");
        } else if (value === "font2") {
            divFont.add("font2");
            divFont.remove("font1", "font3");
            localStorage.setItem("font", "font2");
        } else if (value === "font3") {
            divFont.add("font3");
            divFont.remove("font1", "font2");
            localStorage.setItem("font", "font3");
        }
    };

    useEffect(() => {
        if (!localStorage.getItem("language")) {
            changeLanguage(currentParticipant ? currentParticipant.language : "en", setLanguage);
        } else changeLanguage(localStorage.getItem("language"), setLanguage);
    }, []);

    return (
        <div className="portal">
            <div className="drawer__container">
                <AppBar position="absolute" className="drawer__appBar__shift">
                    <Toolbar className="drawer__toolbar">
                        <Typography component="h1" variant="h6" noWrap className="drawer__title">
                            {/* {settings.app.title} */}
                        </Typography>
                        <div className="nav__content drawer__toolbar__nav">
                            <div className="nav__content__theme">
                                <Button onClick={changeTheme} size="medium">
                                    <img src={theme} alt="" />
                                </Button>
                            </div>
                            <div className="nav__content__fontSize">
                                <FormControl>
                                    <Select className="sizeSelector" value={"none"} onChange={e => changeFont(e.target.value)} autoWidth>
                                        <MenuItem disabled value="none">
                                            <span className="nav__fontSize--placeholder">{t("textSize")}</span>
                                        </MenuItem>
                                        <MenuItem value="font1">
                                            <span className="nav__fontSize--font1">Aa</span>
                                        </MenuItem>
                                        <MenuItem value="font2">
                                            <span className="nav__fontSize--font2">Aa</span>
                                        </MenuItem>
                                        <MenuItem value="font3">
                                            <span className="nav__fontSize--font3">Aa</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="nav__content__fontSize">
                                <FormControl>
                                    <Select
                                        className="nav__button__textSize"
                                        value={language}
                                        onChange={e => changeLanguage(e.target.value, setLanguage)}
                                        autoWidth
                                    >
                                        <MenuItem value="en">
                                            <span className="nav__fontSize--placeholder">English</span>
                                        </MenuItem>
                                        <MenuItem value="fr">
                                            <span className="nav__fontSize--placeholder">Français</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* <div className="nav__header__container">
                            <div className="nav__header__options">
                                <UserMenu />
                            </div>
                        </div> */}
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: "drawer__paper",
                    }}
                    open={true}
                >
                    <div className="nav__logo__container">
                        <Link to="/home">
                            <img src={localStorage.getItem("language") === "en" ? logoEN : logoFR} alt="Logo" className="nav__logo" />
                        </Link>
                    </div>

                    <Divider />
                    <DrawerContent />
                </Drawer>
            </div>
        </div>
    );
};

export default DesktopDrawer;
