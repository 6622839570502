import React, { useState } from "react";

// Mui
import Paper from "@mui/material/Paper";

// Settings
import settings from "../../../settings.json";

// Components
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import Loading from "../../general/Loading";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

const DashboardLayout: React.FC = ({ children }) => {
    const { loading } = useFetchUser();

    const [width, setWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (
        <>
            <Loading loading={loading} />
            <div className="dashboard">
                <div className="mainLayout__container">
                    {width >= settings.page.maxScreenWidth ? <DesktopDrawer /> : <MobileDrawer />}
                    <main className="mainLayout__content">
                        <div className="mainLayout__appBarSpacer" />
                        <div className="mainLayout__info">
                            <Paper style={{ padding: 24 }}>{children}</Paper>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default DashboardLayout;
