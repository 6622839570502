import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ProgressBar from "react-scroll-progress-bar";

// Custom components
import DisclaimerDialog from "./dialogs/DisclaimerDialog";

// Assets
import trophy from "../../../assets/gifs/Trophy.gif";

// Forms
import AttitudeAwarenessFormGenerator from "./forms/attitudeAwareness/AttitudeAwarenessFormGenerator";
import UsabilityAcceptabilityFormGenerator from "./forms/usabilityAcceptability/UsabilityAcceptabilityFormGenerator";
import PhysicalActivityForm from "./forms/lifestyle/PhysicalActivityForm";
import SleepForm from "./forms/lifestyle/SleepForm";
import SocialPsychologicalHealthForm from "./forms/lifestyle/SocialPsychologicalHealthForm";
import VascularHealthForm from "./forms/lifestyle/VascularHealthForm";

// Form data
import { cognitiveEngagement, visionHearing, yourNutrition } from "./forms/lifestyle/lifestyleForms";
import { selfEfficacy, attitudesTowardDementia, dementiaLiteracy } from "./forms/attitudeAwareness/attitudeAwarenessForms";
import { acceptability, usability } from "./forms/usabilityAcceptability/usabilityAcceptabilityForms";

// Firebase
import { firestore, auth, functions } from "../../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";

// Utils
import { getNextMonday } from "../../../utils/date";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { Stage, Stat, StatTopic } from "../../../interfaces/Stat";
import { isQuestionnaireAvailable } from "../../../utils/general";
import { httpsCallable } from "firebase/functions";
import { stage5Completed } from "../../../utils/questionnaires";

interface paramsType {
    stage: string;
}

const Questionnaires: React.FC = () => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { stage } = useParams<paramsType>();
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant, currentWeek } = useFetchUser();
    const statRequests = useDb<Stat>("Stats", null, currentParticipant);

    // States
    const [stats, setStats] = useState<Stat[]>([]);
    const [hasExtraQuestionnaires, setHasExtraQuestionnaires] = useState(false);
    const [updateStatsCalled, setUpdateStatsCalled] = useState(false);
    const [seconds, setSeconds] = useState(5);
    const [dialogOpen, setDialogOpen] = useState(true);

    const handleComplete = async (title: string, data: any, score: number) => {
        try {
            if (currentParticipant && currentParticipant.id) {
                let topic = "";

                // ------------------ Attitude Awareness ------------------ //
                if (title === "Your health views") {
                    topic = "Self Efficacy";
                }
                if (title === "Dementia Facts") {
                    topic = "Dementia Literacy";
                }
                if (title === "Your View of Dementia") {
                    topic = "Attitudes towards dementia";
                }
                // ------------------ Lifestyle ------------------ //
                if (title === "Your Physical Activity") {
                    topic = "Physical";
                }
                if (title === "Your Cognitive Engagement") {
                    topic = "Cognitive";
                }
                if (title === "Your Nutrition") {
                    topic = "Nutrition";
                }
                if (title === "Your Sleep") {
                    topic = "Sleep";
                }
                if (title === "Your Social & Psychological Health") {
                    topic = "Social Psychological";
                }
                if (title === "Your Vascular Health") {
                    topic = "Vascular Health";
                }
                if (title === "Your Vision & Hearing") {
                    topic = "Vision Hearing";
                }
                // ------------------ Usability Acceptability ------------------ //
                if (title === "Your Brain Health Pro Experience") {
                    topic = "Usability";
                }
                if (title === "Help us Improve Brain Health Pro") {
                    topic = "Acceptability";
                }

                const createDocPayload = await statRequests.createDoc({
                    participantId: currentParticipant.id,
                    results: data,
                    score,
                    stage: stage as Stage,
                    topic: topic as StatTopic,
                } as Stat);

                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                    points: (currentParticipant.points += 1),
                });

                if (createDocPayload && createDocPayload.id) {
                    await updateDoc(doc(firestore, "Stats", createDocPayload.id), {
                        id: createDocPayload.id,
                    });
                    enqueueSnackbar(
                        <span>
                            {t(`1pointCompletedGained`)}
                            <b> 1 point </b>
                            {t(`1pointCompletedQuestionnaire`)}
                        </span>,
                        {
                            variant: "success",
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom",
                            },
                            content: (key, message) => (
                                <div key={key} className="point__snackbar">
                                    <img alt="trophy" src={trophy} />
                                    <p>{message}</p>
                                </div>
                            ),
                            autoHideDuration: 5000,
                        }
                    );
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateParticipant = async () => {
        if (currentParticipant && currentParticipant.id) {
            const data: any = {
                stages: { ...currentParticipant.stages, [stage]: true },
            };

            if (!currentParticipant.cycleStart) {
                const getFirebaseTimestamp = httpsCallable(functions, "getFirebaseTimestamp");
                const serverTimestamp: any = (await getFirebaseTimestamp()).data;

                data.cycleStart = getNextMonday(new Date(serverTimestamp));
            }

            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);

            await updateDoc(doc(firestore, "Participants", currentParticipant.id), data);
        }
    };

    const handleDelete = async () => {
        if (currentParticipant && currentParticipant.id) {
            await updateDoc(doc(firestore, "Participants", currentParticipant.id), { isDeleted: true });
            await signOut(auth);
        }
    };

    useEffect(() => {
        if (seconds > 0 && seconds < 5)
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        if (seconds === 0) {
            if (currentParticipant && currentParticipant.deactivated && !currentParticipant.isDeleted) {
                handleDelete();
            } else {
                enqueueSnackbar(`Questionnaires ${stage} completed`, { variant: "success" });

                if (currentWeek <= 1) {
                    hist.push("/fullscreenChapter/x6lmvU6hXZ9do1uM8zPn/false/false"); // redirect to chapter1
                } else if (currentWeek === 52) hist.push("/finalOverview");
                else hist.push("/overview");
            }
        }
    }, [seconds]);

    useEffect(() => {
        if (!updateStatsCalled) {
            if (!currentParticipant?.stages.stage1) {
                if (
                    // Lifestyle
                    stats.some(s => s.topic === "Physical" && s.stage === stage) &&
                    stats.some(s => s.topic === "Cognitive" && s.stage === stage) &&
                    stats.some(s => s.topic === "Nutrition" && s.stage === stage) &&
                    stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
                    stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
                    stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
                    stats.some(s => s.topic === "Vision Hearing" && s.stage === stage) &&
                    // Attitude Awareness
                    stats.some(s => s.topic === "Self Efficacy" && s.stage === stage) &&
                    stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage) &&
                    stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage)
                ) {
                    updateParticipant();
                    setUpdateStatsCalled(true);
                }
            } else if (hasExtraQuestionnaires) {
                if (
                    // Lifestyle
                    stats.some(s => s.topic === "Physical" && s.stage === stage) &&
                    stats.some(s => s.topic === "Cognitive" && s.stage === stage) &&
                    stats.some(s => s.topic === "Nutrition" && s.stage === stage) &&
                    stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
                    stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
                    stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
                    stats.some(s => s.topic === "Vision Hearing" && s.stage === stage) &&
                    // Attitude Awareness
                    stats.some(s => s.topic === "Self Efficacy" && s.stage === stage) &&
                    stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage) &&
                    stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage) &&
                    // Usability Acceptability
                    stats.some(s => s.topic === "Usability" && s.stage === stage) &&
                    stats.some(s => s.topic === "Acceptability" && s.stage === stage)
                ) {
                    updateParticipant();
                    setUpdateStatsCalled(true);
                }
            } else {
                if (
                    // Lifestyle
                    stats.some(s => s.topic === "Physical" && s.stage === stage) &&
                    stats.some(s => s.topic === "Cognitive" && s.stage === stage) &&
                    stats.some(s => s.topic === "Nutrition" && s.stage === stage) &&
                    stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
                    stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
                    stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
                    stats.some(s => s.topic === "Vision Hearing" && s.stage === stage)
                ) {
                    updateParticipant();
                    setUpdateStatsCalled(true);
                }
            }
        }
    }, [stats]);

    useEffect(() => {
        let unsub: any = null;
        if (currentParticipant && currentParticipant.id) {
            if (stats) {
                unsub = statRequests.onSnapshot({
                    callback: setStats,
                    filters: [{ field: "participantId", operator: "==", value: currentParticipant.id }],
                });
            }
        }
        return () => typeof unsub === "function" && unsub();
    }, []);

    const handleExtraQuestionnaire = async () => {
        if (currentParticipant && !currentParticipant.deactivated) {
            // stage 3 or stage 5
            if (stage === "stage3" || stage === "stage5") setHasExtraQuestionnaires(true);

            if (currentParticipant && currentParticipant.deactivated && !currentParticipant.stages.stage5) setHasExtraQuestionnaires(true);

            if (
                (stage !== "stage5" && !isQuestionnaireAvailable(parseInt(stage.replace("stage", "")), currentParticipant)) ||
                (stage === "stage5" && (await stage5Completed(currentParticipant)))
            ) {
                if (currentWeek <= 1) hist.push("/fullscreenChapter/x6lmvU6hXZ9do1uM8zPn/false/false"); // redirect to chapter1
                else if (currentWeek === 52) hist.push("/finalOverview");
                else hist.push("/overview");
            }
        }
    };

    useEffect(() => {
        handleExtraQuestionnaire();
    }, [currentWeek]);

    let output = <></>;

    if (!currentParticipant?.stages.stage1) {
        // ------------------ Lifestyle ------------------ //
        if (!stats.some(s => s.topic === "Physical" && s.stage === stage)) {
            output = <PhysicalActivityForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Physical" && s.stage === stage) && !stats.some(s => s.topic === "Cognitive" && s.stage === stage)) {
            output = <AttitudeAwarenessFormGenerator form={cognitiveEngagement} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Cognitive" && s.stage === stage) && !stats.some(s => s.topic === "Nutrition" && s.stage === stage)) {
            output = <AttitudeAwarenessFormGenerator form={yourNutrition} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Nutrition" && s.stage === stage) && !stats.some(s => s.topic === "Sleep" && s.stage === stage)) {
            output = <SleepForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
            !stats.some(s => s.topic === "Social Psychological" && s.stage === stage)
        ) {
            output = <SocialPsychologicalHealthForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
            !stats.some(s => s.topic === "Vascular Health" && s.stage === stage)
        ) {
            output = <VascularHealthForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
            !stats.some(s => s.topic === "Vision Hearing" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={visionHearing} onComplete={handleComplete} />;
        }
        // ------------------ Attitude Awareness ------------------ //
        else if (
            stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
            !stats.some(s => s.topic === "Self Efficacy" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={selfEfficacy} onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Self Efficacy" && s.stage === stage) &&
            !stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={dementiaLiteracy} onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage) &&
            !stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={attitudesTowardDementia} onComplete={handleComplete} />;
        } else {
            if (
                // ------------------ Lifestyle ------------------ //
                stats.some(s => s.topic === "Physical" && s.stage === stage) &&
                stats.some(s => s.topic === "Cognitive" && s.stage === stage) &&
                stats.some(s => s.topic === "Nutrition" && s.stage === stage) &&
                stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
                stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
                stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
                stats.some(s => s.topic === "Vision Hearing" && s.stage === stage) &&
                // ------------------ Attitude Awareness ------------------ //
                stats.some(s => s.topic === "Self Efficacy" && s.stage === stage) &&
                stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage) &&
                stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage)
            ) {
                output = (
                    <div className="questionnaires__complete">
                        <h1>
                            You have completed the <span className="questionnaires__stage">{stage}</span> questionnaires. You will be redirected in{" "}
                            {seconds} seconds
                        </h1>
                    </div>
                );
            } else {
                output = (
                    <div className="questionnaires__error">
                        <h1>Something went wrong</h1>
                    </div>
                );
            }
        }
    } else if (hasExtraQuestionnaires) {
        // ------------------ Lifestyle ------------------ //
        if (!stats.some(s => s.topic === "Physical" && s.stage === stage)) {
            output = <PhysicalActivityForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Physical" && s.stage === stage) && !stats.some(s => s.topic === "Cognitive" && s.stage === stage)) {
            output = <AttitudeAwarenessFormGenerator form={cognitiveEngagement} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Cognitive" && s.stage === stage) && !stats.some(s => s.topic === "Nutrition" && s.stage === stage)) {
            output = <AttitudeAwarenessFormGenerator form={yourNutrition} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Nutrition" && s.stage === stage) && !stats.some(s => s.topic === "Sleep" && s.stage === stage)) {
            output = <SleepForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
            !stats.some(s => s.topic === "Social Psychological" && s.stage === stage)
        ) {
            output = <SocialPsychologicalHealthForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
            !stats.some(s => s.topic === "Vascular Health" && s.stage === stage)
        ) {
            output = <VascularHealthForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
            !stats.some(s => s.topic === "Vision Hearing" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={visionHearing} onComplete={handleComplete} />;
        }
        // ------------------ Attitude Awareness ------------------ //
        else if (
            stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
            !stats.some(s => s.topic === "Self Efficacy" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={selfEfficacy} onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Self Efficacy" && s.stage === stage) &&
            !stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={dementiaLiteracy} onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage) &&
            !stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={attitudesTowardDementia} onComplete={handleComplete} />;
        }
        // ------------------ Usability Acceptability ------------------ //
        else if (
            stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage) &&
            !stats.some(s => s.topic === "Usability" && s.stage === stage)
        ) {
            output = <UsabilityAcceptabilityFormGenerator form={usability} onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Usability" && s.stage === stage) &&
            !stats.some(s => s.topic === "Acceptability" && s.stage === stage)
        ) {
            output = <UsabilityAcceptabilityFormGenerator form={acceptability} onComplete={handleComplete} />;
        } else {
            if (
                // ------------------ Lifestyle ------------------ //
                stats.some(s => s.topic === "Physical" && s.stage === stage) &&
                stats.some(s => s.topic === "Cognitive" && s.stage === stage) &&
                stats.some(s => s.topic === "Nutrition" && s.stage === stage) &&
                stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
                stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
                stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
                stats.some(s => s.topic === "Vision Hearing" && s.stage === stage) &&
                // ------------------ Attitude Awareness ------------------ //
                stats.some(s => s.topic === "Self Efficacy" && s.stage === stage) &&
                stats.some(s => s.topic === "Dementia Literacy" && s.stage === stage) &&
                stats.some(s => s.topic === "Attitudes towards dementia" && s.stage === stage) &&
                // ------------------ Usability Acceptability ------------------ //
                stats.some(s => s.topic === "Usability" && s.stage === stage) &&
                stats.some(s => s.topic === "Acceptability" && s.stage === stage)
            ) {
                output = (
                    <div className="questionnaires__complete">
                        <h1>
                            You have completed the <span className="questionnaires__stage">{stage}</span> questionnaires. You will be redirected in{" "}
                            {seconds} seconds
                        </h1>
                    </div>
                );
            } else {
                output = (
                    <div className="questionnaires__error">
                        <h1>Something went wrong</h1>
                    </div>
                );
            }
        }
    } else {
        // ------------------ Lifestyle ------------------ //
        if (!stats.some(s => s.topic === "Physical" && s.stage === stage)) {
            output = <PhysicalActivityForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Physical" && s.stage === stage) && !stats.some(s => s.topic === "Cognitive" && s.stage === stage)) {
            output = <AttitudeAwarenessFormGenerator form={cognitiveEngagement} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Cognitive" && s.stage === stage) && !stats.some(s => s.topic === "Nutrition" && s.stage === stage)) {
            output = <AttitudeAwarenessFormGenerator form={yourNutrition} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Nutrition" && s.stage === stage) && !stats.some(s => s.topic === "Sleep" && s.stage === stage)) {
            output = <SleepForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
            !stats.some(s => s.topic === "Social Psychological" && s.stage === stage)
        ) {
            output = <SocialPsychologicalHealthForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
            !stats.some(s => s.topic === "Vascular Health" && s.stage === stage)
        ) {
            output = <VascularHealthForm onComplete={handleComplete} />;
        } else if (
            stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
            !stats.some(s => s.topic === "Vision Hearing" && s.stage === stage)
        ) {
            output = <AttitudeAwarenessFormGenerator form={visionHearing} onComplete={handleComplete} />;
        } else {
            if (
                // ------------------ Lifestyle ------------------ //
                stats.some(s => s.topic === "Physical" && s.stage === stage) &&
                stats.some(s => s.topic === "Cognitive" && s.stage === stage) &&
                stats.some(s => s.topic === "Nutrition" && s.stage === stage) &&
                stats.some(s => s.topic === "Sleep" && s.stage === stage) &&
                stats.some(s => s.topic === "Social Psychological" && s.stage === stage) &&
                stats.some(s => s.topic === "Vascular Health" && s.stage === stage) &&
                stats.some(s => s.topic === "Vision Hearing" && s.stage === stage)
            ) {
                output = (
                    <div className="questionnaires__complete">
                        <h1>
                            You have completed the <span className="questionnaires__stage">{stage}</span> questionnaires. <br />
                            You will be redirected in {seconds} second{seconds > 1 ? "s" : ""}
                        </h1>
                    </div>
                );
            } else {
                output = (
                    <div className="questionnaires__error">
                        <h1>Something went wrong</h1>
                    </div>
                );
            }
        }
    }

    return (
        <div className="questionnaires__forms">
            <ProgressBar height="6px" bgcolor="#F59120" duration="0.2" />
            {output}
            {dialogOpen && <DisclaimerDialog open={dialogOpen} setOpen={setDialogOpen} />}
        </div>
    );
};

export default Questionnaires;
