import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Mui core
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Popover from "@mui/material/Popover";

// Custom components
import GaugeCard from "../../../general/GaugeCard";

// Utils
import { getIconFromTopic, getTitleFromTopic } from "../../../../utils/topic";
import { getCurrentStageNumber } from "../../../../utils/general";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Interfaces
import { Stat, StatTopic } from "../../../../interfaces/Stat";

const TopicPickerDialog = forwardRef(({ setTopic, desiredTopic, children }: any, ref) => {
    const { t } = useTranslation();
    const { currentParticipant, currentWeek } = useFetchUser();
    const { getAll } = useDb<Stat>("Stats", null, currentParticipant);

    // States
    const [open, setOpen] = useState(false);
    const [availableTopics, setAvailableTopics] = useState([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedTopic, setSelectedTopic] = useState<StatTopic | null>(null);
    const [stat, setStat] = useState<Stat | null>(null);

    useImperativeHandle(ref, () => ({
        open({ topics } = { topics: [] }) {
            if (topics.length > 0) setAvailableTopics(topics);
            setOpen(true);
        },
        close() {
            setOpen(false);
        },
    }));

    const fetchStat = async () => {
        if (currentParticipant && currentParticipant.id) {
            const stats = await getAll({
                filters: [
                    { field: "topic", operator: "==", value: selectedTopic },
                    { field: "participantId", operator: "==", value: currentParticipant.id },
                    { field: "stage", operator: "==", value: `stage${getCurrentStageNumber(currentWeek)}` },
                ],
            });
            setStat(stats[0] as Stat);
        }
    };

    const properTopic = (desiredTopic: string) => {
        switch (desiredTopic) {
            case "topic1":
                return t("desiredTopic1");
            case "topic2":
                return t("desiredTopic2");
            case "topic3":
                return t("desiredTopic3");
            default:
                return t("desiredTopic1");
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);

    useEffect(() => {
        if (selectedTopic) {
            fetchStat();
        }
    }, [selectedTopic]);

    useEffect(() => {
        //
    }, [stat]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="prioritizedTopics__dialog">
            <div style={{ padding: 20 }}>
                <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>
                    {t("topicPicker")}
                </DialogTitle>
                <p style={{ textAlign: "center" }}>{properTopic(desiredTopic)}</p>
                <br />
                <p style={{ textAlign: "center" }}>{t("topicInfo")}</p>
                <DialogContent>
                    <ImageList cols={3}>
                        {availableTopics.map((topic, i) => (
                            <ImageListItem
                                key={i}
                                style={{ textAlign: "center", maxWidth: 150, cursor: "pointer", fontWeight: "bold" }}
                                onClick={() => {
                                    setTopic(topic);
                                    setOpen(false);
                                }}
                            >
                                <img src={getIconFromTopic(topic as StatTopic)} alt={topic} loading="lazy" className="nofilterimg" />
                                <ImageListItemBar
                                    title={
                                        <span
                                            aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={e => {
                                                handlePopoverOpen(e);
                                                setSelectedTopic(topic);
                                            }}
                                            onMouseLeave={() => {
                                                handlePopoverClose();
                                                setSelectedTopic(null);
                                                setStat(null);
                                            }}
                                        >
                                            {getTitleFromTopic(topic as StatTopic)}
                                            {/* <IconButton color="primary" style={{ cursor: "pointer" }}>
                                                <GrCircleInformation style={{ marginLeft: 5 }} />
                                            </IconButton> */}
                                        </span>
                                    }
                                    position="below"
                                />
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: "none",
                                    }}
                                    open={popoverOpen}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                    PaperProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                        },
                                    }}
                                >
                                    {stat && (
                                        <GaugeCard
                                            topic={stat.topic}
                                            score={stat.score}
                                            title={selectedTopic ? getTitleFromTopic(selectedTopic) : ""}
                                            isClickable={false}
                                            hasAnimation={false}
                                        />
                                    )}
                                </Popover>
                            </ImageListItem>
                        ))}
                    </ImageList>
                    {children}
                </DialogContent>
            </div>
        </Dialog>
    );
});

export default TopicPickerDialog;
