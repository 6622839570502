import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ProgressBar from "react-scroll-progress-bar";

// Forms
import AttitudeAwarenessFormGenerator from "./forms/attitudeAwareness/AttitudeAwarenessFormGenerator";
import PhysicalActivityForm from "./forms/lifestyle/PhysicalActivityForm";
import SleepForm from "./forms/lifestyle/SleepForm";
import SocialPsychologicalHealthForm from "./forms/lifestyle/SocialPsychologicalHealthForm";
import VascularHealthForm from "./forms/lifestyle/VascularHealthForm";
import UsabilityAcceptabilityFormGenerator from "./forms/usabilityAcceptability/UsabilityAcceptabilityFormGenerator";

// Form data
import { cognitiveEngagement, visionHearing, yourNutrition } from "./forms/lifestyle/lifestyleForms";
import { selfEfficacy, attitudesTowardDementia, dementiaLiteracy } from "./forms/attitudeAwareness/attitudeAwarenessForms";
import { acceptability, usability } from "./forms/usabilityAcceptability/usabilityAcceptabilityForms";

// Firebase
import { auth, firestore } from "../../../firebase/firebase";
import { genDoc } from "../../../firebase/requests";
import { query, where, updateDoc, doc, onSnapshot, collection } from "firebase/firestore";
import { signOut } from "firebase/auth";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { Stage, Stat, StatTopic } from "../../../interfaces/Stat";

interface paramsType {
    stage: string;
}

const ManualQuestionnaires: React.FC = () => {
    const hist = useHistory();
    const { stage } = useParams<paramsType>();
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant } = useFetchUser();
    const statRequests = useDb<Stat>("Stats", null, currentParticipant);

    // States
    const [seconds, setSeconds] = useState(5);
    const [stats, setStats] = useState<Stat[]>([]);
    const [step, setStep] = useState(0);
    const [output, setOutput] = useState(<></>);

    const fetchStats = () => {
        if (currentParticipant && currentParticipant.id) {
            return onSnapshot(
                query(collection(firestore, "Stats"), where("participantId", "==", currentParticipant.id), where("stage", "==", "manualS5")),
                (data: any) => {
                    setStats(data.docs.map(genDoc<Stat>()));
                }
            );
        }
    };

    useEffect(() => {
        const unsub = fetchStats();
        return () => {
            typeof unsub === "function" && unsub();
        };
    }, []);

    const handleComplete = async (title: string, data: any, score: number) => {
        try {
            if (currentParticipant && currentParticipant.id) {
                let topic = "";

                // ------------------ Lifestyle ------------------ //
                if (title === "Your Physical Activity") {
                    topic = "Physical";
                }
                if (title === "Your Cognitive Engagement") {
                    topic = "Cognitive";
                }
                if (title === "Your Nutrition") {
                    topic = "Nutrition";
                }
                if (title === "Your Sleep") {
                    topic = "Sleep";
                }
                if (title === "Your Social & Psychological Health") {
                    topic = "Social Psychological";
                }
                if (title === "Your Vascular Health") {
                    topic = "Vascular Health";
                }
                if (title === "Your Vision & Hearing") {
                    topic = "Vision Hearing";
                }

                // ------------------ Attitude Awareness ------------------ //
                if (title === "Your health views") {
                    topic = "Self Efficacy";
                }
                if (title === "Dementia Facts") {
                    topic = "Dementia Literacy";
                }
                if (title === "Your View of Dementia") {
                    topic = "Attitudes towards dementia";
                }
                // ------------------ Usability Acceptability ------------------ //
                if (title === "Your Brain Health Pro Experience") {
                    topic = "Usability";
                }
                if (title === "Help us Improve Brain Health Pro") {
                    topic = "Acceptability";
                }

                const createDocPayload = await statRequests.createDoc({
                    participantId: currentParticipant.id,
                    results: data,
                    score,
                    stage: stage as Stage,
                    topic: topic as StatTopic,
                } as Stat);

                if (createDocPayload && createDocPayload.id) {
                    await updateDoc(doc(firestore, "Stats", createDocPayload.id), {
                        id: createDocPayload.id,
                    });
                }

                setStep(step + 1);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const generateQuestionnaire = () => {
        let output = <>Something went wrong</>;

        // ------------------ Lifestyle ------------------ //
        if (!stats.some(s => s.topic === "Physical")) {
            output = <PhysicalActivityForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Physical") && !stats.some(s => s.topic === "Cognitive")) {
            output = <AttitudeAwarenessFormGenerator form={cognitiveEngagement} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Cognitive") && !stats.some(s => s.topic === "Nutrition")) {
            output = <AttitudeAwarenessFormGenerator form={yourNutrition} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Nutrition") && !stats.some(s => s.topic === "Sleep")) {
            output = <SleepForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Sleep") && !stats.some(s => s.topic === "Social Psychological")) {
            output = <SocialPsychologicalHealthForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Social Psychological") && !stats.some(s => s.topic === "Vascular Health")) {
            output = <VascularHealthForm onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Vascular Health") && !stats.some(s => s.topic === "Vision Hearing")) {
            output = <AttitudeAwarenessFormGenerator form={visionHearing} onComplete={handleComplete} />;
        }
        // ------------------ Attitude Awareness ------------------ //
        else if (stats.some(s => s.topic === "Vascular Health") && !stats.some(s => s.topic === "Self Efficacy")) {
            output = <AttitudeAwarenessFormGenerator form={selfEfficacy} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Self Efficacy") && !stats.some(s => s.topic === "Dementia Literacy")) {
            output = <AttitudeAwarenessFormGenerator form={dementiaLiteracy} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Dementia Literacy") && !stats.some(s => s.topic === "Attitudes towards dementia")) {
            output = <AttitudeAwarenessFormGenerator form={attitudesTowardDementia} onComplete={handleComplete} />;
        }
        // ------------------ Usability Acceptability ------------------ //
        else if (stats.some(s => s.topic === "Attitudes towards dementia") && !stats.some(s => s.topic === "Usability")) {
            output = <UsabilityAcceptabilityFormGenerator form={usability} onComplete={handleComplete} />;
        } else if (stats.some(s => s.topic === "Usability") && !stats.some(s => s.topic === "Acceptability")) {
            output = <UsabilityAcceptabilityFormGenerator form={acceptability} onComplete={handleComplete} />;
        } else {
            output = (
                <div className="questionnaires__complete">
                    <h1>
                        You have completed the <span className="questionnaires__stage">{stage}</span> questionnaires. You will be redirected in{" "}
                        {seconds} seconds
                    </h1>
                </div>
            );
        }

        return output;
    };

    const updateParticipant = async () => {
        try {
            if (currentParticipant && currentParticipant.id) {
                setTimeout(() => {
                    setSeconds(seconds - 1);
                }, 1000);

                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                    ...currentParticipant,
                    manualS5: false,
                    stages: {
                        ...currentParticipant.stages,
                        stage5: true,
                    },
                    deactivated: true,
                });

                auth.signOut();
                hist.push("/");
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleEnd = async () => {
        if (seconds > 0 && seconds < 5)
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        if (seconds === 0) {
            enqueueSnackbar(`Questionnaires stage5 completed`, { variant: "success" });
            await signOut(auth);
            hist.push("/login");
        }
    };

    useEffect(() => {
        if (
            // Lifestyle
            stats.some(s => s.topic === "Physical") &&
            stats.some(s => s.topic === "Cognitive") &&
            stats.some(s => s.topic === "Nutrition") &&
            stats.some(s => s.topic === "Sleep") &&
            stats.some(s => s.topic === "Social Psychological") &&
            stats.some(s => s.topic === "Vascular Health") &&
            stats.some(s => s.topic === "Vision Hearing") &&
            // Attitude Awareness
            stats.some(s => s.topic === "Self Efficacy") &&
            stats.some(s => s.topic === "Dementia Literacy") &&
            stats.some(s => s.topic === "Attitudes towards dementia") &&
            // Usability Acceptability
            stats.some(s => s.topic === "Usability") &&
            stats.some(s => s.topic === "Acceptability")
        ) {
            updateParticipant();
        }
    }, [stats]);

    useEffect(() => {
        handleEnd();
    }, [seconds]);

    useEffect(() => {
        if (currentParticipant?.manualS5) {
            setOutput(generateQuestionnaire());
        } else {
            auth.signOut();
            hist.push("/login");
        }
    }, [stats]);

    return (
        <div className="questionnaires__forms">
            <ProgressBar height="6px" bgcolor="#F59120" duration="0.2" />
            {output}
            {/* {dialogOpen && <DisclaimerDialog open={dialogOpen} setOpen={setDialogOpen} />} */}
        </div>
    );
};

export default ManualQuestionnaires;
