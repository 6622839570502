import React from 'react';

const LoginHero = () => {
    return (
        <div className="loginHero__container">
            <div className="loginHero">
                
            </div>
        </div>
    );
};

export default LoginHero;