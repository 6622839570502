import React from "react";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Components
import Loading from "../../general/Loading";
import SubDomainRedirection from "../../pages/portal/SubDomainRedirection";

const BaseLayout: React.FC = ({ children }) => {
    const { loading } = useFetchUser();

    return (
        <>
            <SubDomainRedirection />
            <Loading loading={loading} />
            <div className="baseLayout__container">
                <main>
                    <div className="baseLayout__toolbar" />
                    {children}
                </main>
            </div>
        </>
    );
};

export default BaseLayout;
