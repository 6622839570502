import i18n from "i18next";
import moment from "moment";
import "moment/locale/fr";

// toggle language
// eslint-disable-next-line
export const changeLanguage = (value: string | null, setLanguage?: (value: string) => void) => {
    if (value) {
        if (setLanguage) setLanguage(value);
        i18n.changeLanguage(value);
        localStorage.setItem("language", value);
        moment.locale(value);
    }
};

// toggle language
// eslint-disable-next-line
export const switchLanguage = (setLanguage?: (value: string) => void) => {
    let switchLanguage = "fr";
    const currentLanguage = i18n.language;

    if (currentLanguage == "fr") switchLanguage = "en";
    else if (currentLanguage == "en") switchLanguage = "fr";

    i18n.changeLanguage(switchLanguage);

    if (typeof setLanguage === "function") setLanguage(switchLanguage);
    localStorage.setItem("language", switchLanguage);
    moment.locale(switchLanguage);
};
