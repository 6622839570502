import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/moment";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Firebase
import { serverTimestamp } from "firebase/firestore";

// Assets
import plus from "../../../../assets/icons/plus.png";

// Interfaces
import { StatTopic } from "../../../../interfaces/Stat";
import { Chapter, ChapterTopic } from "../../../../interfaces/Chapter";
import { Notebook } from "../../../../interfaces/Notebook";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb from "../../../../hooks/useDb";

// Mui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const MAX_ROWS = 25;

const generateSchema = () => {
    const conditions: any = {};
    for (let i = 1; i <= MAX_ROWS; i++) {
        conditions[`date${i.toString()}`] = yup.date().nullable();
        conditions[`chapterId${i.toString()}`] = yup.string();
        conditions[`note${i.toString()}`] = yup.string();
    }
    return yup.object(conditions);
};

const generateDefaultValues = () => {
    const output: any = {};
    for (let i = 1; i <= MAX_ROWS; i++) {
        output[`date${i.toString()}`] = new Date();
        output[`chapterId${i.toString()}`] = "";
        output[`note${i.toString()}`] = "";
    }
    return output;
};

interface Props {
    topic: StatTopic | ChapterTopic;
}

export const EditNotebook: React.FC<Props> = ({ topic }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant } = useFetchUser();
    const notebookRequest = useDb<Notebook>("Notebooks", null, currentParticipant);
    const chapterRequest = useDb<Chapter>("Chapters", null, currentParticipant);

    // States
    const [rowCount, setRowCount] = useState<any[]>([""]);
    const [rowAdded, setRowAdded] = useState(0);
    const [notebooks, setNotebooks] = useState<Notebook[]>([]);
    const [chapters, setChapters] = useState<Chapter[]>([]);

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({
        resolver: yupResolver(generateSchema()),
        defaultValues: generateDefaultValues(),
    });

    const onSubmit = async (data: any) => {
        const outputData: any = {};

        Object.entries(data).map((e: any) => {
            if (e[1]) {
                if (e[0].includes("chapter")) {
                    const c = JSON.parse(e[1]) as Chapter;
                    outputData[e[0]] = c.id;
                } else {
                    outputData[e[0]] = e[1];
                }
            }
        });

        if (currentParticipant && currentParticipant.id) {
            const currentNotebook = notebooks.find(n => n.topic === topic && n.participantId === currentParticipant.id);

            const notebook: Notebook = {
                topic: topic as StatTopic,
                participantId: currentParticipant.id,
                data: outputData,
                isDeleted: false,
                createdAt: serverTimestamp() as any,
                updatedAt: serverTimestamp() as any,
                createdAtTimestamp: Date.now(),
            };

            if (currentNotebook && currentNotebook.id) {
                await notebookRequest.updateDoc({ ...notebook, id: currentNotebook.id });
            } else {
                const createdNotebook = await notebookRequest.createDoc(notebook);
                await notebookRequest.updateDoc({ ...notebook, id: createdNotebook.id });
            }
        }
    };

    const addRow = () => {
        if (rowCount.length < MAX_ROWS) setRowCount([...rowCount, ""]);
        else enqueueSnackbar(`You have reached the maximum number of rows (${MAX_ROWS})`, { variant: "error" });
    };

    const fetchChapters = async () => {
        setChapters(
            (
                (await chapterRequest.getAll({
                    filters: [{ field: "topic", operator: "==", value: topic }],
                })) as Chapter[]
            ).sort((a, b) => a.chapter - b.chapter)
        );
    };

    const fetchNotebooks = async () => {
        if (currentParticipant && currentParticipant.id) {
            setNotebooks(
                (await notebookRequest.getAll({
                    filters: [
                        { field: "participantId", operator: "==", value: currentParticipant.id },
                        { field: "topic", operator: "==", value: topic },
                    ],
                })) as Notebook[]
            );
        }
    };

    useEffect(() => {
        if (currentParticipant) {
            fetchChapters();
            fetchNotebooks();
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (notebooks.length > 0) {
            for (let i = 1; i <= MAX_ROWS; i++) {
                let rowNeeded = false;

                if (notebooks[0].data[`date${i}`]) {
                    rowNeeded = true;
                    setValue(`date${i}`, notebooks[0].data[`date${i}`]);
                }
                if (notebooks[0].data[`chapterId${i}`]) {
                    rowNeeded = true;
                    setValue(`chapterId${i}`, JSON.stringify(chapters.find(c => c.id === notebooks[0].data[`chapterId${i}`])));
                }
                if (notebooks[0].data[`note${i}`]) {
                    rowNeeded = true;
                    setValue(`note${i}`, notebooks[0].data[`note${i}`]);
                }

                let totalRows = 0;

                // get row count
                Object.keys(notebooks[0].data).map(k => {
                    if (k.includes("note")) {
                        totalRows++;
                    }
                });

                if (i > 1 && rowNeeded && rowCount.length < totalRows) {
                    addRow();
                    setRowAdded(rowAdded + 1);
                }
            }
        }
    }, [notebooks]);

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid item container spacing={0.5}>
                {rowCount.map((row, i) => {
                    return (
                        <React.Fragment key={i}>
                            <Grid item xs={2}>
                                <Controller
                                    control={control}
                                    name={`date${(i + 1).toString()}`}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                                            <DesktopDatePicker
                                                allowSameDateSelection
                                                openTo="year"
                                                disableFuture
                                                label="Date"
                                                inputFormat="DD-MM-yyyy"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        fullWidth
                                                        error={errors && errors[`date${(i + 1).toString()}`] && errors[`date${(i + 1).toString()}`]}
                                                        helperText={
                                                            errors &&
                                                            errors[`date${(i + 1).toString()}`] &&
                                                            errors[`date${(i + 1).toString()}`]?.message
                                                        }
                                                        style={{ marginBottom: 10 }}
                                                        {...params}
                                                    />
                                                )}
                                                {...field}
                                                onClose={handleSubmit(onSubmit)}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Controller
                                    name={`chapterId${(i + 1).toString()}`}
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors[`chapterId${(i + 1).toString()}`]?.message}
                                            onBlur={handleSubmit(onSubmit)}
                                        >
                                            <InputLabel id="roles-id">
                                                <p>{t("chapter")}</p>
                                            </InputLabel>
                                            <Select
                                                label="---------------"
                                                labelId="roles-id"
                                                renderValue={chapter => {
                                                    const c = JSON.parse(chapter) as Chapter;
                                                    return c.chapter;
                                                }}
                                                {...field}
                                            >
                                                {chapters.map((c, i) => {
                                                    return (
                                                        <MenuItem key={i} value={JSON.stringify(c)}>
                                                            {c.chapter}: {localStorage.getItem("language") === "en" ? c.titleEN : c.titleFR}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {!!errors[`chapterId${(i + 1).toString()}`]?.message && (
                                                <FormHelperText>{errors[`chapterId${(i + 1).toString()}`]?.message}</FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name={`note${(i + 1).toString()}`}
                                    render={({ field }) => (
                                        <TextField
                                            multiline
                                            rows={3}
                                            helperText={
                                                errors && !!errors[`note${(i + 1).toString()}`] && errors[`note${(i + 1).toString()}`]?.message
                                            }
                                            error={errors && !!errors[`note${(i + 1).toString()}`]}
                                            label="Note"
                                            fullWidth
                                            {...field}
                                            onBlur={handleSubmit(onSubmit)}
                                        />
                                    )}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>

            <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
                <img src={plus} alt="plus" style={{ maxWidth: 45, cursor: "pointer", display: "inline-block" }} onClick={() => addRow()} />
            </div>
        </form>
    );
};

export default EditNotebook;
