import React from "react";
import { useTranslation } from "react-i18next";

// React Router Dom
import { Link } from "react-router-dom";

// Components
import Title from "../../general/Title";

// MUI
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Interfaces
import { ChapterTopic } from "../../../interfaces/Chapter";

// Assets
import { AiFillStar } from "react-icons/ai";
import iconBrain from "../../../assets/icons/icon-brain.png";
import iconCardio from "../../../assets/icons/icon-cardio.png";
import iconCognitive from "../../../assets/icons/icon-cognitive.png";
import iconHearVision from "../../../assets/icons/icon-hear-vision.png";
import iconSocial from "../../../assets/icons/icon-social.png";
import iconSleep from "../../../assets/icons/icon-sleep.png";
import iconPhysical from "../../../assets/icons/icon-physical.png";
import iconNutrition from "../../../assets/icons/icon-nutrition.png";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress className="progressbar" variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}></Box>
        </Box>
    );
}

interface Props {
    image: any;
    title: string;
    chapter: number;
    totalChapter: number;
    topic: ChapterTopic;
}

const TopicCard: React.FC<Props> = ({ image, title, chapter, totalChapter, topic }) => {
    const { t } = useTranslation();
    const { currentParticipant } = useFetchUser();

    const isPrioritizedTopic = (t: ChapterTopic) => {
        if (currentParticipant && currentParticipant.prioritizedTopics) {
            return currentParticipant.prioritizedTopics.includes(t);
        }
        return false;
    };

    return (
        <Link to={`/topic/${topic}`}>
            <div className="taskCard">
                <div className="taskCard__logo">
                    <img src={image} alt="" className="nofilterimg" />
                </div>
                <div className="taskCard__content">
                    <h4 className="topicCard__title">{title}</h4>
                    <h6 className="topicCard__chapters">
                        {chapter} {t("of")} {totalChapter} {t("chapters")}
                    </h6>
                    <Box sx={{ width: "100%" }}>
                        <LinearProgressWithLabel value={(chapter / totalChapter) * 100} />
                    </Box>
                </div>
                <div className="taskCard__topRightCorner">
                    {isPrioritizedTopic(topic) ? (
                        <div className="taskCard__star">
                            <AiFillStar />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </Link>
    );
};

const Topics: React.FC = () => {
    const { currentParticipant } = useFetchUser();
    const { t } = useTranslation();

    return (
        <>
            <div className="topics__header">
                <div>
                    <Title>{t("topics")}</Title>
                    <p>{t("topicsDesc")}</p>
                </div>
                <p>
                    <AiFillStar /> {t("priorityTopic")}
                </p>
            </div>
            <div className="topics__cards">
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconBrain}
                            title={t("brainOverviewTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Brain Overview"]
                                    ? currentParticipant.unlockedChapters["Brain Overview"].filter((x: any) =>
                                          currentParticipant.completedChapters["Brain Overview"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={18}
                            topic="Brain Overview"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconPhysical}
                            title={t("physicalTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Physical"]
                                    ? currentParticipant.unlockedChapters["Physical"].filter((x: any) =>
                                          currentParticipant.completedChapters["Physical"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={21}
                            topic="Physical"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconCognitive}
                            title={t("cognitiveTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Cognitive"]
                                    ? currentParticipant.unlockedChapters["Cognitive"].filter((x: any) =>
                                          currentParticipant.completedChapters["Cognitive"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={24}
                            topic="Cognitive"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconNutrition}
                            title={t("nutritionTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Nutrition"]
                                    ? currentParticipant.unlockedChapters["Nutrition"].filter((x: any) =>
                                          currentParticipant.completedChapters["Nutrition"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={24}
                            topic="Nutrition"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconSleep}
                            title={t("sleepTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Sleep"]
                                    ? currentParticipant.unlockedChapters["Sleep"].filter((x: any) =>
                                          currentParticipant.completedChapters["Sleep"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={24}
                            topic="Sleep"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconSocial}
                            title={t("socialTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Social Psychological"]
                                    ? currentParticipant.unlockedChapters["Social Psychological"].filter((x: any) =>
                                          currentParticipant.completedChapters["Social Psychological"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={24}
                            topic="Social Psychological"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconCardio}
                            title={t("vascularTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Vascular Health"]
                                    ? currentParticipant.unlockedChapters["Vascular Health"].filter((x: any) =>
                                          currentParticipant.completedChapters["Vascular Health"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={23}
                            topic="Vascular Health"
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TopicCard
                            image={iconHearVision}
                            title={t("visionTitle")}
                            chapter={
                                currentParticipant && currentParticipant.unlockedChapters["Vision Hearing"]
                                    ? currentParticipant.unlockedChapters["Vision Hearing"].filter((x: any) =>
                                          currentParticipant.completedChapters["Vision Hearing"].includes(x)
                                      ).length
                                    : 0
                            }
                            totalChapter={24}
                            topic="Vision Hearing"
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Topics;
