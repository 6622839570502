import { Participant } from "../interfaces/Participant";

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const getCurrentStageNumber = (currentWeek: number) => {
    let stage = 0;

    if (currentWeek >= 1 && currentWeek < 12) stage = 1;
    if (currentWeek >= 12 && currentWeek < 23) stage = 2;
    if (currentWeek >= 23 && currentWeek < 34) stage = 3;
    if (currentWeek >= 34 && currentWeek < 45) stage = 4;
    if (currentWeek >= 45) stage = 5;

    return stage;
};

export const getLatestStageNumber = (participant: Participant) => {
    if (participant.stages.stage1 && !participant.stages.stage2) return 1;
    if (participant.stages.stage2 && !participant.stages.stage3) return 2;
    if (participant.stages.stage3 && !participant.stages.stage4) return 3;
    if (participant.stages.stage4 && !participant.stages.stage5) return 4;
    if (participant.stages.stage5) return 5;
    return 1;
};

export const getCurrentStage = (currentWeek: number) => {
    let stage = "stage1";

    if (currentWeek >= 1 && currentWeek < 12) stage = "stage1";
    if (currentWeek >= 12 && currentWeek < 23) stage = "stage2";
    if (currentWeek >= 23 && currentWeek < 34) stage = "stage3";
    if (currentWeek >= 34 && currentWeek < 45) stage = "stage4";
    if (currentWeek >= 45) stage = "stage5";

    return stage;
};

export const isQuestionnaireAvailable = (stageNumber: number, currentParticipant: Participant | null) => {
    let isAvailable = true;

    if (currentParticipant && stageNumber !== 1) {
        isAvailable = Object.entries(currentParticipant.stages).some(entry => entry[0] === `stage${stageNumber}` && !entry[1]);
    }

    return isAvailable;
};

export const removeUndefinedInObject = (data: any) => {
    const newObject: any = {};

    Object.entries(data).forEach(entry => {
        if (entry[1] !== undefined) newObject[entry[0]] = entry[1];
    });

    return newObject;
};

export const isInTimeline = (id: string, currentParticipant: Participant) => {
    let inTimeline = false;

    Object.entries(currentParticipant.timeline).map(e => {
        if (e[1] && e[1].length > 0) {
            inTimeline = e[1].includes(id);
        }
    });

    return inTimeline;
};

export const getStageDisplayName = (stage: string) => {
    let stageName = "";
    switch (stage) {
        case "stage1":
        default:
            stageName = "Baseline";
            break;
        case "stage2":
            stageName = "Week12";
            break;
        case "stage3":
            stageName = "Week23";
            break;
        case "stage4":
            stageName = "Week34";
            break;
        case "stage5":
            stageName = "Week45";
            break;
    }
    return stageName;
};

export const dirtyValues = (dirtyFields: any, allValues: any): any => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    return Object.fromEntries(Object.keys(dirtyFields).map(key => [key, dirtyValues(dirtyFields[key], allValues[key])]));
};
