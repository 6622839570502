import React, { useState } from "react";

// Components
import TopMenu from "../../pages/portal/forms/TopMenu";

// Mui
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

// Icons
import { MdMenu } from "react-icons/md";
import DrawerContent from "./DrawerContent";

interface Props {
    open: boolean;
    setOpen: any;
}

const MobileDrawer = () => {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const MobileDrawerContent = (props: Props) => {
        const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
                return;
            }

            props.setOpen(open);
        };

        return (
            <div>
                <Drawer open={props.open} onClose={toggleDrawer(false)}>
                    <div className="drawer__list__mobile" role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                        <DrawerContent />
                    </div>
                </Drawer>
            </div>
        );
    };

    return (
        <div className="drawer__container">
            <AppBar position="fixed">
                <Toolbar className="drawer__menu">
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                        <MdMenu className="drawer__menu__icon" />
                    </IconButton>
                    <TopMenu />
                    {/* <Typography variant="h6" noWrap className="drawer__title">
                        {settings.app.title}
                    </Typography> */}
                </Toolbar>
            </AppBar>
            <MobileDrawerContent open={open} setOpen={setOpen} />
        </div>
    );
};

export default MobileDrawer;
