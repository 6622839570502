import React from "react";

// Assets
import loadingGif from "../../assets/gifs/BHPLoading.gif";

export const Loading: React.FC<{ loading: boolean }> = ({ loading }) => {
    if (loading)
        return (
            <div
                className="loading__container parent"
                style={{ backgroundColor: "black", opacity: 0.5, position: "absolute", zIndex: 99999, minHeight: "100%", minWidth: "100%" }}
            >
                <div className="child">
                    <img className="nofilterimg" src={loadingGif} alt="loading" style={{ maxHeight: 350 }} />
                </div>
            </div>
        );
    else return <></>;
};

export default Loading;
