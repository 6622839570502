// Route props
import { CustomRouteProps } from "./route";

// Settings
import settings from "../settings.json";

// Components
import BaseLayout from "../components/layout/base/BaseLayout";
import DashboardLayout from "../components/layout/dashboardLayout/DashboardLayout";
import DashboardHome from "../components/pages/dashboard/Home";
import Login from "../components/pages/dashboard/Login";
import Participants from "../components/pages/dashboard/participants/Participants";
import Whitelist from "../components/pages/dashboard/Whitelist";
import Admins from "../components/pages/dashboard/admins/Admins";
import Admin from "../components/pages/dashboard/admin/Admin";
import Logs from "../components/pages/dashboard/logs/Logs";
import Chapters from "../components/pages/dashboard/chapters/Chapters";
import Chapter from "../components/pages/dashboard/chapters/Chapter";
import Stats from "../components/pages/dashboard/stats/Stats";
import Tracking from "../components/pages/dashboard/tracking/Tracking";
import SpecificTracking from "../components/pages/dashboard/tracking/SpecificTracking";

const Routes: Array<CustomRouteProps> = [
    {
        Component: Login,
        path: "/dashboard/login",
        exact: true,
        title: "Login",
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: DashboardHome,
        path: "/dashboard/",
        exact: true,
        title: "Home",
        LayoutComponent: DashboardLayout,
        rolesAllowed: settings.app.dashboardRolesIncludingSyn,
        isPrivate: true,
    },
    {
        Component: Participants,
        path: "/dashboard/participants",
        exact: true,
        title: "Participants",
        LayoutComponent: DashboardLayout,
        rolesAllowed: settings.app.dashboardRolesIncludingSyn,
        isPrivate: true,
    },
    {
        Component: Whitelist,
        path: "/dashboard/whitelist",
        exact: true,
        title: "Whitelist",
        LayoutComponent: DashboardLayout,
        rolesAllowed: settings.app.dashboardRolesIncludingSyn,
        isPrivate: true,
    },
    {
        Component: Stats,
        path: "/dashboard/stats",
        exact: true,
        title: "Statistics",
        LayoutComponent: DashboardLayout,
        rolesAllowed: settings.app.dashboardRolesIncludingSyn,
        isPrivate: true,
    },
    {
        Component: Admins,
        path: "/dashboard/admins",
        exact: true,
        title: "Admins",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Admin,
        path: "/dashboard/admin/:id",
        exact: false,
        title: "Admin",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Logs,
        path: "/dashboard/logs",
        exact: true,
        title: "Logs",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Chapters,
        path: "/dashboard/chapters",
        exact: true,
        title: "Chapters",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Chapter,
        path: "/dashboard/chapter/:id",
        exact: false,
        title: "Chapter",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Tracking,
        path: "/dashboard/tracking",
        exact: true,
        title: "Tracking",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: SpecificTracking,
        path: "/dashboard/specificTracking/:id",
        exact: false,
        title: "SpecificTracking",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
];

export default Routes;
