import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import Title from "../../../../general/Title";
import TopBarForm from "../TopBarForm";
import InstructionDialog from "../InstructionDialog";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";

// Hooks
import RadioGroup from "@mui/material/RadioGroup";
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { removeUndefinedInObject } from "../../../../../utils/general";
import { getScoreByQuestion } from "../../../../../utils/score";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Participant } from "../../../../../interfaces/Participant";
import { FormControlLabel, Radio } from "@mui/material";
import { calculateAge } from "../../../../../utils/date";

const requiredMessage = "Required field";

interface Props {
    // eslint-disable-next-line
    onComplete: (title: string, data: any, points: number, topic: string, participant: Participant | null) => void;
}

const TITLE_FR = "Votre santé psychologique et sociale";
const TITLE_EN = "Your Social & Psychological Health";
const ABOUT_FR =
    "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter.Essayez de terminer le questionnaire en une seule séance.";
const ABOUT_EN =
    "These questions will help us to know you better and see your progress. This should take about 10 minutes. Try to complete this task in one session.";
const INSTRUCTION_FR = "Répondez à chaque question au mieux de vos capacités.";
const INSTRUCTION_EN = "Answer each question to the best of your abilities.";

const SocialPsychologicalHealthForm: React.FC<Props> = ({ onComplete }) => {
    const { t } = useTranslation();
    const { currentParticipant, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

    const generateSchema = () => {
        const output: any = {};
        output.question1 = yup.number().typeError("Please select a value").required(requiredMessage);
        output.question2 = yup.number().typeError("Please select a value").required(requiredMessage);
        output.question3 = yup.string().typeError("Please select a value").required(requiredMessage);
        output.question4 = yup.number().typeError("Please select a value").required(requiredMessage);
        output.question5 = yup.number().typeError("Please select a value").required(requiredMessage);
        output.question6 = yup.number().typeError("Please select a value").required(requiredMessage);
        output.question7 = yup.number().typeError("Please select a value").required(requiredMessage);
        output.question8 = yup.number().typeError("Please select a value").required(requiredMessage);

        return yup.object(output);
    };

    const generateDefaultValues = () => {
        const output: any = {};
        output.question1 = "";
        output.question2 = "";
        output.question3 = "";
        output.question4 = "";
        output.question5 = "";
        output.question6 = "";
        output.question7 = "";
        output.question8 = "";
        return output;
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(generateSchema()),
        defaultValues: generateDefaultValues(),
    });

    const onSubmit = async (data: any) => {
        try {
            let points = 0;
            data = removeUndefinedInObject(data);
            setLoading(true);

            Object.entries(data).forEach(e => {
                const localPoints = getScoreByQuestion(
                    "Social Psychological",
                    parseInt((e[0] as string).replace("question", "")),
                    data,
                    currentParticipant
                );
                if (typeof localPoints !== "undefined" && localPoints !== "n/a") points += localPoints as number;
            });

            enqueueSnackbar(t("questionnaireCompleted", { title: localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR }), {
                variant: "success",
            });
            onComplete(TITLE_EN, data, points, "Social Psychological", currentParticipant);
            window.scrollTo(0, 0);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const id = Object.entries(errors)[0][0];
            const element = document.getElementById(id);

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    return (
        <>
            <TopBarForm />
            <div className="forms__container">
                <Title>{localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR}</Title>

                <div className="forms__section">
                    <h6 className="forms__subtitle">{localStorage.getItem("language") === "en" ? "About" : "À propos"}</h6>
                    <p>{localStorage.getItem("language") === "en" ? ABOUT_EN : ABOUT_FR}</p>
                </div>

                <div className="forms__section">
                    <h6 className="forms__subtitle">Instructions</h6>
                    <p>{localStorage.getItem("language") === "en" ? INSTRUCTION_EN : INSTRUCTION_FR}</p>
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e: any) => {
                        // Disable submit on "enter" key press
                        e.key === "Enter" && e.preventDefault();
                    }}
                    className="forms__content"
                >
                    <Grid item>
                        <div>
                            <Controller
                                control={control}
                                name="question1"
                                render={({ field }) => (
                                    <FormControl
                                        id="question1"
                                        component="fieldset"
                                        error={!!errors.question1?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, in the last two weeks, how often did you feel lonely?"
                                                : "Sur une échelle de 0 à 7, au cours des deux dernières semaines, combien de fois vous êtes-vous senti seul(e) ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "0 (Never)" : "0 (Jamais)"}
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel value={2} control={<Radio />} label={2} />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel value={5} control={<Radio />} label={5} />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Every day)" : "7 (Tous les jours)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question1?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question2"
                                render={({ field }) => (
                                    <FormControl
                                        id="question2"
                                        component="fieldset"
                                        error={!!errors.question2?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, how often, in the last two weeks has anyone shown prejudice against you or treated you unfairly because of your age?"
                                                : "Sur une échelle de 0 à 7, combien de fois, au cours des deux dernières semaines, vous a-t-on traité de façon injuste ou avez-vous fait l’objet de discrimination à cause de votre âge ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "0 (Never)" : "0 (Jamais)"}
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel value={2} control={<Radio />} label={2} />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel value={5} control={<Radio />} label={5} />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Every day)" : "7 (Tous les jours)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question2?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question3"
                                render={({ field }) => (
                                    <FormControl
                                        id="question3"
                                        component="fieldset"
                                        error={!!errors.question3?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "Many people feel older or younger than they actually are. What age have you felt most of the time in the last two weeks?"
                                                : "De nombreuses personnes se sentent plus âgées ou plus jeunes qu'elles ne le sont en réalité. Au cours des deux dernières semaines, quel âge avez-vous ressenti avoir la plupart du temps?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value="Younger than my current age"
                                                control={<Radio />}
                                                label={
                                                    localStorage.getItem("language") === "en"
                                                        ? "Younger than my current age"
                                                        : "Plus jeune que mon âge actuel"
                                                }
                                            />
                                            <FormControlLabel
                                                value="The same as my current age"
                                                control={<Radio />}
                                                label={
                                                    localStorage.getItem("language") === "en"
                                                        ? "The same as my current age"
                                                        : "Le même que mon âge actuel"
                                                }
                                            />
                                            <FormControlLabel
                                                value="Older than my current age"
                                                control={<Radio />}
                                                label={
                                                    localStorage.getItem("language") === "en"
                                                        ? "Older than my current age"
                                                        : "Plus vieux que mon âge actuel"
                                                }
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question3?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question4"
                                render={({ field }) => (
                                    <FormControl
                                        id="question4"
                                        component="fieldset"
                                        error={!!errors.question4?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, please tell us how you feel about this statement over the last two weeks. No matter at what point in life, you can always influence your own aging."
                                                : "Sur une échelle de 0 à 7, veuillez nous dire ce que vous pensez de cette déclaration au cours des deux dernières semaines: Peu importe votre âge, vous pouvez toujours influencer votre propre vieillissement."}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={
                                                    localStorage.getItem("language") === "en" ? "0 (Absolutely Agree)" : "0 (Tout à fait d'accord)"
                                                }
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel value={2} control={<Radio />} label={2} />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel value={5} control={<Radio />} label={5} />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Do Not Agree)" : "7 (Pas d'accord)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question4?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question5"
                                render={({ field }) => (
                                    <FormControl
                                        id="question5"
                                        component="fieldset"
                                        error={!!errors.question5?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, over the last two weeks, how much have you been bothered by feeling sad, down, or uninterested in life?"
                                                : "Sur une échelle de 0 à 7, au cours des deux dernières semaines, à quel point avez-vous été perturbé par un sentiment de tristesse, de déprime ou de désintérêt pour la vie ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "0 (Not At All)" : "0 (Pas du tout)"}
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "2 (A little)" : "2 (Un peu)"}
                                            />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel
                                                value={5}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "5 (Moderately)" : "5 (Modérément)"}
                                            />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Severely)" : "7 (Sévèrement)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question5?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question6"
                                render={({ field }) => (
                                    <FormControl
                                        id="question6"
                                        component="fieldset"
                                        error={!!errors.question6?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, over the last two weeks, how much have you been bothered by feeling anxious or nervous?"
                                                : "Sur une échelle de 0 à 7, au cours des deux dernières semaines, à quel point avez-vous été perturbé par un sentiment d’anxiété ou de nervosité ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "0 (Not At All)" : "0 (Pas du tout)"}
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "2 (A little)" : "2 (Un peu)"}
                                            />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel
                                                value={5}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "5 (Moderately)" : "5 (Modérément)"}
                                            />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Severely)" : "7 (Sévèrement)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question6?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question7"
                                render={({ field }) => (
                                    <FormControl
                                        id="question7"
                                        component="fieldset"
                                        error={!!errors.question7?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, over the last two weeks, how much have you been bothered by feeling stressed?"
                                                : "Sur une échelle de 0 à 7, au cours des deux dernières semaines,  à quel point avez-vous été perturbé par le stress?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "0 (Not At All)" : "0 (Pas du tout)"}
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "2 (A little)" : "2 (Un peu)"}
                                            />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel
                                                value={5}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "5 (Moderately)" : "5 (Modérément)"}
                                            />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Severely)" : "7 (Sévèrement)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question7?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                control={control}
                                name="question8"
                                render={({ field }) => (
                                    <FormControl
                                        id="question8"
                                        component="fieldset"
                                        error={!!errors.question8?.message}
                                        {...field}
                                        className="forms__fullWidth"
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "On a scale of 0-7, over the last two weeks, how much have you been bothered by not having the social support you feel you need?"
                                                : "Sur une échelle de 0 à 7, au cours des deux dernières semaines, à quel point avez-vous été perturbé de ne pas recevoir le soutien social dont vous pensez avoir besoin?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["slider"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel
                                                value={0}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "0 (Not At All)" : "0 (Pas du tout)"}
                                            />
                                            <FormControlLabel value={1} control={<Radio />} label={1} />
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "2 (A little)" : "2 (Un peu)"}
                                            />
                                            <FormControlLabel value={3} control={<Radio />} label={3} />
                                            <FormControlLabel value={4} control={<Radio />} label={4} />
                                            <FormControlLabel
                                                value={5}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "5 (Moderately)" : "5 (Modérément)"}
                                            />
                                            <FormControlLabel value={6} control={<Radio />} label={6} />
                                            <FormControlLabel
                                                value={7}
                                                control={<Radio />}
                                                label={localStorage.getItem("language") === "en" ? "7 (Severely)" : "7 (Sévèrement)"}
                                            />
                                        </RadioGroup>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question8?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" className="questionnaires__cta" fullWidth>
                            {localStorage.getItem("language") === "en" ? "Submit" : "Soumettre"} <MdKeyboardArrowRight />
                        </Button>
                    </Grid>
                    <div id="spacer_for_button" style={{ minHeight: 30 }} />
                </form>
            </div>
            <InstructionDialog open={dialogOpen} setOpen={setDialogOpen} types={selectedTypes} />
        </>
    );
};

export default SocialPsychologicalHealthForm;
