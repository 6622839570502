import * as dbUtils from "../firebase/requests";

// Interfaces
import { AdminUser } from "../interfaces/AdminUser";
import { Log } from "../interfaces/Log";
import { Base } from "../interfaces/Base";
import { Participant } from "../interfaces/Participant";

import settings from "../settings.json";

interface GetAllOptions {
    filters?: dbUtils.Filter[];
    orderBy?: dbUtils.OrderBy;
    isDeleted?: boolean;
    allData?: boolean;
}

interface onSnapshotOptions {
    callback: any;
    id?: string;
    orderBy?: dbUtils.OrderBy;
    filters?: dbUtils.Filter[];
    acceptDeleted?: boolean;
}

export const convertCollectionToFrench = (collection: string) => {
    switch (collection) {
        case "Chapters":
            return "du chapitre";
        case "Stats":
            return "de la statistique";
        case "WhiteListUsers":
            return "de l'utilisateur whitelist";
        case "Users":
            return "de l'utilisateur";
        case "Participants":
            return "du participant";
        default:
            return "d'une entrée dans une collection inconnue";
    }
};

export const convertCollectionToEnglish = (collection: string) => {
    switch (collection) {
        case "Chapters":
            return "chapter";
        case "Stats":
            return "stat";
        case "WhiteListUsers":
            return "whitelistUser";
        case "Users":
            return "user";
        case "Participants":
            return "participant";
        default:
            return "an entry in an unknown collection";
    }
};

function useDb<T>(collection: string, currentAdmin: AdminUser | null = null, currentParticipant: Participant | null = null, document?: string) {
    const firstFetch = (callback: any, acceptDeleted = false, filters: dbUtils.Filter[] = []) => {
        return dbUtils.firstFetch(collection, callback, acceptDeleted, filters);
    };

    const fetchNextPage = (item: Base, callback: any, acceptDeleted = false, filters: dbUtils.Filter[] = []) => {
        return dbUtils.fetchNextPage(collection, callback, item, acceptDeleted, filters);
    };

    const fetchLastPage = (item: Base, callback: any, acceptDeleted = false, filters: dbUtils.Filter[] = []) => {
        return dbUtils.fetchLastPage(collection, callback, item, acceptDeleted, filters);
    };

    // eslint-disable-next-line
    const getAll: (options: GetAllOptions) => Promise<Base[]> = ({
        filters = [],
        orderBy = { field: "createdAt", direction: "desc" },
        isDeleted = false,
        allData = false,
    }) => {
        return dbUtils.getAll(collection, filters, orderBy, isDeleted, allData);
    };

    // eslint-disable-next-line
    const getById: (id: string) => Promise<Base> = id => {
        return dbUtils.getById(collection, id);
    };

    // eslint-disable-next-line
    const updateDoc: (doc: Base) => Promise<Base> = async doc => {
        const res: Promise<Base> = dbUtils.update(collection, doc);

        const entry = await dbUtils.getById(collection, (doc as any).id);

        if (collection !== "Logs") {
            let adminId = "";
            if (currentAdmin && currentAdmin.id) adminId = currentAdmin.id;
            if (currentParticipant && currentParticipant.id) adminId = currentParticipant.id;
            await dbUtils.create<Log>("Logs", {
                adminId,
                reason: `Modified ${convertCollectionToEnglish(collection)} ${
                    entry && (entry as any).email ? (entry as any).email : (doc as any).id
                }`,
            });
        }

        return res;
    };

    // eslint-disable-next-line
    const createDoc: (doc: Base) => Promise<Base> = async doc => {
        const res: Promise<Base> = dbUtils.create(collection, doc);

        const entry = await dbUtils.getById(collection, (doc as any).id);

        if (collection !== "Logs") {
            let adminId = "";
            if (currentAdmin && currentAdmin.id) adminId = currentAdmin.id;
            if (currentParticipant && currentParticipant.id) adminId = currentParticipant.id;
            await dbUtils.create<Log>("Logs", {
                adminId,
                reason: `Created ${convertCollectionToEnglish(collection)} ${entry && (entry as any).email ? (entry as any).email : (doc as any).id}`,
            });
        }

        return res;
    };

    // eslint-disable-next-line
    const deleteDoc: (id: string, hard: boolean) => Promise<boolean> = async (id, hard = false) => {
        const entry = await dbUtils.getById(collection, id);

        const res: Promise<boolean> = dbUtils.deleteById(collection, id, hard);

        if (collection !== "Logs") {
            let adminId = "";
            if (currentAdmin && currentAdmin.id) adminId = currentAdmin.id;
            if (currentParticipant && currentParticipant.id) adminId = currentParticipant.id;
            await dbUtils.create<Log>("Logs", {
                adminId,
                reason: `Deleted ${convertCollectionToEnglish(collection)} ${entry && (entry as any).email ? (entry as any).email : id}`,
            });
        }

        return res;
    };

    // eslint-disable-next-line
    const toggleDeleteDoc: (doc: any) => Promise<T | void> = async doc => {
        if (!currentAdmin) throw Error("useDb: toggleDeleteDoc: Cannot call this function without defining the currentAdmin");
        if (!(currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole))) {
            return;
        }

        return dbUtils.update(collection, { ...doc, isDeleted: !doc.isDeleted });
    };

    const onSnapshot = (options: onSnapshotOptions) => {
        return dbUtils.onSnapshot(collection, options.callback, options.orderBy, options.filters, options.id, options.acceptDeleted);
    };

    const onSnapshotWithDoc = (callback: any) => {
        return dbUtils.onSnapshotWithDoc(document ? document : "", callback);
    };

    return {
        firstFetch,
        fetchNextPage,
        fetchLastPage,
        getAll,
        getById,
        updateDoc,
        createDoc,
        deleteDoc,
        onSnapshot,
        onSnapshotWithDoc,
        toggleDeleteDoc,
    };
}

export default useDb;
