import { useSnackbar } from "notistack";

// Material UI Core
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";

// Firebase
import * as requests from "../../../../firebase/requests";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Icons
import { MdAdd } from "react-icons/md";

// Settings
import settings from "../../../../settings.json";

const COLLECTION = "Users";

interface Props {
    open: boolean;
    setOpen: any;
}

const AdminsTableRows = (props: Props) => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = (u: AdminUser) => {
        try {
            if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                if (u.id)
                    requests.update<AdminUser>(COLLECTION, {
                        ...u,
                        isDeleted: !u.isDeleted,
                    });
            } else {
                enqueueSnackbar("Missing required role", { variant: "error" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return [
        {
            header: "Email",
            body: (u: AdminUser) => u.email,
        },
        {
            header: "Firstname",
            body: (u: AdminUser) => u.firstName,
        },
        {
            header: "Lastname",
            body: (u: AdminUser) => u.lastName,
        },
        {
            header: "Roles",
            body: (u: AdminUser) => u.roles.map((r, i) => <div key={u && u.id ? u.id + i : i}>{r}</div>),
        },
        {
            header: (
                <Button variant="contained" color="primary" onClick={() => props.setOpen(true)}>
                    <MdAdd />
                    Add
                </Button>
            ),
            body: (u: AdminUser) => (
                <Switch
                    checked={!u.isDeleted}
                    onChange={() => handleDelete(u)}
                    name="checkedA"
                    inputProps={{
                        "aria-label": "secondary checkbox",
                    }}
                />
            ),
        },
    ];
};

export default AdminsTableRows;
