import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Avatar, Button, Popover, ListItemIcon, ListItemText, MenuItem } from "@mui/material";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Icons
import { MdExitToApp } from "react-icons/md";

// Firebase
import { auth } from "../../firebase/firebase";

// Assets
import avatar from "../../assets/images/profile.jpg";

const UserMenu: React.FC = () => {
    const hist = useHistory();
    const { resetStates } = useFetchUser();

    // States
    const [userMenu, setUserMenu] = useState<any>(null);

    return (
        <div>
            <Button onClick={e => setUserMenu(e.currentTarget)}>
                <Avatar src={avatar} />
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={() => setUserMenu(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                classes={{
                    paper: "py-8",
                }}
            >
                <MenuItem
                    component={Link}
                    to="/login"
                    onClick={() => {
                        resetStates();
                        auth.signOut();
                        setUserMenu(null);
                        hist.push("/login");
                    }}
                    role="button"
                >
                    <ListItemIcon className="min-w-40">
                        <MdExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>
            </Popover>
        </div>
    );
};

export default UserMenu;
