import React from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb, { convertCollectionToEnglish } from "../../../../hooks/useDb";

// Settings
import settings from "../../../../settings.json";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { emailRegex } from "../../../../utils/regex";

// Firebase
import { httpsCallable } from "firebase/functions";
import { sendPasswordResetEmail } from "firebase/auth";
import { functions, auth } from "../../../../firebase/firebase";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";
import { Log } from "../../../../interfaces/Log";

const COLLECTION = "Logs";

// yup validation
const requiredMessage = "Ce champ est requis";

const userSchema = yup.object({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    email: yup.string().matches(emailRegex, "Courriel invalide").required(requiredMessage),
    roles: yup.array(yup.string().oneOf(settings.app.dashboardRoles)).required(requiredMessage),
});

interface Props {
    open: boolean;
    setOpen: any;
    userId: string;
}

const UserDialog: React.FC<Props> = ({ open, setOpen }) => {
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const { createDoc } = useDb<Log>(COLLECTION, currentAdmin);

    const defaultValues = {
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const onSubmit = async (data: AdminUser) => {
        try {
            const user: AdminUser = { ...data };

            setLoading(true);
            const createAdminUser = httpsCallable(functions, "createAdminUser");
            await createAdminUser(user);

            await sendPasswordResetEmail(auth, user.email);
            await createDoc({
                adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                reason: `Created ${convertCollectionToEnglish("Users")} ${data && (data as any).email ? (data as any).email : (data as any).id}`,
            } as Log);

            enqueueSnackbar("User created", { variant: "success" });

            setOpen(false);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => !loading && setOpen(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="scroll-dialog-title">Add user</DialogTitle>
                <DialogContent>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.firstName?.message}
                                error={!!errors.firstName?.message}
                                label="Prénom"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="firstName"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.lastName?.message}
                                error={!!errors.lastName?.message}
                                label="Nom famille"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="lastName"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.email?.message}
                                error={!!errors.email?.message}
                                label="Courriel"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="email"
                        control={control}
                    />
                    <Controller
                        name="roles"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="roles-id">Rôles</InputLabel>
                                <Select
                                    labelId="roles-id"
                                    multiple
                                    {...field}
                                    inputProps={{ readOnly: currentAdmin && currentAdmin.roles.includes(settings.app.highestRole) ? false : true }}
                                >
                                    {settings.app.dashboardRoles.map(r => (
                                        <MenuItem key={r} value={r}>
                                            {r}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default UserDialog;
