import { Link } from 'react-router-dom';

// Assets
import { GoTriangleRight } from 'react-icons/go';

const LandingCard = ( prop: { linkTitle: string, text: string, to: string } ) => {
    return (
        <div className="landingCard">
            <div className="landingCard__container">
                <div className="landingCard__title">
                    <Link to={prop.to}>
                        <h3>{prop.linkTitle} <GoTriangleRight /></h3>
                    </Link>
                </div>
                <div className="landingCard__text">
                    <h6>{prop.text}</h6>
                </div>
            </div>
        </div>
    );
};

export default LandingCard;