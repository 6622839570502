import { createRef, useEffect } from "react";
import { SnackbarProvider } from "notistack";
// import { detect, Browser } from "detect-browser";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Router
import Router from "./routes/router";

// Settings
import versionObj from "./version.json";

// styles
import "./scss/App.scss";

// Theme
import theme from "./theme";

// Assets
import { useFetchUser } from "./hooks/useFetchUser";

// const browser = detect();

function App() {
    const { currentParticipant } = useFetchUser();

    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    // const isBrowserCompatible = () => {
    //     // IE & Safari are incompatible

    //     const safari: Browser = "safari";
    //     const ie: Browser = "ie";

    //     if (browser && browser.name) return browser.name !== ie && browser.name !== safari;
    //     else true;
    // };

    // set lang if present in localstorage
    useEffect(() => {
        const lang = localStorage.getItem("language");
        if (lang) {
            return;
        } else {
            localStorage.setItem("language", currentParticipant ? currentParticipant.language : "en");
        }
    }, []);

    // set theme if present in localstorage
    useEffect(() => {
        const divColor = (document.getElementById("color") as any).classList;
        const theme = localStorage.getItem("theme");
        if (theme) {
            if (divColor.contains("normal")) {
                divColor.remove("normal");
            } else if (divColor.contains("inverse")) {
                divColor.remove("inverse");
            }
            divColor.add(theme);
        } else {
            localStorage.setItem("theme", "normal");
        }
    }, []);

    // set font size if present in localstorage
    useEffect(() => {
        const divFont = (document.getElementById("font") as any).classList;
        const font = localStorage.getItem("font");
        if (font) {
            if (divFont.contains("font1")) {
                divFont.remove("font2");
                divFont.remove("font3");
            } else if (divFont.contains("font2")) {
                divFont.remove("font1");
                divFont.remove("font3");
            } else if (divFont.contains("font3")) {
                divFont.remove("font1");
                divFont.remove("font2");
            }
            divFont.add(font);
        } else {
            localStorage.setItem("font", "font1");
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {console.info("Powered by LEADHOUSE - https://leadhouse.ca")}
            <CssBaseline />
            <SnackbarProvider
                style={{ margin: 2 }}
                ref={snackbarRef}
                // hideIconVariant
                // TransitionComponent={TransitionLeft}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={4000}
                // action={key => (
                //     <Button
                //         style={{ color: "white" }}
                //         onClick={() => {
                //             snackbarRef.current.closeSnackbar(key);
                //         }}
                //     >
                //         FERMER
                //     </Button>
                // )}
            >
                {/* {window.innerWidth < 768 ? (
                    <div className="mainLayout__smallscreen">
                        <img src={localStorage.getItem("language") === "en" ? logoEN : logoFR} alt="Logo" />
                        <h3>{localStorage.getItem("language") === "en" ? "Small screen detected" : "Petit écran détecté"}</h3>
                        <p>
                            {localStorage.getItem("language") === "en"
                                ? "We have detected that you are using a smaller device to access Brain Health Pro. For usability purposes due to the interactive chapters, we highly recommend using a bigger screen."
                                : "Nous avons détecté que vous utilisez un appareil plus petit pour accéder à Santé Cerveau Pro. Pour des raisons de convivialité en raison des chapitres interactifs, nous vous recommandons fortement d'utiliser un écran plus grand."}
                        </p>
                    </div>
                ) : (
                    ""
                )} */}

                {/* {!isBrowserCompatible() && (
                    <div className="mainLayout__smallscreen">
                        <img src={localStorage.getItem("language") === "en" ? logoEN : logoFR} alt="Logo" />
                        <h3>{localStorage.getItem("language") === "en" ? "Incompatible browser" : "Navigateur incompatible"}</h3>
                        <div>
                            {localStorage.getItem("language") === "en" ? (
                                <>
                                    <p>We have detected that you are using an imcompatible browser to access Brain Health Pro.</p>
                                    <p>For usability purposes due to the interactive chapters, we highly recommend using Chrome, Edge or Firefox.</p>
                                </>
                            ) : (
                                <>
                                    <p>Nous avons détecté que vous utilisez un navigateur imcompatible pour accéder à Santé Cerveau Pro.</p>
                                    <p>
                                        Pour des raisons de convivialité en raison des chapitres interactifs, nous vous recommandons fortement
                                        d'utiliser Chrome, Edge ou Firefox.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                )} */}

                <Router />
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
