// Interfaces
import { Participant } from "../../../../interfaces/Participant";

const ParticipantsTableRows = () => {

    return [
        {
            header: "Email",
            body: (u: Participant) => u.email,
        },
        {
            header: "Nickname",
            body: (u: Participant) => u.nickname,
        },
    ];
};

export default ParticipantsTableRows;
