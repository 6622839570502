import React from "react";
import { useTranslation } from "react-i18next";

// Assets
import radioButtonGif from "../../../../assets/gifs/radioButton.gif";
import numberInputGif from "../../../../assets/gifs/numberInput.gif";
import textInputGif from "../../../../assets/gifs/textInput.gif";
import multilineTextInput from "../../../../assets/gifs/multilineTextInput.gif";
import sliderGif from "../../../../assets/gifs/slider.gif";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen: (value: boolean) => void;
    types: string[];
}

const InstructionDialog: React.FC<Props> = ({ open, setOpen, types }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle style={{ fontSize: 36 }} id="alert-dialog-title">
                {t("questionnaireInstructions")}
            </DialogTitle>
            <DialogContent>
                <p style={{ fontWeight: "bold" }}>Here are the controls you will encounter while filling the different questionnaires.</p>

                {types.includes("radio") && (
                    <>
                        <h1 style={{ textDecoration: "underline" }}>Radio buttons</h1>
                        <p>When presented with a radio button, you must select only one of the available options.</p>
                        <p>To do so, you must click with your mouse on the circle. You'll notice that a dot will appear when correctly selected.</p>
                        <p style={{ fontStyle: "italic" }}>*** See the image below for a live example.</p>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                            <img style={{ border: "1px solid black", maxWidth: "100%" }} src={radioButtonGif} alt="radioButtonGif" />
                        </div>
                    </>
                )}

                {types.includes("slider") && (
                    <>
                        <h1 style={{ textDecoration: "underline" }}>Sliders</h1>
                        <p style={{ fontStyle: "italic" }}>*** See the image below for a live example.</p>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                            <img style={{ border: "1px solid black", maxWidth: "100%" }} src={sliderGif} alt="sliderGif" />
                        </div>
                    </>
                )}

                {types.includes("input") && (
                    <>
                        <h1 style={{ textDecoration: "underline" }}>Input fields </h1>
                        <h2>* Number fields</h2>
                        <p>
                            When presented with a number text field, you must first click on the control (you'll notice that the mouse cursor becomes
                            a "I").
                        </p>
                        <p>Then, you can either type the intended number or use the up or down arrow.</p>
                        <p style={{ fontStyle: "italic" }}>*** See the image below for a live example.</p>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                            <img style={{ border: "1px solid black", maxWidth: "100%" }} src={numberInputGif} alt="numberInputGif" />
                        </div>
                        <h2>* Text fields</h2>
                        <p>
                            When presented with a regular text field, you must first click on the control (you'll notice that the mouse cursor becomes
                            a "I").
                        </p>
                        <p>Then, you can either type the intended text.</p>
                        <p style={{ fontStyle: "italic" }}>*** See the image below for a live example.</p>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                            <img style={{ border: "1px solid black", maxWidth: "100%" }} src={textInputGif} alt="textInputGif" />
                        </div>
                        <h2>* Multiline text fields</h2>
                        <p style={{ fontStyle: "italic" }}>*** See the image below for a live example.</p>
                        <div style={{ marginTop: 10, marginBottom: 20 }}>
                            <img style={{ border: "1px solid black", maxWidth: "100%" }} src={multilineTextInput} alt="multilineTextInputGif" />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button fullWidth variant="contained" onClick={() => setOpen(false)} autoFocus>
                    {t("back")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InstructionDialog;
