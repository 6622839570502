export const cognitiveEngagement = {
    titleFR: "Votre engagement cognitif",
    titleEN: "Your Cognitive Engagement",
    aboutFR:
        "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter.. Essayez de terminer le questionnaire en une seule séance. ",
    aboutEN:
        "These questions will help us to know you better and see your progress. This should take about 10 minutes. Try to complete this task in one session.  ",
    instructionsFR:
        "Pour chacune des activités listées ci-dessous, veuillez indiquer à quelle fréquence vous les réalisez. Répondez à chaque question au mieux de vos capacités.",
    instructionsEN: "Rate how often you do each activity. Answer each question to the best of your abilities.",
    questions: [
        {
            questionFR: "Je lis (p. ex. journaux d’actualités, livres, revues)",
            questionEN: "I read (e.g. newspapers, books, magazines)",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je joue à des jeux (p. ex. jeux de cartes, mots croisés, casse-têtes, bridge)",
            questionEN: "I play games (e.g. puzzle, crosswords, bridge, cards)",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je fais de la musique (p. ex. instrument de musique, chorale)",
            questionEN: "I play music (e.g. choir, instrument)",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je fais des activités artisanales (p. ex. peinture, dessin, sculpture, céramique)",
            questionEN: "I do craft activities (e.g. ceramic, woodworking)",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je participe à des activités de groupe ou des activités sociales (p. ex. club de lecture, bénévolat)",
            questionEN: "I engage in group/social activities (e.g. book club, volunteering)",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je fais des activités informatiques (p. ex. recherches et visionnements sur l’internet, jeux vidéos)",
            questionEN: "I engage in computer activities (e.g. internet, web search, computer games) ",
            answers: [],
            correctAnswer: "",
        },
    ],
    answers: [
        { labelFR: "Moins d’une fois par semaine", labelEN: "Less than once per week", pointValue: 0 },
        { labelFR: "1-4 fois par semaine", labelEN: "1-4 times per week", pointValue: 3 },
        { labelFR: "Plus de 5 fois par semaine (presque chaque jour) ", labelEN: "More than 5 times per week (almost every day)", pointValue: 6 },
    ],
};

export const yourNutrition = {
    titleFR: "Votre nutrition",
    titleEN: "Your Nutrition",
    aboutFR:
        "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance. ",
    aboutEN:
        "These questions will help us to know you better and see your progress. This should take about 10 minutes. Try to complete this task in one session.  ",
    instructionsFR:
        "Évaluez la fréquence à laquelle vous avez mangé ou bu ces articles au cours des 30 derniers jours. Répondez à chaque question au mieux de vos capacités.",
    instructionsEN: "Rate how often you ate or drank these items over the past 30 days. Answer each question to the best of your abilities.",
    questions: [
        {
            questionFR: "",
            questionEN: "",
            answers: [],
            correctAnswer: "",
            commentFR:
                "Veuillez penser à ce que vous avez mangé ou bu en moyenne au cours du dernier mois, c’est-à-dire au cours des 30 derniers jours. Cochez la case qui représente le mieux votre réponse. ",
            commentEN:
                "Please think about what you usually ate or drank over the past month, that is, the past 30 days.  Check the box that best represents your answer.  ",
        },
        {
            questionFR: "Combien de portions de légumes avez-vous mangées en moyenne ?",
            questionEN: "How many servings of vegetables did you usually eat?",
            answers: [
                { labelFR: "< 1 par jour", labelEN: "< 1 per day", pointValue: 0 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 0 },
                { labelFR: "2 par jour", labelEN: "2 per day", pointValue: 0 },
                { labelFR: "3 par jour", labelEN: "3 per day", pointValue: 1 },
                { labelFR: "4 par jour", labelEN: "4 per day", pointValue: 1 },
                { labelFR: "5 par jour", labelEN: "5 per day", pointValue: 2 },
                { labelFR: "6 ou plus par jour", labelEN: "6 or more per day", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Combien de portions de fruits avez-vous mangées en moyenne ? (frais, surgelé, séchés, 100% jus)",
            questionEN: "How many servings of fruit did you usually consume?  (fresh, frozen, dried, 100% juice)",
            answers: [
                { labelFR: "< 1 par jour", labelEN: "< 1 per day", pointValue: 0 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 0 },
                { labelFR: "2 par jour", labelEN: "2 per day", pointValue: 1 },
                { labelFR: "3 par jour", labelEN: "3 per day", pointValue: 1 },
                { labelFR: "4 par jour", labelEN: "4 per day", pointValue: 2 },
                { labelFR: "5 par jour", labelEN: "5 per day", pointValue: 2 },
                { labelFR: "6 ou plus par jour", labelEN: "6 or more per day", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Combien de portions de noix non salées ou de beurres de noix entièrement naturels (y compris du beurre d’arachides) avez-vous mangées?",
            questionEN: "How many servings of unsalted nuts or all-natural nut butters (including peanuts) did you eat?",
            answers: [
                { labelFR: "< 1 par semaine", labelEN: "< 1 per week", pointValue: 0 },
                { labelFR: "1 par semaine", labelEN: "1 per week", pointValue: 0 },
                { labelFR: "2-3 par semaine", labelEN: "2-3 per week", pointValue: 1 },
                { labelFR: "4-6 par semaine", labelEN: "4-6 per week", pointValue: 1 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 2 },
                { labelFR: "2 ou plus par jour", labelEN: "2 or more per day", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Combien de portions de poisson ou fruits de mer avez-vous mangées ? N'incluez pas les produits panés ou frits.",
            questionEN: "How many servings of fish or seafood did you eat?  Don’t include battered or fried products.",
            answers: [
                { labelFR: "< 1 par semaine", labelEN: "< 1 per week", pointValue: 0 },
                { labelFR: "1-2 par semaine", labelEN: "1-2 per week", pointValue: 1 },
                { labelFR: "3 par semaine", labelEN: "3 per week", pointValue: 2 },
                { labelFR: "4-6 par semaine", labelEN: "4-6 per week", pointValue: 2 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 2 },
                { labelFR: "2 ou plus par jour", labelEN: "2 or more per day", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Combien de portions de fèves en canne ou haricots secs cuits avez-vous mangées? (par ex. pois chiches, lentilles, haricots rouges)",
            questionEN: "How many servings of canned beans or cooked dried beans did you eat?  (e.g. chickpeas, lentils, kidney beans)",
            answers: [
                { labelFR: "< 1 par semaine", labelEN: "< 1 per week", pointValue: 0 },
                { labelFR: "1 par semaine", labelEN: "1 per week", pointValue: 1 },
                { labelFR: "2-3 par semaine", labelEN: "2-3 per week", pointValue: 2 },
                { labelFR: "4-6 par semaine", labelEN: "4-6 per week", pointValue: 2 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 2 },
                { labelFR: "2 ou plus par jour", labelEN: "2 or more per day", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous mangé des grains entiers au lieu de pain blanc ou grains rafinés?  (par ex. pain à 100% grains entiers, pâtes brunes, riz brun, boulgour, orge, avoine)",
            questionEN:
                "Did you eat whole grain products instead of white breads or refined grains?  (e.g. 100% whole grain breads, brown pasta, brown rice, bulgar, barley, oatmeal)",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
                { labelFR: "La plupart des jours", labelEN: "On most days", pointValue: 1 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous consommé du lait, du yogourt et du fromage faibles en gras plutôt que des produits laitiers à forte teneur de gras? (par ex. lait 1% ou écrémé, yogourt 0-2%, fromage feta)",
            questionEN:
                "Did you eat low-fat milk, yogurt, and cheese instead of higher fat dairy products?  (e.g. 1% or skim milk, 0-2% yogurt, < 23% block cheese, feta cheese) ",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
                { labelFR: "La plupart des jours", labelEN: "On most days", pointValue: 1 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous utilisé de l’huile d’olive extra vierge comme huile culinaire principale? (dans vos cuissons, vos salades, avec votre pain ou ajoutée à d’autres aliments)",
            questionEN:
                "Did you use extra virgin olive oil as your main culinary oil?  (Used for cooking, in salad dressing, added to bread or other foods.)",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Combien de portions de viande et volaille (excluant le poisson) avez-vous mangées?",
            questionEN: "How many servings of meat and poultry (excluding fish) did you eat?",
            answers: [
                { labelFR: "< 1 par semaine", labelEN: "< 1 per week", pointValue: 2 },
                { labelFR: "1-3 par semaine", labelEN: "1-3 per week", pointValue: 2 },
                { labelFR: "4-6 par semaine", labelEN: "4-6 per week", pointValue: 2 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 2 },
                { labelFR: "2 par jour", labelEN: "2 per day", pointValue: 1 },
                { labelFR: "3 ou plus par jour", labelEN: "3 or more per day", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Au total, combien de portions de beurre, crème, margarine dure ou tartinade de produits laitiers à forte teneur en gras avez-vous mangées? (Incluant la crème sûre et le fromage à la crème)",
            questionEN:
                "In total, how many servings of butter, cream, hard-stick margarine, or high-fat dairy spreads did you eat?  (Includes full-fat sour cream and cream cheese)",
            answers: [
                { labelFR: "< 1 par semaine", labelEN: "< 1 per week", pointValue: 2 },
                { labelFR: "1-3 par semaine", labelEN: "1-3 per week", pointValue: 1 },
                { labelFR: "4-6 par semaine", labelEN: "4-6 per week", pointValue: 0 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 0 },
                { labelFR: "2 par jour", labelEN: "2 per day", pointValue: 0 },
                { labelFR: "3 ou plus par jour", labelEN: "3 or more per day", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Combien de portions de pain blanc avez-vous mangées? (par ex. pain blanc, petits pains, bagels, pain pita, tortilla)",
            questionEN: "How many servings of white bread did you eat?  (e.g. white bread, rolls, bagels, pita, or tortilla)",
            answers: [
                { labelFR: "< 1 par semaine", labelEN: "< 1 per week", pointValue: 2 },
                { labelFR: "1 par semaine", labelEN: "1 per week", pointValue: 2 },
                { labelFR: "2-3 par semaine", labelEN: "2-3 per week", pointValue: 1 },
                { labelFR: "4-6 par semaine", labelEN: "4-6 per week", pointValue: 1 },
                { labelFR: "1 par jour", labelEN: "1 per day", pointValue: 0 },
                { labelFR: "2 ou plus par jour", labelEN: "2 or more per day", pointValue: 0 },
            ],
            correctAnswer: "",
        },
    ],
    answers: [],
};

export const visionHearing = {
    titleFR: "Votre vision et votre audition",
    titleEN: "Your Vision & Hearing",
    aboutFR:
        "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Quinze minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance.",
    aboutEN:
        "These questions will help us to know you better and see your progress. This should take about 15 minutes. Try to complete this task in one session.",
    instructionsFR:
        "Veuillez répondre aux questions ci-dessous en pensant à vos activités quotidiennes – telles que vous les faites habituellement (c'est-à-dire dans les endroits habituels et avec les personnes avec lesquelles vous les faites habituellement). Si vous portez des lunettes ou des appareils auditifs, veuillez répondre aux questions en considérant que pour chaque situation, vous êtes en train de les porter.",
    instructionsEN:
        "Please answer the questions below thinking about your everyday activities – as you usually go about doing them (i.e. in the usual places, and with the people you usually do them). If you wear glasses or hearing aids, please answer as if you were using these devices.",
    questions: [
        {
            questionFR: "Comment décrivez-vous votre ouïe?",
            questionEN: "How would you describe your hearing?",
            answers: [
                { labelFR: "Excellente / Très bonne", labelEN: "Excellent / Very Good", pointValue: 0 },
                { labelFR: "Bonne", labelEN: "Good", pointValue: 1 },
                { labelFR: "Moyenne / Faible", labelEN: "Fair / Poor", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Comment décrivez-vous votre vision?",
            questionEN: "How would you characterize your eyesight?",
            answers: [
                { labelFR: "Excellente / Très bonne", labelEN: "Excellent / Very Good", pointValue: 0 },
                { labelFR: "Bonne", labelEN: "Good", pointValue: 1 },
                { labelFR: "Moyenne / Faible", labelEN: "Fair / Poor", pointValue: 2 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Avez-vous un trouble auditif qui vous empêche d’utiliser le téléphone aussi souvent que vous le souhaitez?",
            questionEN: "Does a hearing problem cause you to use the phone less often than you would like? ",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous des difficultés à lire les étiquettes ou autres imprimés (par ex. : les étiquettes sur les flacons de médicaments, sur les récipients alimentaires, les journaux ou les livres)?",
            questionEN: "Do you have any difficulty reading print (e.g., labels on medicine bottles, food containers, newspapers or books)?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous l'impression que trouver ou comprendre des informations est plus difficile pour vous en raison de votre vision ou de votre audition?",
            questionEN: "Do you feel that finding or understanding information is harder for you because of your vision or your hearing?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous de la difficulté à participer à des activités physiques (par exemple: entraînement, quilles, tennis, golf) en raison de votre vision ou de votre audition?",
            questionEN:
                "Do you have any difficulty taking part in physical activities (e.g., working out, bowling, tennis, or golf) because of your vision or hearing?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Un problème de vision ou d'audition vous a-t-il déjà rendu irritable, fâché ou frustré?",
            questionEN: "Does a vision or hearing problem ever make you feel irritable, angry or frustrated?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous de la difficulté à reconnaître les personnes (voix ou visages) lorsqu’elles sont près de vous (par ex.: de l’autre côté de la pièce)?",
            questionEN: "Do you have any difficulty recognizing people (voices or faces) when they are near to you (e.g., across the room)?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous le sentiment qu'une quelconque difficulté d'audition ou de vision limite ou entrave votre vie personnelle ou sociale?",
            questionEN: "Do you feel that any difficulty with your hearing or vision limits or hampers your personal or social life?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR:
                "Avez-vous parfois l'impression que des problèmes de vision ou d'audition vous empêchent de faire les choses que vous aimeriez faire?",
            questionEN: "Do you ever feel that problems with your vision or hearing make it difficult to do the things you would like to do?",
            answers: [
                { labelFR: "Oui", labelEN: "Yes", pointValue: 2 },
                { labelFR: "Parfois", labelEN: "Sometimes", pointValue: 1 },
                { labelFR: "Non", labelEN: "No", pointValue: 0 },
            ],
            correctAnswer: "",
        },
    ],
    answers: [
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
    ],
};

export const EXAMPLE = {
    titleFR: "",
    titleEN: "",
    aboutFR: "",
    aboutEN: "",
    instructionsFR: "",
    instructionsEN: "",
    questions: [
        {
            questionFR: "",
            questionEN: "",
            answers: [],
            correctAnswer: "",
        },
    ],
    answers: [
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
    ],
};
