import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Mui
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

// Utils
import { changeLanguage } from "../../../../utils/language";

// Assets
import theme from "../../../../assets/nav/theme.png";
import { MdClose, MdMenu } from "react-icons/md";

// Assets
import logoEN from "../../../../assets/logos/navLogoEN.png";
import logoFR from "../../../../assets/logos/navLogoFR.png";

const TopBarForm = () => {
    const { t } = useTranslation();

    // States
    const [language, setLanguage] = useState("en");
    const [width, setWidth] = useState(window.innerWidth);
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    };

    const handleSize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleSize);

    useEffect(() => {
        handleSize();
    }, []);

    // toggle theme
    const changeTheme = () => {
        const divColor = (document.getElementById("color") as any).classList;
        if (divColor.contains("normal")) {
            divColor.replace("normal", "inverse");
            localStorage.setItem("theme", "inverse");
        } else {
            divColor.replace("inverse", "normal");
            localStorage.setItem("theme", "normal");
        }
    };

    // toggle font
    const changeFont = (value: string) => {
        const divFont = (document.getElementById("font") as any).classList;
        if (value === "font1") {
            divFont.add("font1");
            divFont.remove("font2", "font3");
            localStorage.setItem("font", "font1");
        } else if (value === "font2") {
            divFont.add("font2");
            divFont.remove("font1", "font3");
            localStorage.setItem("font", "font2");
        } else if (value === "font3") {
            divFont.add("font3");
            divFont.remove("font1", "font2");
            localStorage.setItem("font", "font3");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("language")) {
            const lang = localStorage.getItem("language");
            if (lang) changeLanguage(lang, setLanguage);
        } else {
            changeLanguage("en", setLanguage);
        }
    }, []);

    return width > 1200 ? (
        <div className="topBarForm">
            <img src={localStorage.getItem("language") === "en" ? logoEN : logoFR} alt="" />
            <div className="topBarForm__right">
                <div>
                    <Button onClick={changeTheme} size="medium">
                        <img src={theme} alt="" />
                    </Button>
                </div>
                <div className="nav__content__fontSize">
                    <FormControl>
                        <Select className="sizeSelector" value={"none"} onChange={e => changeFont(e.target.value)} autoWidth>
                            <MenuItem disabled value="none">
                                <span className="nav__fontSize--placeholder">{t("textSize")}</span>
                            </MenuItem>
                            <MenuItem value="font1">
                                <span className="nav__fontSize--font1">Aa</span>
                            </MenuItem>
                            <MenuItem value="font2">
                                <span className="nav__fontSize--font2">Aa</span>
                            </MenuItem>
                            <MenuItem value="font3">
                                <span className="nav__fontSize--font3">Aa</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="nav__content__fontSize">
                    <FormControl>
                        <Select
                            className="nav__button__textSize"
                            value={language}
                            onChange={e => changeLanguage(e.target.value, setLanguage)}
                            autoWidth
                        >
                            <MenuItem value="en">
                                <span className="nav__fontSize--placeholder">English</span>
                            </MenuItem>
                            <MenuItem value="fr">
                                <span className="nav__fontSize--placeholder">Français</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    ) : (
        <div className="topBarForm">
            <img src={localStorage.getItem("language") === "en" ? logoEN : logoFR} alt="" />
            <div className="hbg-menu" onClick={handleClick}>
                {clicked ? <MdClose /> : <MdMenu />}
            </div>
            <div className={clicked ? "topBarForm__right active" : "topBarForm__right"}>
                <div>
                    <Button onClick={changeTheme} size="medium">
                        <img src={theme} alt="" />
                    </Button>
                </div>
                <div className="nav__content__fontSize">
                    <FormControl>
                        <Select className="sizeSelector" value={"none"} onChange={e => changeFont(e.target.value)} autoWidth>
                            <MenuItem disabled value="none">
                                <span className="nav__fontSize--placeholder">{t("textSize")}</span>
                            </MenuItem>
                            <MenuItem value="font1">
                                <span className="nav__fontSize--font1">Aa</span>
                            </MenuItem>
                            <MenuItem value="font2">
                                <span className="nav__fontSize--font2">Aa</span>
                            </MenuItem>
                            <MenuItem value="font3">
                                <span className="nav__fontSize--font3">Aa</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="nav__content__fontSize">
                    <FormControl>
                        <Select
                            className="nav__button__textSize"
                            value={language}
                            onChange={e => changeLanguage(e.target.value, setLanguage)}
                            autoWidth
                        >
                            <MenuItem value="en">
                                <span className="nav__fontSize--placeholder">English</span>
                            </MenuItem>
                            <MenuItem value="fr">
                                <span className="nav__fontSize--placeholder">Français</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

export default TopBarForm;
