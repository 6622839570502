import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "../locales/en";
import { fr } from "../locales/fr";
import moment from "moment";
import "moment/locale/fr";

const resources = {
    en,
    fr,
};

const defaultLanguage = localStorage.getItem("language") || "en";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: ["en", "fr"],
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

moment.locale(i18n.language);

export default i18n;
