export const usability = {
    titleFR: "Votre expérience de Santé Cerveau Pro",
    titleEN: "Your Brain Health Pro Experience",
    aboutFR:
        "Le sondage ci-dessous nous aidera à améliorer le programme Santé Cerveau Pro. Dix à quinze minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance.",
    aboutEN: "These questions help us improve Brain Health Pro. This should take 10-15 minutes. Try to complete this task in one session.",
    instructionsFR: "",
    instructionsEN: "",
    questions: [
        {
            questionFR: "J’utilise le site web de Santé Cerveau Pro…",
            questionEN: "I use the Brain Health Pro website…",
            answers: [
                { labelFR: "Moins d’une fois par mois ", labelEN: "Less than once per month", pointValue: 0 },
                { labelFR: "Environ une fois par semaine", labelEN: "About once per week", pointValue: 1 },
                { labelFR: "1-3 fois par semaine", labelEN: "1-3 times per week", pointValue: 2 },
                { labelFR: "4-7 fois par semaine", labelEN: "4-7 times per week", pointValue: 3 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "J'aimerais utiliser ce site web fréquemment.",
            questionEN: "I would like to use this website frequently.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "J’ai trouvé que ce site web était facile d’utilisation.",
            questionEN: "I thought this website was easy to use.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Il était très facile de passer d’une section du site à une autre.",
            questionEN: "It was easy to navigate between the different sections of this website.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "La plupart des gens pourraient rapidement apprendre à utiliser ce site internet.",
            questionEN: "Most people would learn to use this website very quickly.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je me suis senti très confiant(e) en utilisant ce site web.",
            questionEN: "I felt very confident using this website.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "",
            questionEN: "",
            answers: [],
            correctAnswer: "",
            commentFR:
                "Merci pour vos réponses et commentaires jusqu’à maintenant...Nous avons quelques questions additionnelles.Veuillez les lire attentivement.",
            commentEN: "Thank you for your feedback so far. We have a few more questions. Please read each one carefully.",
        },
        {
            questionFR: "J’ai trouvé ce site web inutilement compliqué.",
            questionEN: "I found this website unnecessarily complex.",
            answers: [
                { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 4 },
                { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 3 },
                { labelFR: "Neutre", labelEN: "Neutral", pointValue: 2 },
                { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 1 },
                { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "J'aurais besoin d'une assistance technique pour pouvoir utiliser ce site web par moi-même.",
            questionEN: "I would need technical assistance to be able to use this website on my own.",
            answers: [
                { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 4 },
                { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 3 },
                { labelFR: "Neutre", labelEN: "Neutral", pointValue: 2 },
                { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 1 },
                { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Il y avait trop d’incohérence au sein du contenu du site.",
            questionEN: "There was too much inconsistency between the website’s contents.",
            answers: [
                { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 4 },
                { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 3 },
                { labelFR: "Neutre", labelEN: "Neutral", pointValue: 2 },
                { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 1 },
                { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "Ce site web est très compliqué et difficile à utiliser.",
            questionEN: "This website is very cumbersome or awkward to use.",
            answers: [
                { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 4 },
                { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 3 },
                { labelFR: "Neutre", labelEN: "Neutral", pointValue: 2 },
                { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 1 },
                { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 0 },
            ],
            correctAnswer: "",
        },
        {
            questionFR: "J’ai eu besoin d’apprendre beaucoup de choses avant de pouvoir utiliser ce site web.",
            questionEN: "I needed to learn a lot of things before I could get going with this website.",
            answers: [
                { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 4 },
                { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 3 },
                { labelFR: "Neutre", labelEN: "Neutral", pointValue: 2 },
                { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 1 },
                { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 0 },
            ],
            correctAnswer: "",
        },
    ],
    answers: [
        { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 0 },
        { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 1 },
        { labelFR: "Neutre", labelEN: "Neutral", pointValue: 2 },
        { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 3 },
        { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 4 },
    ],
};

export const acceptability = {
    titleFR: "Aidez-nous à améliorer le programme Santé Cerveau Pro",
    titleEN: "Help us Improve Brain Health Pro",
    aboutFR:
        "Le sondage ci-dessous nous aidera à améliorer le programme de soutien à la santé du cerveau. Dix à quinze minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance.",
    aboutEN: "These questions help us improve Brain Health Pro. This should take 10-15 minutes. Try to complete this task in one session.",
    instructionsFR: "",
    instructionsEN: "",
    questions: [
        {
            questionFR: "Le programme Santé Cerveau Pro m’a aidé à mieux comprendre ma santé personnelle.",
            questionEN: "Brain Health Pro has helped me to better understand my health.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le programme Santé Cerveau Pro m’a aidé à améliorer mes connaissances par rapport à la santé en général.",
            questionEN: "Brain Health Pro helped me to improve my knowledge related to health.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "J’aimerais continuer à avoir accès au programme Santé Cerveau Pro dans le futur.",
            questionEN: "I would like to have access to Brain Health Pro in the future.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le programme Santé Cerveau Pro m’a incité à prendre soin de ma santé cérébrale.",
            questionEN: "Brain Health Pro has motivated me to take care of my brain health.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "J’applique, dans ma vie quotidienne, plusieurs recommandations venant du programme Santé Cerveau Pro.",
            questionEN: "I have applied several recommendations from Brain Health Pro in my everyday life.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "L’information contenue dans le programme Santé Cerveau Pro était facile à comprendre.",
            questionEN: "Brain Health Pro health information was easy to understand.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Les activités du programme Santé Cerveau Pro étaient intéressantes.",
            questionEN: "The activities in Brain Health Pro were interesting.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je pourrais facilement expliquer aux autres les informations contenues dans le programme Santé Cerveau Pro.",
            questionEN: "I could easily explain the health information in Brain Health Pro to others.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je pourrais facilement expliquer aux autres comment utiliser le programme Santé Cerveau Pro.",
            questionEN: "I could easily explain to others how to use Brain Health Pro.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je pourrais facilement expliquer aux autres les avantages du programme Santé Cerveau Pro.",
            questionEN: "I could easily explain to others the advantages of Brain Health Pro.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le programme Santé Cerveau Pro m’a aidé à mieux accomplir certaines tâches de la vie quotidienne.",
            questionEN: "Using Brain Health Pro helped me to better accomplish some tasks of everyday life.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le programme Santé Cerveau Pro m’a aidé à accomplir les tâches de la vie quotidienne avec moins d’efforts.",
            questionEN: "Using Brain Health Pro has helped me to complete tasks of everyday life with less effort.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Apprendre à utiliser le site web a été facile pour moi.",
            questionEN: "Learning to operate this website was easy for me.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le programme Santé Cerveau Pro m’a aidé à accomplir certains buts personnels.",
            questionEN: "Brain Health Pro has helped me to reach some of my personal goals.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je comprends très bien comment utiliser ce site web.",
            questionEN: "I clearly understand how to interact with this website.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le design du site web du programme Santé Cerveau Pro permet de comprendre facilement comment utiliser le site.",
            questionEN: "The Brain Health Pro website design makes it easy to understand how to use the site.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le site web du programme Santé Cerveau Pro est attrayant visuellement.",
            questionEN: "The Brain Health Pro website is visually appealing.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR:
                "Le site web du programme Santé Cerveau Pro était bien adapté à ma situation personnelle et à mes propres facteurs de risque.",
            questionEN: "The Brain Health Pro website was personalised to my own situation and risks.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Le système de réalisation implémenté dans le programme Santé Cerveau Pro agissait comme un motivateur.",
            questionEN: "The achievements system in the Brain Health Pro website was motivating.",
            answers: [],
            correctAnswer: "",
        },
        {
            questionFR: "Je recommanderais le programme Santé Cerveau Pro à mes amis et à mes proches.",
            questionEN: "I would recommend the Brain Health Pro website to my friends or relatives.",
            answers: [],
            correctAnswer: "",
        },
    ],
    answers: [
        { labelFR: "Fortement en désaccord", labelEN: "Strongly disagree", pointValue: 0 },
        { labelFR: "Modérément en désaccord", labelEN: "Moderately disagree", pointValue: 1 },
        { labelFR: "Plutôt en désaccord", labelEN: "Somewhat disagree", pointValue: 2 },
        { labelFR: "Neutre", labelEN: "Neutral", pointValue: 3 },
        { labelFR: "Plutôt d’accord", labelEN: "Somewhat agree", pointValue: 4 },
        { labelFR: "Modérément d’accord", labelEN: "Moderately agree", pointValue: 5 },
        { labelFR: "Fortement d’accord", labelEN: "Strongly agree", pointValue: 6 },
    ],
};

export const EXAMPLE = {
    titleFR: "",
    titleEN: "",
    aboutFR: "",
    aboutEN: "",
    instructionsFR: "",
    instructionsEN: "",
    questions: [
        {
            questionFR: "",
            questionEN: "",
            answers: [],
            correctAnswer: "",
        },
    ],
    answers: [
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
        { labelFR: "", labelEN: "", pointValue: 0 },
    ],
};
