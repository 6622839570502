import i18n from "../utils/i18n";

// Interfaces
import { Chapter, ChapterTopic } from "../interfaces/Chapter";
import { StatTopic } from "../interfaces/Stat";
import { Participant } from "../interfaces/Participant";

// Assets
import iconBrain from "../assets/icons/icon-brain.png";
import iconCognitive from "../assets/icons/icon-cognitive.png";
import iconHearVision from "../assets/icons/icon-hear-vision.png";
import iconSocial from "../assets/icons/icon-social.png";
import iconSleep from "../assets/icons/icon-sleep.png";
import iconPhysical from "../assets/icons/icon-physical.png";
import iconNutrition from "../assets/icons/icon-nutrition.png";
import iconVascular from "../assets/icons/icon-cardio.png";

// Utils
import { areAllChaptersUnlocked } from "./chapter";

// Firebase
import { firestore } from "../firebase/firebase";
import {
    collection as firebaseCollection,
    getDocs,
    query,
    where,
    getDoc,
    doc as firebaseDoc,
    updateDoc,
    getCountFromServer,
    collection,
    doc,
} from "firebase/firestore";
import { genDoc } from "../firebase/requests";

export const getTitleFromTopic = (topic: ChapterTopic | StatTopic) => {
    switch (topic) {
        case "Brain Overview":
            return i18n.t("brainOverviewTitle");
        case "Physical":
            return i18n.t("physicalTitle");
        case "Cognitive":
            return i18n.t("cognitiveTitle");
        case "Nutrition":
            return i18n.t("nutritionTitle");
        case "Sleep":
            return i18n.t("sleepTitle");
        case "Social Psychological":
            return i18n.t("socialTitle");
        case "Vascular Health":
            return i18n.t("vascularTitle");
        case "Vision Hearing":
            return i18n.t("visionTitle");
        case "Self Efficacy":
            return i18n.t("efficacyTitle");
        case "Dementia Literacy":
            return i18n.t("dementiaTitle");
        case "Attitudes towards dementia":
            return i18n.t("attitudesTitle");
        default:
            return "";
    }
};

export const getIconFromTopic = (topic: ChapterTopic | StatTopic) => {
    switch (topic) {
        case "Brain Overview":
            return iconBrain;
        case "Physical":
            return iconPhysical;
        case "Cognitive":
            return iconCognitive;
        case "Nutrition":
            return iconNutrition;
        case "Sleep":
            return iconSleep;
        case "Social Psychological":
            return iconSocial;
        case "Vascular Health":
            return iconVascular;
        case "Vision Hearing":
            return iconHearVision;
        default:
            return "";
    }
};

export const translateTopic = (topicName: string) => {
    switch (topicName) {
        case "Brain Overview":
            return "Aperçu sur la santé du cerveau";
        case "Physical":
            return "Activité physique";
        case "Cognitive":
            return "Engagement cognitif";
        case "Nutrition":
            return "Nutrition";
        case "Sleep":
            return "Sommeil";
        case "Social Psychological":
            return "Santé psychologique et sociale";
        case "Vascular Health":
            return "Santé cardiovasculaire";
        case "Vision Hearing":
            return "Vision et Audition";
        default:
            return "";
    }
};

export interface TopicAtRisk {
    topic: StatTopic;
    value: number;
}

export const getDangerLevelFromValue = (value: number) => {
    let riskLevel: "low" | "moderate" | "high" = "low";
    if (value <= 60) riskLevel = "low";
    if (value > 60 && value <= 120) riskLevel = "moderate";
    if (value > 120) riskLevel = "high";

    return riskLevel;
};

export const getGaugeInfoAndDangerLevel = (topic: string, score: number) => {
    let gaugeValue = 0;
    let riskClass = "";
    let riskLabel = "";
    let dangerLevel = "";

    if (topic === "Physical") {
        // Low risk
        if (score >= 1.75) {
            gaugeValue = Math.round(60 - ((score - 1.75) / (9 - 1.75)) * (180 / 3));
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score > 1.25 && score < 1.75) {
            gaugeValue = Math.round(60 - ((score - 1.25) / (1.75 - 1.25)) * (180 / 3) + 180 / 3);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score <= 1.25) {
            gaugeValue = Math.round(60 - (score / 1.25) * (180 / 3) + 360 / 3);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }
    if (topic === "Cognitive") {
        // Low risk
        if (score >= 15) {
            gaugeValue = Math.round(60 - ((score - 15) / (36 - 15)) * (180 / 3));
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score >= 8 && score <= 14) {
            gaugeValue = Math.round(60 - ((score - 8) / (14 - 8)) * (180 / 3) + 180 / 3);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score <= 7) {
            gaugeValue = Math.round(60 - (score / 7) * (180 / 3) + 360 / 3);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }
    if (topic === "Nutrition") {
        // Low risk
        if (score >= 14 && score <= 22) {
            gaugeValue = Math.round(60 - ((score - 14) / (22 - 14)) * (180 / 3));
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score >= 8 && score <= 13) {
            gaugeValue = Math.round(60 - ((score - 8) / (13 - 8)) * (180 / 3) + 180 / 3);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score <= 7) {
            gaugeValue = Math.round(60 - (score / 7) * (180 / 3) + 360 / 3);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }
    if (topic === "Sleep") {
        // Low risk
        if (score >= 16 && score <= 20) {
            gaugeValue = Math.round(60 - ((score - 16) / (20 - 16)) * (180 / 3));
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score >= 8 && score <= 15) {
            gaugeValue = Math.round(((score - 8) / (15 - 8)) * 60 + 60);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score >= 1 && score <= 7) {
            gaugeValue = Math.round(60 - ((score - 1) / (7 - 1)) * 60 + 120);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }
    if (topic === "Social Psychological") {
        // Low risk
        if (score >= -1 && score <= 15) {
            gaugeValue = Math.round(((score - -1) / (15 - -1)) * (180 / 3));
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score >= 16 && score <= 33) {
            gaugeValue = Math.round(((score - 16) / (33 - 16)) * (180 / 3) + 180 / 3);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score >= 34 && score <= 50) {
            gaugeValue = Math.round(((score - 34) / (50 - 34)) * (180 / 3) + 360 / 3);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }
    if (topic === "Vascular Health") {
        // Low risk
        if (score >= 7) {
            gaugeValue = 30;
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score >= 4 && score <= 6) {
            gaugeValue = Math.round(60 - ((score - 4) / (6 - 4)) * (180 / 3) + 180 / 3);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score >= -4 && score <= 3) {
            gaugeValue = Math.round(60 - ((score - -4) / (3 - -4)) * (180 / 3) + 360 / 3);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }
    if (topic === "Vision Hearing") {
        // Low risk
        if (score >= 0 && score <= 8) {
            gaugeValue = Math.round(((score - 0) / (8 - 0)) * (180 / 3));
            riskClass = "lowRisk";
            riskLabel = "Low Risk";
            dangerLevel = "low";
        }
        // Moderate risk
        if (score >= 9 && score <= 14) {
            gaugeValue = Math.round(((score - 9) / (14 - 9)) * (180 / 3) + 180 / 3);
            riskClass = "moderateRisk";
            riskLabel = "Moderate Risk";
            dangerLevel = "moderate";
        }
        // High risk
        if (score >= 15 && score <= 20) {
            gaugeValue = Math.round(((score - 15) / (20 - 15)) * (180 / 3) + 360 / 3);
            riskClass = "highRisk";
            riskLabel = "High Risk";
            dangerLevel = "high";
        }
    }

    // Needle cheat
    if (gaugeValue > 177) gaugeValue = 177;

    return { gaugeValue, riskClass, riskLabel, dangerLevel };
};

const getNextMissingChapter = async (
    unlockedChapters: {
        "Brain Overview": string[];
        Cognitive: string[];
        "Social Psychological": string[];
        Sleep: string[];
        "Vascular Health": string[];
        Nutrition: string[];
        Physical: string[];
        "Vision Hearing": string[];
    },
    topic: ChapterTopic,
    chapters: Chapter[],
    lastCompletedChapterIndex: number
) => {
    for (let i = 0; i < lastCompletedChapterIndex; i++) {
        if (chapters[i] && chapters[i].id) {
            if (!unlockedChapters[topic].some(x => x === chapters[i].id)) {
                // missing chapter
                return chapters[i];
            }
        }
    }

    return undefined;
};

const getNextChapter = async (participantId: string, isPrioritizedTopic: boolean, chosenTopics: ChapterTopic[]): Promise<Chapter | undefined> => {
    // No more chapters
    if (await areAllChaptersUnlocked(participantId)) return;

    const participant = genDoc<Participant>()(await getDoc(firebaseDoc(firestore, "Participants", participantId)));

    if (participant.unlockedChapters && participant && participant.id) {
        let entries: [string, string[]][] = [];

        entries = Object.entries(participant.unlockedChapters)
            // Filter prioritized or not
            .filter(x =>
                isPrioritizedTopic
                    ? participant.prioritizedTopics.includes(x[0] as ChapterTopic)
                    : !participant.prioritizedTopics.includes(x[0] as ChapterTopic)
            )
            // Filter already chosen chapters
            .filter(x => {
                if (!hasOnlyOneTopicWithChaptersRemaining(participant.id as string)) return !chosenTopics.includes(x[0] as ChapterTopic);
                return true;
            })
            .sort((a, b) => a[1].length - b[1].length);

        if (entries.length > 0) {
            const maxEntry = entries.length;
            let maxChapter = 0;
            let entryIndex = 0;
            let firstLoop = true;

            do {
                if (!firstLoop) entryIndex++;
                else firstLoop = false;
                if (entryIndex > maxEntry - 1) break;

                // Get the highest chapter number
                const countPayload = await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", entries[entryIndex][0])));
                maxChapter = countPayload.data().count;
            } while (entries[entryIndex][1].length + 1 > maxChapter && !(await areAllChaptersUnlocked(participant.id)));

            if (entryIndex > maxEntry - 1) {
                return await getNextChapter(participantId, false, chosenTopics);
            } else {
                // get chapter by index
                const chaptersPayload = await getDocs(
                    query(firebaseCollection(firestore, "Chapters"), where("isDeleted", "==", false), where("topic", "==", entries[entryIndex][0]))
                );
                const chapters = chaptersPayload.docs.map(genDoc<Chapter>()).sort((a, b) => (a.chapter < b.chapter ? -1 : 1));

                // Check if all previous chapters are completed
                const missingChapter = await getNextMissingChapter(
                    participant.unlockedChapters,
                    entries[entryIndex][0] as ChapterTopic,
                    chapters,
                    entries[entryIndex][1].length
                );

                if (missingChapter) {
                    console.log("missing chapter", entries[entryIndex][0], entries[entryIndex][1].length, missingChapter.chapter, missingChapter.id);

                    await unlockChapter(participant.id, missingChapter);
                    return missingChapter;
                } else {
                    await unlockChapter(participant.id, chapters[entries[entryIndex][1].length]);
                    return chapters[entries[entryIndex][1].length];
                }
            }
        }
    }
};

const unlockChapter = async (participantId: string, chapter: Chapter) => {
    const participant = genDoc<Participant>()(await getDoc(firebaseDoc(firestore, "Participants", participantId)));

    if (participant && participant.id && chapter && chapter.id) {
        if (participant.unlockedChapters && !participant.unlockedChapters[chapter.topic].includes(chapter.id)) {
            await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                ...participant,
                unlockedChapters: {
                    ...participant.unlockedChapters,
                    [chapter.topic]: [...participant.unlockedChapters[chapter.topic], chapter.id],
                },
            });
        }
    }
};

export const unlockChapters = async (participantId: string, chapters: Chapter[]) => {
    for (const chapter of chapters) {
        await unlockChapter(participantId, chapter);
    }
};

const getSpecialWeekChapters = async (participantId: string, currentWeek: number, chapters: any) => {
    const participant = genDoc<Participant>()(await getDoc(firebaseDoc(firestore, "Participants", participantId)));
    // Keeps track of already picked topics
    const chosenTopics: ChapterTopic[] = [];

    let data: any[] = [];

    if (participant && participant.id) {
        if (chapters) {
            const localQuery = query(
                firebaseCollection(firestore, "Chapters"),
                where("isDeleted", "==", false),
                where("topic", "==", "Brain Overview"),
                Array.isArray(chapters) ? where("chapter", "in", chapters) : where("chapter", "==", chapters)
            );
            const payload = await getDocs(localQuery);
            data = payload.docs.map(genDoc<Chapter>());

            await unlockChapters(participant.id, data);
        }

        if (currentWeek === 2) {
            // already 4 chapters. do nothing.
        } else if (currentWeek === 3) {
            const chap1 = await getNextChapter(participant.id, true, chosenTopics);
            if (chap1) {
                chosenTopics.push(chap1.topic);
                data.push(chap1);
            }

            const chap2 = await getNextChapter(participant.id, true, chosenTopics);
            if (chap2) {
                chosenTopics.push(chap2.topic);
                data.push(chap2);
            }
        } else {
            const chap1 = await getNextChapter(participant.id, true, chosenTopics);
            if (chap1) {
                chosenTopics.push(chap1.topic);
                data.push(chap1);
            }

            const chap2 = await getNextChapter(participant.id, true, chosenTopics);
            if (chap2) {
                chosenTopics.push(chap2.topic);
                data.push(chap2);
            }

            const chap3 = await getNextChapter(participant.id, false, chosenTopics);
            if (chap3) {
                chosenTopics.push(chap3.topic);
                data.push(chap3);
            }
        }
        return data;
    }
    return [] as Chapter[];
};

export const getWeeklyChapters = async (currentWeek: number, participantId: string) => {
    const participant = genDoc<Participant>()(await getDoc(firebaseDoc(firestore, "Participants", participantId)));

    if (participant && participant.id) {
        if (await areAllChaptersUnlocked(participant.id)) return [] as Chapter[];

        let chapters: Chapter[] = [];
        // Keeps track of already picked topics
        const chosenTopics: ChapterTopic[] = [];

        if (currentWeek === 2) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, [2, 3, 4]);
        } else if (currentWeek === 3) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, [5, 6.1, 6.2]);
        } else if (currentWeek === 13) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 7);
        } else if (currentWeek === 15) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 8);
        } else if (currentWeek === 18) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 9);
        } else if (currentWeek === 21) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 10);
        } else if (currentWeek === 24) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 11);
        } else if (currentWeek === 26) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 12);
        } else if (currentWeek === 29) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 13);
        } else if (currentWeek === 32) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 14);
        } else if (currentWeek === 35) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 15);
        } else if (currentWeek === 37) {
            chapters = await getSpecialWeekChapters(participant.id, currentWeek, 16);
        } else if (
            (currentWeek >= 4 && currentWeek <= 12) ||
            currentWeek === 14 ||
            (currentWeek >= 16 && currentWeek <= 17) ||
            (currentWeek >= 19 && currentWeek <= 20) ||
            (currentWeek >= 22 && currentWeek <= 23) ||
            currentWeek === 25 ||
            (currentWeek >= 27 && currentWeek <= 28) ||
            (currentWeek >= 30 && currentWeek <= 31) ||
            (currentWeek >= 33 && currentWeek <= 34) ||
            currentWeek === 36 ||
            currentWeek >= 38
        ) {
            if (participant && participant.id) {
                const chap1 = await getNextChapter(participant.id, true, chosenTopics);
                if (chap1) {
                    chosenTopics.push(chap1.topic);
                    chapters.push(chap1);
                }

                const chap2 = await getNextChapter(participant.id, true, chosenTopics);
                if (chap2) {
                    chosenTopics.push(chap2.topic);
                    chapters.push(chap2);
                }

                const chap3 = await getNextChapter(participant.id, false, chosenTopics);
                if (chap3) {
                    chosenTopics.push(chap3.topic);
                    chapters.push(chap3);
                }

                const chap4 = await getNextChapter(participant.id, false, chosenTopics);
                if (chap4) {
                    chosenTopics.push(chap4.topic);
                    chapters.push(chap4);
                }
            }
        }
        return chapters;
    }
    return [] as Chapter[];
};

const hasOnlyOneTopicWithChaptersRemaining = async (participantId: string) => {
    let topicsWithChaptersRemaining = 0;

    if (!(await areAllChaptersUnlocked(participantId))) {
        const participantPayload = await getDoc(doc(firestore, "Participants", participantId));
        const participant = genDoc<Participant>()(participantPayload);

        if (!participant) throw new Error("Invalid participant id");

        const sleepChapters = (await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Sleep")))).data().count;
        const vascularChapters = (await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Vascular Health")))).data()
            .count;
        const visionHearingChapters = (
            await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Vision Hearing")))
        ).data().count;
        const brainOverviewChapters = (
            await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Brain Overview")))
        ).data().count;
        const cognitiveChapters = (await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Cognitive")))).data()
            .count;
        const nutritionChapters = (await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Nutrition")))).data()
            .count;
        const physicalChapters = (await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Physical")))).data().count;
        const socialChapters = (
            await getCountFromServer(query(collection(firestore, "Chapters"), where("topic", "==", "Social Psychological")))
        ).data().count;

        if (participant.unlockedChapters["Brain Overview"].length !== brainOverviewChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Cognitive"].length !== cognitiveChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Nutrition"].length !== nutritionChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Physical"].length !== physicalChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Sleep"].length !== sleepChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Social Psychological"].length !== socialChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Vascular Health"].length !== vascularChapters) topicsWithChaptersRemaining++;
        if (participant.unlockedChapters["Vision Hearing"].length !== visionHearingChapters) topicsWithChaptersRemaining++;

        return topicsWithChaptersRemaining === 1;
    }
    return false;
};
