// Firebase
import { firestore } from "../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc, getDocs, query, collection, doc } from "firebase/firestore";
import { genDoc } from "../firebase/requests";

// Interfaces
import { Chapter } from "../interfaces/Chapter";
import { Participant } from "../interfaces/Participant";

export const completeChapter = async (participantId: string, currentWeek: number, chapterId: string) => {
    const participant = genDoc<Participant>()(await getDoc(firebaseDoc(firestore, "Participants", participantId)));
    const chapter = genDoc<Chapter>()(await getDoc(firebaseDoc(firestore, "Chapters", chapterId)));

    if (participant && participant.id && chapter.id) {
        // Add missing objects
        if (!participant.completedChaptersHistory) {
            participant.completedChaptersHistory = {
                "Brain Overview": [],
                Cognitive: [],
                "Social Psychological": [],
                Sleep: [],
                "Vascular Health": [],
                Nutrition: [],
                Physical: [],
                "Vision Hearing": [],
            };
        }
        if (!participant.unlockedChaptersHistory) {
            participant.unlockedChaptersHistory = {
                "Brain Overview": [],
                Cognitive: [],
                "Social Psychological": [],
                Sleep: [],
                "Vascular Health": [],
                Nutrition: [],
                Physical: [],
                "Vision Hearing": [],
            };
        }

        if (
            participant.completedChapters &&
            participant.completedChapters[chapter.topic] &&
            !participant.completedChapters[chapter.topic].includes(chapter.id)
        ) {
            const data: any = {
                completedChapters: {
                    ...participant.completedChapters,
                    [chapter.topic]: [...participant.completedChapters[chapter.topic], chapter.id],
                },
                points: participant.points + 1,
            };

            if (!participant.unlockedChapters[chapter.topic].includes(chapterId)) {
                data.unlockedChapters = {
                    ...participant.unlockedChapters,
                    [chapter.topic]: [...participant.unlockedChapters[chapter.topic], chapter.id],
                };
            }

            if (!participant.unlockedChaptersHistory[chapter.topic].some(x => x.id === chapterId)) {
                data.unlockedChaptersHistory = {
                    ...participant.unlockedChaptersHistory,
                    [chapter.topic]: [...participant.unlockedChaptersHistory[chapter.topic], { id: chapter.id, date: new Date() }],
                };
            }

            if (!participant.completedChaptersHistory[chapter.topic].some(x => x.id === chapterId)) {
                data.completedChaptersHistory = {
                    ...participant.completedChaptersHistory,
                    [chapter.topic]: [...participant.completedChaptersHistory[chapter.topic], { id: chapter.id, date: new Date() }],
                };
            }

            if (currentWeek === 0 || currentWeek === 1) {
                data.timeline = {
                    ...participant.timeline,
                    week01: [...participant.timeline.week01, chapter.id],
                };
            }
            await updateDoc(firebaseDoc(firestore, "Participants", participant.id), data);
        }

        // Check previous week's completion
        if (currentWeek >= 2) {
            if (!(await weeklyChapterCompleted(currentWeek - 1, participant.id))) {
                await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                    weeksInRow: 0,
                });
            }
        }

        // Must be one of this week's chapter
        if (
            participant.timeline &&
            (participant.timeline as any)[`week${currentWeek < 10 ? `0${currentWeek}` : currentWeek}`] &&
            (participant.timeline as any)[`week${currentWeek < 10 ? `0${currentWeek}` : currentWeek}`].includes(chapter.id)
        ) {
            // Check the week's completion
            if (await weeklyChapterCompleted(currentWeek, participant.id)) {
                await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                    weeksInRow: participant.weeksInRow + 1,
                });
            }
        }
    }
};

export const weeklyChapterCompleted = async (week: number, participantId: string) => {
    const participant = genDoc<Participant>()(await getDoc(firebaseDoc(firestore, "Participants", participantId)));
    let completed = false;

    if (participant && participant.id) {
        if ((participant.timeline as any)[`week${week < 10 ? `0${week}` : week}`]) {
            let completedChapters: any[] = [];

            Object.values(participant.completedChapters).forEach(c => {
                completedChapters = [...completedChapters, ...c];
            });

            if (week === 1) {
                completed =
                    completedChapters.filter(c => (participant.timeline as any)[`week${week < 10 ? `0${week}` : week}`].includes(c)).length >= 1;
            } else if (week === 2) {
                completed =
                    completedChapters.filter(c => (participant.timeline as any)[`week${week < 10 ? `0${week}` : week}`].includes(c)).length >= 3;
            } else if (week === 3) {
                completed =
                    completedChapters.filter(c => (participant.timeline as any)[`week${week < 10 ? `0${week}` : week}`].includes(c)).length >= 5;
            } else {
                completed =
                    completedChapters.filter(c => (participant.timeline as any)[`week${week < 10 ? `0${week}` : week}`].includes(c)).length >= 4;
            }
        }
    }
    return completed;
};

export const isChapterCompleted = (id: string, currentParticipant: Participant | null) => {
    let isCompleted = false;
    if (currentParticipant) {
        Object.values(currentParticipant.completedChapters).forEach(c => {
            if (c.length > 0 && c.includes(id)) isCompleted = true;
        });
    }

    return isCompleted;
};

export const getChapterCompletionDate = (chapterId: string, participant: Participant | null) => {
    let date: Date | null = null;

    if (participant && participant.uses && participant.uses.length) {
        participant.uses.forEach(u => {
            if (chapterId && u.chaptersCompleted && u.chaptersCompleted.includes(chapterId)) {
                date = u.end;
            }
        });
    }

    return date;
};

export const isChapterFavorited = (chapterId: string, participant: Participant | null) => {
    let favorited = false;

    if (participant && participant.favourites && participant.favourites.length) {
        favorited = participant.favourites.includes(chapterId);
    }

    return favorited;
};

export const getChapterVisitedCount = (chapterId: string, participant: Participant | null) => {
    let count = 0;

    if (participant && participant.uses && participant.uses.length) {
        participant.uses.forEach(u => {
            if (u.chaptersVisited && u.chaptersVisited.includes(chapterId)) {
                count++;
            }
        });
    }

    return count;
};

export const areAllChaptersCompleted = async (participantId: string) => {
    const participantPayload = await getDoc(doc(firestore, "Participants", participantId));
    const participant = genDoc<Participant>()(participantPayload);

    if (!participant) throw new Error("Invalid participant id");

    const payload = await getDocs(query(collection(firestore, "Chapters")));
    const chapters = payload.docs.map(genDoc<Chapter>());

    const sleepChapters = chapters.filter(x => x.topic === "Sleep");
    const vascularChapters = chapters.filter(x => x.topic === "Vascular Health");
    const visionHearingChapters = chapters.filter(x => x.topic === "Vision Hearing");
    const brainOverviewChapters = chapters.filter(x => x.topic === "Brain Overview");
    const cognitiveChapters = chapters.filter(x => x.topic === "Cognitive");
    const nutritionChapters = chapters.filter(x => x.topic === "Nutrition");
    const physicalChapters = chapters.filter(x => x.topic === "Physical");
    const socialChapters = chapters.filter(x => x.topic === "Social Psychological");

    // console.log("-------------- Completed -----------------");
    // console.log("sleep", `${participant.completedChapters["Sleep"].length}/${sleepChapters.length}`);
    // console.log("vascular", `${participant.completedChapters["Vascular Health"].length}/${vascularChapters.length}`);
    // console.log("visionHearing", `${participant.completedChapters["Vision Hearing"].length}/${visionHearingChapters.length}`);
    // console.log("brainOverview", `${participant.completedChapters["Brain Overview"].length}/${brainOverviewChapters.length}`);
    // console.log("cognitive", `${participant.completedChapters["Cognitive"].length}/${cognitiveChapters.length}`);
    // console.log("nutrition", `${participant.completedChapters["Nutrition"].length}/${nutritionChapters.length}`);
    // console.log("physical", `${participant.completedChapters["Physical"].length}/${physicalChapters.length}`);
    // console.log("social", `${participant.completedChapters["Social Psychological"].length}/${socialChapters.length}`);

    // console.log("-------------- Stages -----------------");
    // console.log("stage1", participant.stages.stage1);
    // console.log("stage2", participant.stages.stage2);
    // console.log("stage3", participant.stages.stage3);
    // console.log("stage4", participant.stages.stage4);
    // console.log("stage5", participant.stages.stage5);

    // console.log(
    //     "allChapterCompleted",
    //     participant.completedChapters["Brain Overview"].length === brainOverviewChapters.length &&
    //         JSON.stringify(participant.completedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Cognitive"].length === cognitiveChapters.length &&
    //         JSON.stringify(participant.completedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Nutrition"].length === nutritionChapters.length &&
    //         JSON.stringify(participant.completedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Physical"].length === physicalChapters.length &&
    //         JSON.stringify(participant.completedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Sleep"].length === sleepChapters.length &&
    //         JSON.stringify(participant.completedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Social Psychological"].length === socialChapters.length &&
    //         JSON.stringify(participant.completedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Vascular Health"].length === vascularChapters.length &&
    //         JSON.stringify(participant.completedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
    //         participant.completedChapters["Vision Hearing"].length === visionHearingChapters.length &&
    //         JSON.stringify(participant.completedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    // );

    return (
        participant.completedChapters["Brain Overview"].length === brainOverviewChapters.length &&
        JSON.stringify(participant.completedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Cognitive"].length === cognitiveChapters.length &&
        JSON.stringify(participant.completedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Nutrition"].length === nutritionChapters.length &&
        JSON.stringify(participant.completedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Physical"].length === physicalChapters.length &&
        JSON.stringify(participant.completedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Sleep"].length === sleepChapters.length &&
        JSON.stringify(participant.completedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Social Psychological"].length === socialChapters.length &&
        JSON.stringify(participant.completedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Vascular Health"].length === vascularChapters.length &&
        JSON.stringify(participant.completedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
        participant.completedChapters["Vision Hearing"].length === visionHearingChapters.length &&
        JSON.stringify(participant.completedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    );
};

export const areAllChaptersUnlocked = async (participantId: string) => {
    const participantPayload = await getDoc(doc(firestore, "Participants", participantId));
    const participant = genDoc<Participant>()(participantPayload);

    if (!participant) throw new Error("Invalid participant id");

    const payload = await getDocs(query(collection(firestore, "Chapters")));
    const chapters = payload.docs.map(genDoc<Chapter>());

    const sleepChapters = chapters.filter(x => x.topic === "Sleep");
    const vascularChapters = chapters.filter(x => x.topic === "Vascular Health");
    const visionHearingChapters = chapters.filter(x => x.topic === "Vision Hearing");
    const brainOverviewChapters = chapters.filter(x => x.topic === "Brain Overview");
    const cognitiveChapters = chapters.filter(x => x.topic === "Cognitive");
    const nutritionChapters = chapters.filter(x => x.topic === "Nutrition");
    const physicalChapters = chapters.filter(x => x.topic === "Physical");
    const socialChapters = chapters.filter(x => x.topic === "Social Psychological");

    // console.log("-------------- Unlocked -----------------");
    // console.log("sleep", `${participant.unlockedChapters["Sleep"].length}/${sleepChapters.length}`);
    // console.log("vascular", `${participant.unlockedChapters["Vascular Health"].length}/${vascularChapters.length}`);
    // console.log("visionHearing", `${participant.unlockedChapters["Vision Hearing"].length}/${visionHearingChapters.length}`);
    // console.log("brainOverview", `${participant.unlockedChapters["Brain Overview"].length}/${brainOverviewChapters.length}`);
    // console.log("cognitive", `${participant.unlockedChapters["Cognitive"].length}/${cognitiveChapters.length}`);
    // console.log("nutrition", `${participant.unlockedChapters["Nutrition"].length}/${nutritionChapters.length}`);
    // console.log("physical", `${participant.unlockedChapters["Physical"].length}/${physicalChapters.length}`);
    // console.log("social", `${participant.unlockedChapters["Social Psychological"].length}/${socialChapters.length}`);

    // console.log("-------------- Stages -----------------");
    // console.log("stage1", participant.stages.stage1);
    // console.log("stage2", participant.stages.stage2);
    // console.log("stage3", participant.stages.stage3);
    // console.log("stage4", participant.stages.stage4);
    // console.log("stage5", participant.stages.stage5);

    // console.log(
    //     "allChaptersUnlocked",
    //     participant.unlockedChapters["Brain Overview"].length === brainOverviewChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Cognitive"].length === cognitiveChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Nutrition"].length === nutritionChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Physical"].length === physicalChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Sleep"].length === sleepChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Social Psychological"].length === socialChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Vascular Health"].length === vascularChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
    //         participant.unlockedChapters["Vision Hearing"].length === visionHearingChapters.length &&
    //         JSON.stringify(participant.unlockedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    // );

    return (
        participant.unlockedChapters["Brain Overview"].length === brainOverviewChapters.length &&
        JSON.stringify(participant.unlockedChapters["Brain Overview"].sort()) === JSON.stringify(brainOverviewChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Cognitive"].length === cognitiveChapters.length &&
        JSON.stringify(participant.unlockedChapters["Cognitive"].sort()) === JSON.stringify(cognitiveChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Nutrition"].length === nutritionChapters.length &&
        JSON.stringify(participant.unlockedChapters["Nutrition"].sort()) === JSON.stringify(nutritionChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Physical"].length === physicalChapters.length &&
        JSON.stringify(participant.unlockedChapters["Physical"].sort()) === JSON.stringify(physicalChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Sleep"].length === sleepChapters.length &&
        JSON.stringify(participant.unlockedChapters["Sleep"].sort()) === JSON.stringify(sleepChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Social Psychological"].length === socialChapters.length &&
        JSON.stringify(participant.unlockedChapters["Social Psychological"].sort()) === JSON.stringify(socialChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Vascular Health"].length === vascularChapters.length &&
        JSON.stringify(participant.unlockedChapters["Vascular Health"].sort()) === JSON.stringify(vascularChapters.map(x => x.id).sort()) &&
        participant.unlockedChapters["Vision Hearing"].length === visionHearingChapters.length &&
        JSON.stringify(participant.unlockedChapters["Vision Hearing"].sort()) === JSON.stringify(visionHearingChapters.map(x => x.id).sort())
    );
};
