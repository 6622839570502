// Forms
import { selfEfficacy, dementiaLiteracy, attitudesTowardDementia } from "../components/pages/portal/forms/attitudeAwareness/attitudeAwarenessForms";
import { usability, acceptability } from "../components/pages/portal/forms/usabilityAcceptability/usabilityAcceptabilityForms";
import { cognitiveEngagement, yourNutrition, visionHearing } from "../components/pages/portal/forms/lifestyle/lifestyleForms";

// Interfaces
import { Participant } from "../interfaces/Participant";

// Utils
import { calculateAge } from "./date";

export const selfEfficacyAllowedQuestionIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const dementiaLiteracyAllowedQuestionIndexes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
];
export const attitudesTowardDementiaAllowedQuestionIndexes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
];
export const cognitiveEngagementAllowedQuestionIndexes = [0, 1, 2, 3, 4, 5];
export const yourNutritionAllowedQuestionIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const visionHearingAllowedQuestionIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const usabilityAllowedQuestionIndexes = [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11];
export const acceptabilityAllowedQuestionIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
export const physicalAllowedQuestionIndexes = [3, 4, 5, 7, 8, 9, 11, 12, 13, 15];
export const sleepAllowedQuestionIndexes = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const socialPsychologicalAllowedQuestionIndexes = [1, 2, 3, 4, 5, 6, 7, 8];
export const vascularHealthAllowedQuestionIndexes = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

export const getAAQuestionScore = (topic: string, questionNumber: number, data: any) => {
    let form: any = {};
    const scoreObj: any = {};
    let allowedQuestions: number[] = [];

    if (topic === "Self Efficacy") {
        form = selfEfficacy;
        allowedQuestions = selfEfficacyAllowedQuestionIndexes;
    }
    if (topic === "Dementia Literacy") {
        form = dementiaLiteracy;
        allowedQuestions = dementiaLiteracyAllowedQuestionIndexes;
    }
    if (topic === "Attitudes towards dementia") {
        form = attitudesTowardDementia;
        allowedQuestions = attitudesTowardDementiaAllowedQuestionIndexes;
    }
    if (topic === "Cognitive") {
        form = cognitiveEngagement;
        allowedQuestions = cognitiveEngagementAllowedQuestionIndexes;
    }
    if (topic === "Nutrition") {
        form = yourNutrition;
        allowedQuestions = yourNutritionAllowedQuestionIndexes;
    }
    if (topic === "Vision Hearing") {
        form = visionHearing;
        allowedQuestions = visionHearingAllowedQuestionIndexes;
    }

    Object.entries(data)
        .filter(e => allowedQuestions.includes(parseInt(e[0])))
        .forEach((e: any) => {
            if (allowedQuestions.includes(questionNumber) && parseInt(e[0]) === questionNumber) {
                if (form.questions[e[0]]) {
                    if (form.questions[e[0]].correctAnswer) {
                        if (e[1] === form.questions[e[0]].correctAnswer) {
                            scoreObj[e[0]] = 1;
                        } else scoreObj[e[0]] = 0;
                    } else {
                        if (form.questions[e[0]].answers.length) {
                            form.questions[e[0]].answers.forEach((a: any) => {
                                if (e[1] === a.labelEN && typeof a.pointValue === "number") {
                                    scoreObj[e[0]] = a.pointValue;
                                }
                            });
                        } else {
                            form.answers.forEach((a: any) => {
                                if (e[1] === a.labelEN && typeof a.pointValue === "number") {
                                    scoreObj[e[0]] = a.pointValue;
                                }
                            });
                        }
                    }
                }
            }
        });

    return scoreObj[questionNumber];
};

export const getUAQuestionScore = (topic: string, questionNumber: number, data: any) => {
    let form: any = {};
    const scoreObj: any = {};
    let allowedQuestions: number[] = [];

    if (topic === "Usability") {
        form = usability;
        allowedQuestions = usabilityAllowedQuestionIndexes;
    }
    if (topic === "Acceptability") {
        form = acceptability;
        allowedQuestions = acceptabilityAllowedQuestionIndexes;
    }

    Object.entries(data).forEach((e: any) => {
        if (allowedQuestions.includes(parseInt(e[0]))) {
            if (!e[0].includes("comment")) {
                if (typeof e[1] === "string" && typeof parseInt(e[1]) === "number") {
                    if (form.questions[e[0]].correctAnswer) {
                        if (e[1] === form.questions[e[0]].correctAnswer) {
                            scoreObj[e[0]] = 1;
                        } else scoreObj[e[0]] = 0;
                    } else {
                        if (form.questions[e[0]].answers.length) {
                            form.questions[e[0]].answers.forEach((a: any) => {
                                if (e[1] === a.labelEN && typeof a.pointValue === "number") {
                                    scoreObj[e[0]] = a.pointValue;
                                }
                            });
                        } else {
                            form.answers.forEach((a: any) => {
                                if (e[1] === a.labelEN && typeof a.pointValue === "number") {
                                    scoreObj[e[0]] = a.pointValue;
                                }
                            });
                        }
                    }
                }
            }
        }
    });

    return scoreObj[questionNumber];
};

export const getSleepQuestionScore = (questionNumber: number, data: any) => {
    const scoreObj: any = {};

    if ("question2" in data) {
        if (data.question2 === "Very satisfied" || data.question2 === "Satisfied") {
            scoreObj[2] = 2;
        } else if (data.question2 === "Neutral") {
            scoreObj[2] = 1;
        } else scoreObj[2] = 0;
    }

    if ("question3" in data) {
        if (parseInt(data.question3) >= 6) {
            scoreObj[3] = 2;
        } else scoreObj[3] = 0;
    }

    if ("question4" in data) {
        if (
            data.question4 === "Never" ||
            data.question4 === "<1 per week" ||
            data.question4 === "<1 / week" ||
            data.question4 === "<1 per week" ||
            data.question4 === "<1/week"
        ) {
            scoreObj[4] = 2;
        } else if (data.question4 === "Once or twice per week") {
            scoreObj[4] = 1;
        } else scoreObj[4] = 0;
    }

    if ("question5" in data) {
        if (data.question5 === "Not at all" || data.question5 === "A little") {
            scoreObj[5] = 2;
        } else if (data.question5 === "Somewhat") {
            scoreObj[5] = 1;
        } else scoreObj[5] = 0;
    } else scoreObj[5] = 2;

    if ("question6" in data) {
        if (
            data.question6 === "Never" ||
            data.question6 === "<1 per week" ||
            data.question6 === "<1 / week" ||
            data.question6 === "<1 per week" ||
            data.question6 === "<1/week"
        ) {
            scoreObj[6] = 2;
        } else if (data.question6 === "Once or twice/week" || data.question6 === "Once or twice per week") {
            scoreObj[6] = 1;
        } else scoreObj[6] = 0;
    }

    if ("question7" in data) {
        if (data.question7 === "Not at all" || data.question7 === "A little" || data.question7 === "Never") {
            scoreObj[7] = 2;
        } else if (data.question7 === "Somewhat") {
            scoreObj[7] = 1;
        } else scoreObj[7] = 0;
    } else scoreObj[7] = 2;

    if ("question8" in data) {
        if (
            data.question8 === "Never" ||
            data.question8 === "<1 per week" ||
            data.question8 === "<1 / week" ||
            data.question8 === "<1 per week" ||
            data.question8 === "<1/week"
        ) {
            scoreObj[8] = 2;
        } else if (data.question8 === "Once or twice per week" || data.question8 === "Once or twice/week") {
            scoreObj[8] = 1;
        } else scoreObj[8] = 0;
    }

    if ("question9" in data) {
        if (data.question9 === "Not at all" || data.question9 === "A little") {
            scoreObj[9] = 2;
        } else if (data.question9 === "Somewhat") {
            scoreObj[9] = 1;
        } else scoreObj[9] = 0;
    } else scoreObj[9] = 2;

    if ("question10" in data) {
        if (data.question10 === "Rested" || data.question10 === "Well rested" || data.question10 === "Very well rested") {
            scoreObj[10] = 2;
        } else if (data.question10 === "Slightly rested") {
            scoreObj[10] = 1;
        } else scoreObj[10] = 0;
    }

    if ("question11" in data) {
        if (data.question11 === "30 minutes or less") {
            scoreObj[11] = 2;
        } else if (data.question11 === "More than 30 minutes") {
            scoreObj[11] = 1;
        } else scoreObj[11] = 0;
    }

    return scoreObj[questionNumber];
};

export const getPhysicalQuestionScore = (questionNumber: number, data: any, statCreationDate: Date) => {
    const scoreObj: any = {};

    // Before 05/11/2024
    if (statCreationDate.getTime() < new Date(2024, 10, 5).getTime()) {
        if ("question4" in data && data.question4 > 0 && data.question4 <= 7) {
            scoreObj[4] = 1;
        } else scoreObj[4] = 0;

        if ("question5" in data && "question4" in data && parseFloat(data.question5) >= 10) {
            const value = parseFloat(data.question4) * parseFloat(data.question5);
            if (value >= 120) scoreObj[5] = 2;
            if (value >= 100 && value <= 119) scoreObj[5] = 1.75;
            if (value >= 80 && value <= 99) scoreObj[5] = 1.5;
            if (value >= 60 && value <= 79) scoreObj[5] = 1.25;
            if (value >= 40 && value <= 59) scoreObj[5] = 1;
            if (value >= 20 && value <= 39) scoreObj[5] = 0.5;
            if (value >= 10 && value <= 19) scoreObj[5] = 0.25;
        } else scoreObj[5] = 0;

        if ("question8" in data && data.question8 > 0 && data.question8 <= 7) {
            scoreObj[8] = 1;
        } else scoreObj[8] = 0;

        if ("question9" in data && "question8" in data && parseFloat(data.question9) >= 10) {
            const value = parseFloat(data.question8) * parseFloat(data.question9);

            if (value >= 300) scoreObj[9] = 2;
            if (value >= 257 && value <= 299) scoreObj[9] = 1.75;
            if (value >= 214 && value <= 256) scoreObj[9] = 1.5;
            if (value >= 172 && value <= 213) scoreObj[9] = 1.25;
            if (value >= 129 && value <= 171) scoreObj[9] = 1;
            if (value >= 86 && value <= 128) scoreObj[9] = 0.75;
            if (value >= 43 && value <= 85) scoreObj[9] = 0.5;
            if (value >= 10 && value <= 42) scoreObj[9] = 0.25;
        } else scoreObj[9] = 0;

        if ("question12" in data && data.question12 > 0 && data.question12 <= 7) {
            scoreObj[12] = 1;
        } else scoreObj[12] = 0;

        if ("question13" in data && "question12" in data && parseFloat(data.question13) >= 10) {
            const value = parseFloat(data.question12) * parseFloat(data.question13);

            if (value >= 360) scoreObj[13] = 2;
            if (value >= 310 && value <= 359) scoreObj[13] = 1.75;
            if (value >= 258 && value <= 309) scoreObj[13] = 1.5;
            if (value >= 206 && value <= 257) scoreObj[13] = 1.25;
            if (value >= 154 && value <= 205) scoreObj[13] = 1;
            if (value >= 102 && value <= 153) scoreObj[13] = 0.75;
            if (value >= 50 && value <= 101) scoreObj[13] = 0.5;
            if (value >= 10 && value <= 49) scoreObj[13] = 0.25;
        } else scoreObj[13] = 0;
    }
    // After 05/11/2024
    else {
        if ("question1" in data && data.question1 > 0 && data.question1 <= 7) {
            scoreObj[1] = 1;
        } else scoreObj[1] = 0;

        if ("question2" in data && "question1" in data && parseFloat(data.question2) >= 10) {
            const value = parseFloat(data.question1) * parseFloat(data.question2);
            if (value >= 120) scoreObj[2] = 2;
            if (value >= 100 && value <= 119) scoreObj[2] = 1.75;
            if (value >= 80 && value <= 99) scoreObj[2] = 1.5;
            if (value >= 60 && value <= 79) scoreObj[2] = 1.25;
            if (value >= 40 && value <= 59) scoreObj[2] = 1;
            if (value >= 20 && value <= 39) scoreObj[2] = 0.5;
            if (value >= 10 && value <= 19) scoreObj[2] = 0.25;
        } else scoreObj[2] = 0;

        if ("question3" in data && data.question3 > 0 && data.question3 <= 7) {
            scoreObj[3] = 1;
        } else scoreObj[3] = 0;

        if ("question4" in data && "question3" in data && parseFloat(data.question4) >= 10) {
            const value = parseFloat(data.question3) * parseFloat(data.question4);

            if (value >= 300) scoreObj[4] = 2;
            if (value >= 257 && value <= 299) scoreObj[4] = 1.75;
            if (value >= 214 && value <= 256) scoreObj[4] = 1.5;
            if (value >= 172 && value <= 213) scoreObj[4] = 1.25;
            if (value >= 129 && value <= 171) scoreObj[4] = 1;
            if (value >= 86 && value <= 128) scoreObj[4] = 0.75;
            if (value >= 43 && value <= 85) scoreObj[4] = 0.5;
            if (value >= 10 && value <= 42) scoreObj[4] = 0.25;
        } else scoreObj[4] = 0;

        if ("question5" in data && data.question5 > 0 && data.question5 <= 7) {
            scoreObj[5] = 1;
        } else scoreObj[5] = 0;

        if ("question6" in data && "question5" in data && parseFloat(data.question6) >= 10) {
            const value = parseFloat(data.question5) * parseFloat(data.question6);

            if (value >= 360) scoreObj[6] = 2;
            if (value >= 310 && value <= 359) scoreObj[6] = 1.75;
            if (value >= 258 && value <= 309) scoreObj[6] = 1.5;
            if (value >= 206 && value <= 257) scoreObj[6] = 1.25;
            if (value >= 154 && value <= 205) scoreObj[6] = 1;
            if (value >= 102 && value <= 153) scoreObj[6] = 0.75;
            if (value >= 50 && value <= 101) scoreObj[6] = 0.5;
            if (value >= 10 && value <= 49) scoreObj[6] = 0.25;
        } else scoreObj[6] = 0;
    }
    return scoreObj[questionNumber] ?? "n/a";
};

export const getSPQuestionScore = (questionNumber: number, data: any, participant: Participant) => {
    const scoreObj: any = {};

    if (participant) {
        if ("question1" in data && parseInt(data.question1)) {
            scoreObj[1] = parseInt(data.question1);
        } else scoreObj[1] = 0;

        if ("question2" in data && parseInt(data.question2)) {
            scoreObj[2] = parseInt(data.question2);
        } else scoreObj[2] = 0;

        if ("question3" in data) {
            if (data.question3 === "Older than my current age") {
                scoreObj[3] = 1;
            }
            if (data.question3 === "The same as my current age") {
                scoreObj[3] = 0;
            }
            if (data.question3 === "Younger than my current age") {
                scoreObj[3] = -1;
            }
        } else scoreObj[3] = 0;

        if ("question4" in data && parseInt(data.question4)) {
            scoreObj[4] = parseInt(data.question4);
        } else scoreObj[4] = 0;

        if ("question5" in data && parseInt(data.question5)) {
            scoreObj[5] = parseInt(data.question5);
        } else scoreObj[5] = 0;

        if ("question6" in data && parseInt(data.question6)) {
            scoreObj[6] = parseInt(data.question6);
        } else scoreObj[6] = 0;

        if ("question7" in data && parseInt(data.question7)) {
            scoreObj[7] = parseInt(data.question7);
        } else scoreObj[7] = 0;

        if ("question8" in data && parseInt(data.question8)) {
            scoreObj[8] = parseInt(data.question8);
        } else scoreObj[8] = 0;
    }

    return scoreObj[questionNumber];
};

export const getVHQuestionScore = (questionNumber: number, data: any) => {
    const scoreObj: any = {};

    if ("question2" in data) {
        if (data.question2 === "No") {
            {
                scoreObj[2] = 2;
            }
        } else {
            scoreObj[2] = 0;
            if (data.question3 !== "No" && "question4" in data) {
                if (data.question4 === "Yes") {
                    scoreObj[4] = 1;
                }
                if (data.question4 === "No") {
                    scoreObj[4] = -1;
                }
            }
        }
    }

    if ("question5" in data) {
        if (data.question5 === "No") {
            scoreObj[5] = 2;
        } else {
            scoreObj[5] = 0;
            if (data.question6 !== "No" && "question7" in data) {
                if (data.question7 === "Yes") {
                    scoreObj[7] = 1;
                }
                if (data.question7 === "No") {
                    scoreObj[7] = -1;
                }
            }
        }
    }

    if ("question8" in data) {
        if (data.question8 === "No") {
            scoreObj[8] = 2;
        } else {
            scoreObj[8] = 0;
            if (data.question9 !== "No" && "question10" in data) {
                if (data.question10 === "Yes") {
                    scoreObj[10] = 1;
                }
                if (data.question10 === "No") {
                    scoreObj[10] = -1;
                }
            }
        }
    }

    if ("question11" in data) {
        if (data.question11 === "No") {
            scoreObj[11] = 2;
        } else {
            scoreObj[11] = 0;
            if ("question13" in data) {
                if (data.question13 === "Yes") {
                    scoreObj[13] = 1;
                }
                if (data.question13 === "No") {
                    scoreObj[13] = -1;
                }
            }
        }
    }

    return scoreObj[questionNumber] ?? "n/a";
};

export const getScoreByQuestion = (
    topic: string,
    questionNumber: number,
    results: any,
    participant?: Participant | null,
    statCreationDate = new Date()
) => {
    let score = undefined;
    // Attitude awareness generator
    if (
        topic === "Self Efficacy" ||
        topic === "Dementia Literacy" ||
        topic === "Attitudes towards dementia" ||
        topic === "Cognitive" ||
        topic === "Nutrition" ||
        topic === "Vision Hearing"
    ) {
        score = getAAQuestionScore(topic, questionNumber, results);
    }
    // UsabilityAcceptability generator
    if (topic === "Usability" || topic === "Acceptability") {
        score = getUAQuestionScore(topic, questionNumber, results);
    }

    // Individual generators
    if (topic === "Physical") {
        score = getPhysicalQuestionScore(questionNumber, results, statCreationDate);
    }
    if (topic === "Sleep") {
        score = getSleepQuestionScore(questionNumber, results);
    }
    if (topic === "Social Psychological") {
        if (!participant) throw new Error("No participant");
        score = getSPQuestionScore(questionNumber, results, participant);
    }
    if (topic === "Vascular Health") {
        score = getVHQuestionScore(questionNumber, results);
    }

    return score;
};
