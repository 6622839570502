import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { collection as firebaseCollection, getDocs, query, where } from "firebase/firestore";

// Components
import Title from "../../general/Title";
import GaugeCard from "../../general/GaugeCard";

// Utils
import settings from "../../../settings.json";
import { getCurrentStage } from "../../../utils/general";
import { getGaugeInfoAndDangerLevel, getTitleFromTopic } from "../../../utils/topic";
import { genDoc } from "../../../firebase/requests";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { Stat, StatTopic } from "../../../interfaces/Stat";

// MUI
import Grid from "@mui/material/Grid";

// Assets
import { FaThumbsUp, FaExclamationTriangle } from "react-icons/fa";

const Metrics: React.FC = () => {
    const { t } = useTranslation();
    const { currentParticipant, currentWeek } = useFetchUser();

    // States
    const [stats, setStats] = useState<Stat[]>([]);
    const [riskTopics, setRiskTopics] = useState<string[]>([]);
    const [risklessTopics, setRisklessTopics] = useState<string[]>([]);

    const fetchStats = async () => {
        if (currentParticipant) {
            const stage = getCurrentStage(currentWeek);
            const data = await getDocs(
                query(firebaseCollection(firestore, "Stats"), where("participantId", "==", currentParticipant.id), where("stage", "==", stage))
            );

            setStats(data.docs.map(genDoc<Stat>()));
        }
    };

    const fetchRisks = async () => {
        const risks: StatTopic[] = [];
        const noRisks: StatTopic[] = [];

        stats
            .filter(
                s =>
                    s.topic !== "Acceptability" &&
                    s.topic !== "Dementia Literacy" &&
                    s.topic !== "Attitudes towards dementia" &&
                    s.topic !== "Self Efficacy" &&
                    s.topic !== "Usability"
            )
            .map(s => {
                const value = getGaugeInfoAndDangerLevel(s.topic, s.score).gaugeValue;

                if (value) {
                    if (value <= 60) noRisks.push(s.topic);
                    if (value > 60 && value <= 120) risks.push(s.topic);
                    if (value > 120) risks.push(s.topic);
                }
            });
        setRisklessTopics(noRisks);
        setRiskTopics(risks);
    };

    useEffect(() => {
        if (currentWeek !== 0) {
            fetchStats();
            fetchRisks();
        }
    }, [currentWeek]);

    useEffect(() => {
        if (stats && stats.length > 0) fetchRisks();
    }, [stats]);

    return (
        <>
            <Title>{t("brainHealthProfileTitle")}</Title>
            <div className="profile__summary">
                <h6>{t("profileDesc1")}</h6>
                <h6>{t("profileDesc2")}</h6>

                {risklessTopics.map((t, i) => {
                    return (
                        <h6 key={i}>
                            <span className="profile__thumbs">
                                <FaThumbsUp />
                            </span>{" "}
                            {getTitleFromTopic(t as StatTopic)}
                        </h6>
                    );
                })}

                {riskTopics.map((t, i) => (
                    <h6 key={i}>
                        <span className="profile__careful">
                            <FaExclamationTriangle />
                        </span>{" "}
                        {getTitleFromTopic(t as StatTopic)}
                    </h6>
                ))}
            </div>

            <Grid container spacing={4} className="profile__gaugeContainer">
                {stats.map((s, i) => {
                    if (settings.app.topics.includes(s.topic))
                        return (
                            <Grid key={i} item xs={6}>
                                <GaugeCard topic={s.topic} score={s.score} />
                            </Grid>
                        );
                })}
            </Grid>
        </>
    );
};

export default Metrics;
