import React from "react";

// Custom components
import Help from "./portal/Help";

const AccountHelp: React.FC = () => {
    return (
        <div>
            <Help isLoggedOut />
        </div>
    );
};

export default AccountHelp;
