import React from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb from "../../../../hooks/useDb";

// Settings
import settings from "../../../../settings.json";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Interfaces
import { Chapter } from "../../../../interfaces/Chapter";
import { FormHelperText } from "@mui/material";

const COLLECTION = "Chapters";

// yup validation
const requiredMessage = "Required field";

const chapterSchema = yup.object({
    titleFR: yup.string().required(requiredMessage),
    titleEN: yup.string().required(requiredMessage),
    linkFR: yup.string().required(requiredMessage),
    linkEN: yup.string().required(requiredMessage),
    chapter: yup.number().required(requiredMessage),
    topic: yup.string().required(requiredMessage),
});

interface Props {
    open: boolean;
    setOpen: any;
}

const ChapterDialog: React.FC<Props> = ({ open, setOpen }) => {
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const { createDoc } = useDb<Chapter>(COLLECTION, currentAdmin);

    const defaultValues = {
        titleFR: "",
        titleEN: "",
        linkFR: "",
        linkEN: "",
        chapter: 0,
        topic: "",
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({ resolver: yupResolver(chapterSchema), defaultValues });

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            const chapter: Chapter = { ...data };

            await createDoc(chapter);

            enqueueSnackbar("Chapter created", { variant: "success" });

            setOpen(false);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => !loading && setOpen(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="scroll-dialog-title">Add chapter</DialogTitle>
                <DialogContent>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.titleFR?.message}
                                error={!!errors.titleFR?.message}
                                label="French title"
                                fullWidth
                                {...field}
                            />
                        )}
                        name="titleFR"
                        control={control}
                    />

                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.titleEN?.message}
                                error={!!errors.titleEN?.message}
                                label="English title"
                                fullWidth
                                {...field}
                            />
                        )}
                        name="titleEN"
                        control={control}
                    />

                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.linkFR?.message}
                                error={!!errors.linkFR?.message}
                                label="French link"
                                fullWidth
                                {...field}
                            />
                        )}
                        name="linkFR"
                        control={control}
                    />

                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.linkEN?.message}
                                error={!!errors.linkEN?.message}
                                label="English link"
                                fullWidth
                                {...field}
                            />
                        )}
                        name="linkEN"
                        control={control}
                    />

                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.chapter?.message}
                                error={!!errors.chapter?.message}
                                label="Chapter number"
                                fullWidth
                                type="number"
                                {...field}
                            />
                        )}
                        name="chapter"
                        control={control}
                    />

                    <Controller
                        name="topic"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth variant="standard" error={!!errors.topic?.message}>
                                <InputLabel id="roles-id">Topic</InputLabel>
                                <Select
                                    labelId="roles-id"
                                    {...field}
                                    inputProps={{ readOnly: currentAdmin && currentAdmin.roles.includes(settings.app.highestRole) ? false : true }}
                                >
                                    {settings.app.topics.map(r => (
                                        <MenuItem key={r} value={r}>
                                            {r}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!errors.topic?.message && <FormHelperText>{errors.topic?.message}</FormHelperText>}
                            </FormControl>
                        )}
                    />
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default ChapterDialog;
