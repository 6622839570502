import { useSnackbar } from "notistack";

// Material UI Core
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

// Interfaces
import { Chapter } from "../../../../interfaces/Chapter";

// Firebase
import * as requests from "../../../../firebase/requests";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Icons
import { MdAdd } from "react-icons/md";

// Settings
import settings from "../../../../settings.json";

const COLLECTION = "Chapters";

interface Props {
    open: boolean;
    setOpen: any;
}

const ChaptersTableRows = (props: Props) => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = (c: Chapter) => {
        try {
            if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                if (c.id)
                    requests.update<Chapter>(COLLECTION, {
                        ...c,
                        isDeleted: !c.isDeleted,
                    });
            } else {
                enqueueSnackbar("Missing required role", { variant: "error" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return [
        {
            header: "Title",
            body: (c: Chapter) => c.titleEN,
        },
        {
            header: "Link",
            body: (c: Chapter) => c.linkEN,
        },
        {
            header: "Chapter number",
            body: (c: Chapter) => c.chapter,
        },
        {
            header: "Topic",
            body: (c: Chapter) => c.topic,
        },
        {
            header: (
                <Button variant="contained" color="primary" onClick={() => props.setOpen(true)}>
                    <MdAdd />
                    Add
                </Button>
            ),
            body: (c: Chapter) => (
                <Switch
                    checked={!c.isDeleted}
                    onChange={() => handleDelete(c)}
                    name="checkedA"
                    inputProps={{
                        "aria-label": "secondary checkbox",
                    }}
                />
            ),
        },
    ];
};

export default ChaptersTableRows;
