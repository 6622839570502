import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { collection, doc as firebaseDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Mui core
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Assets
import plus from "../../../assets/icons/plus.png";
import trophy from "../../../assets/gifs/Trophy.gif";
import { MdKeyboardArrowRight } from "react-icons/md";

// Custom components
import TopicPickerDialog from "./dialogs/TopicPickerDialog";
import TopBarForm from "./forms/TopBarForm";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Settings
import settings from "../../../settings.json";

// Interfaces
import { Stat, StatTopic } from "../../../interfaces/Stat";

// Utils
import { getGaugeInfoAndDangerLevel, getIconFromTopic, getTitleFromTopic, translateTopic } from "../../../utils/topic";
import { getCurrentStage } from "../../../utils/general";
import { isChapterCompleted } from "../../../utils/chapter";

const PrioritizedTopics: React.FC = () => {
    const { t } = useTranslation();
    const topic1PickerDialogRef = useRef(null);
    const topic2PickerDialogRef = useRef(null);
    const topic3PickerDialogRef = useRef(null);
    const hist = useHistory();
    const { currentParticipant, currentWeek, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [stats, setStats] = useState<Stat[]>([]);
    const [topicsInDanger, setTopicsInDanger] = useState<any[]>([]);
    const [topic1, setTopic1] = useState("");
    const [topic2, setTopic2] = useState("");
    const [topic3, setTopic3] = useState("");
    const [topic1Data, setTopic1Data] = useState<string[]>([]);
    const [topic2Data, setTopic2Data] = useState<string[]>([]);
    const [topic3Data, setTopic3Data] = useState<string[]>([]);
    const [topic1Locked, setTopic1Locked] = useState(false);
    const [topic2Locked, setTopic2Locked] = useState(false);
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
    const [boxHeight, setBoxHeight] = useState<number>();

    const handleSize = () => {
        setWindowSize(window.innerWidth);
        setBoxHeight((windowSize - 50 - 32 * 3) / 4 / 2 - 150);
    };

    window.addEventListener("resize", handleSize);

    const fetchStats = async () => {
        if (currentParticipant && currentParticipant.id) {
            const localQuery = query(
                collection(firestore, "Stats"),
                where("isDeleted", "==", false),
                where("participantId", "==", currentParticipant.id),
                where("stage", "==", getCurrentStage(currentWeek))
            );
            const data = await getDocs(localQuery);
            setStats(data.docs.map(genDoc<Stat>()));
        }
    };

    const fetchTopicsInDanger = async () => {
        const dangerTopics: any[] = [];
        const stage = getCurrentStage(currentWeek);
        if (currentParticipant && currentParticipant.id) {
            for (const t of settings.app.topics) {
                const filteredStats = stats.filter(s => s.topic === t && s.stage === stage);
                if (filteredStats && filteredStats.length > 0) {
                    const value = getGaugeInfoAndDangerLevel(t as StatTopic, filteredStats[0].score).gaugeValue;
                    if (value) {
                        if (value > 60) {
                            dangerTopics.push({ topic: filteredStats[0].topic, value });
                        }
                    }
                }
            }

            setTopicsInDanger(dangerTopics);
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            if (!topic1 && !topic2 && !topic3) {
                return enqueueSnackbar(t("selectTopics"), { variant: "error" });
            } else {
                if (!topic1) return enqueueSnackbar(t("selectTopic1"), { variant: "error" });
                if (!topic2) return enqueueSnackbar(t("selectTopic2"), { variant: "error" });
                if (!topic3) return enqueueSnackbar(t("selectTopic3"), { variant: "error" });

                setLoading(true);
                if (currentParticipant && currentParticipant.id) {
                    const prioritizedTopicsHistory: any = {};

                    // prioritizedTopicsHistory
                    if (currentParticipant.prioritizedTopicsHistory) {
                        Object.entries(currentParticipant.prioritizedTopicsHistory).forEach(e => {
                            if (e[0] === getCurrentStage(currentWeek)) {
                                prioritizedTopicsHistory[e[0]] = [topic1, topic2, topic3];
                            } else {
                                prioritizedTopicsHistory[e[0]] = e[1];
                            }
                        });
                    } else {
                        // prioritizedTopicsHistory missing
                        for (let i = 1; i <= 5; i++) {
                            if (`stage${i}` === getCurrentStage(currentWeek)) {
                                prioritizedTopicsHistory[`stage${i}`] = [topic1, topic2, topic3];
                            } else {
                                prioritizedTopicsHistory[`stage${i}`] = [];
                            }
                        }
                    }

                    await updateDoc(firebaseDoc(firestore, "Participants", currentParticipant.id), {
                        prioritizedTopics: [topic1, topic2, topic3],
                        prioritizedTopicsHistory,
                        points: currentParticipant.points + 1,
                    });
                }

                setLoading(false);
                enqueueSnackbar(
                    <span>
                        {t(`1pointCompletedGained`)}
                        <b> 1 point </b>
                        {t(`1pointCompletedPrioritized`)}
                    </span>,
                    {
                        variant: "success",
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom",
                        },
                        content: (key, message) => (
                            <div key={key} className="point__snackbar">
                                <img alt="trophy" src={trophy} />
                                <p>{message}</p>
                            </div>
                        ),
                    }
                );

                if (!isChapterCompleted("h1BYNJTOhheE8tJ1khUk", currentParticipant)) {
                    // Redirect to chapter 6.2
                    hist.push("/chapter/h1BYNJTOhheE8tJ1khUk");
                } else hist.push("/goals");
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchTopic1Data = () => {
        let data = [];

        if (topicsInDanger.length === 1 || topicsInDanger.length === 2) {
            data = [topicsInDanger[0].topic];
            setTopic1(topicsInDanger[0].topic);
            setTopic1Locked(true);
        } else if (topicsInDanger.length === 0) {
            data = settings.app.topics.filter((t: string) => t !== "Brain Overview");
        } else {
            data = topicsInDanger.map(t => t.topic);
        }

        return data;
    };

    const fetchTopic2Data = () => {
        let data = [];

        if (topicsInDanger.length === 2) {
            data = [topicsInDanger[1].topic];
            setTopic2(topicsInDanger[1].topic);
            setTopic2Locked(true);
        } else if (topicsInDanger.length === 0 || topicsInDanger.length === 1) {
            data = settings.app.topics.filter((t: any) => t !== "Brain Overview");
        } else {
            data = topicsInDanger.map(t => t.topic).filter(t => t !== topic1);
        }

        return data;
    };

    const fetchTopic3Data = () => {
        let data = [];

        if (topicsInDanger.length > 0) {
            data = settings.app.topics.filter((t: string) => t !== topic1 && t !== topic2 && t !== "Brain Overview");
        } else {
            data = settings.app.topics.filter((t: string) => t !== "Brain Overview");
        }

        return data;
    };

    useEffect(() => {
        if (currentWeek > 0) {
            fetchStats();
            // fetchTopicsInDanger();
            handleSize();
            setLoading(true);
        }
    }, [currentWeek]);

    useEffect(() => {
        if (stats && stats.length > 0) {
            fetchTopicsInDanger();
        }
    }, [stats]);

    useEffect(() => {
        setTopic1Data(fetchTopic1Data());
        setTopic2Data(fetchTopic2Data());
        setTopic3Data(fetchTopic3Data());
    }, [topicsInDanger]);

    useEffect(() => {
        if (topic1) {
            setTopic2Data(fetchTopic2Data().filter((t: any) => t !== "Brain Overview" && t !== topic1 && t !== topic3));
            setTopic3Data(fetchTopic3Data().filter((t: any) => t !== "Brain Overview" && t !== topic1 && t !== topic2));
        }
    }, [topic1]);

    useEffect(() => {
        if (topic2) {
            setTopic1Data(fetchTopic1Data().filter((t: any) => t !== "Brain Overview" && t !== topic2 && t !== topic3));
            setTopic3Data(fetchTopic3Data().filter((t: any) => t !== "Brain Overview" && t !== topic1 && t !== topic2));
        }
    }, [topic2]);

    useEffect(() => {
        if (topic3) {
            setTopic1Data(fetchTopic1Data().filter((t: any) => t !== "Brain Overview" && t !== topic2 && t !== topic3));
            setTopic2Data(fetchTopic2Data().filter((t: any) => t !== "Brain Overview" && t !== topic1 && t !== topic3));
        }
    }, [topic3]);

    return (
        <div>
            <TopBarForm />
            <div className="prioritizedTopic">
                <h1>{t("prioritizedTopics")}</h1>
                <br />
                <p>{t("selectTopics")}</p>
                {(topicsInDanger.length === 1 || topicsInDanger.length === 2) && (
                    <>
                        <br />
                        <p style={{ fontStyle: "italic" }}>{t("RiskTopics")}</p>
                    </>
                )}

                <Grid container spacing={4} className="prioritizedTopic__container">
                    <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                        {topic1 ? (
                            <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                <img
                                    src={getIconFromTopic(topic1 as StatTopic)}
                                    alt={topic1}
                                    className="prioritizedTopic__img"
                                    onClick={() => {
                                        if (!topic1Locked) {
                                            const current: any = topic1PickerDialogRef.current;
                                            if (current) {
                                                current.open({
                                                    topics: topic1Data,
                                                });
                                            }
                                        }
                                    }}
                                    style={{ cursor: topic1Locked ? "default" : "cursor" }}
                                />
                                <h6 className="prioritizedTopic__topic__title">{getTitleFromTopic(topic1 as StatTopic)}</h6>
                            </div>
                        ) : (
                            <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                <img
                                    src={plus}
                                    alt="plus"
                                    className="prioritizedTopic__img selection"
                                    onClick={() => {
                                        if (!topic1Locked) {
                                            const current: any = topic1PickerDialogRef.current;
                                            if (current) {
                                                current.open({
                                                    topics: topic1Data,
                                                });
                                            }
                                        }
                                    }}
                                    style={{ cursor: topic1Locked ? "default" : "cursor" }}
                                />
                                <h6>{t("selectTopic1")}</h6>
                            </div>
                        )}
                    </Grid>

                    <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                        {topic2 ? (
                            <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                <img
                                    src={getIconFromTopic(topic2 as StatTopic)}
                                    alt={topic2}
                                    className="prioritizedTopic__img"
                                    onClick={() => {
                                        if (!topic2Locked) {
                                            const current: any = topic2PickerDialogRef.current;
                                            if (current) {
                                                current.open({
                                                    topics: topic2Data,
                                                });
                                            }
                                        }
                                    }}
                                    style={{ cursor: topic2Locked ? "default" : "cursor" }}
                                />
                                <h6 className="prioritizedTopic__topic__title">
                                    {localStorage.getItem("language") === "en" ? topic2 : translateTopic(topic2)}
                                </h6>
                            </div>
                        ) : (
                            <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                <img
                                    src={plus}
                                    alt="plus"
                                    className="prioritizedTopic__img selection"
                                    onClick={() => {
                                        if (!topic2Locked) {
                                            const current: any = topic2PickerDialogRef.current;
                                            if (current) {
                                                current.open({
                                                    topics: topic2Data,
                                                });
                                            }
                                        }
                                    }}
                                    style={{ cursor: topic2Locked ? "default" : "cursor" }}
                                />
                                <h6>{t("selectTopic2")}</h6>
                            </div>
                        )}
                    </Grid>

                    <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                        {topic3 ? (
                            <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                <img
                                    src={getIconFromTopic(topic3 as StatTopic)}
                                    alt={topic3}
                                    className="prioritizedTopic__img"
                                    onClick={() => {
                                        const current: any = topic3PickerDialogRef.current;
                                        if (current) {
                                            current.open({
                                                topics: topic3Data,
                                            });
                                        }
                                    }}
                                />
                                <h6 className="prioritizedTopic__topic__title">
                                    {localStorage.getItem("language") === "en" ? topic3 : translateTopic(topic3)}
                                </h6>
                            </div>
                        ) : (
                            <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                <img
                                    src={plus}
                                    alt="plus"
                                    className="prioritizedTopic__img selection"
                                    onClick={() => {
                                        const current: any = topic3PickerDialogRef.current;
                                        if (current) {
                                            current.open({
                                                topics: topic3Data,
                                            });
                                        }
                                    }}
                                />
                                <h6>{t("selectTopic3")}</h6>
                            </div>
                        )}
                    </Grid>

                    <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                        <div className="questionnaires__buttonContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                            <Button className="questionnaires__button" variant="contained" color="primary" onClick={handleSubmit}>
                                {t("confirm")} <MdKeyboardArrowRight />
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <TopicPickerDialog setTopic={setTopic1} desiredTopic="topic1" ref={topic1PickerDialogRef} />
                <TopicPickerDialog setTopic={setTopic2} desiredTopic="topic2" ref={topic2PickerDialogRef} />
                <TopicPickerDialog setTopic={setTopic3} desiredTopic="topic3" ref={topic3PickerDialogRef} />
            </div>
        </div>
    );
};

export default PrioritizedTopics;
