import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import Title from "../../../../general/Title";
import TopBarForm from "../TopBarForm";
import InstructionDialog from "../InstructionDialog";

// Forms
import { usability, acceptability } from "../usabilityAcceptability/usabilityAcceptabilityForms";

// Mui
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Interfaces
import { TextField } from "@mui/material";

// Utils
import { dirtyValues, removeUndefinedInObject } from "../../../../../utils/general";
import { getScoreByQuestion } from "../../../../../utils/score";

const requiredMessage = "Required field";

interface Question {
    questionFR: string;
    questionEN: string;
    answers: Answer[];
    correctAnswer: string;
    commentFR?: string;
    commentEN?: string;
}

interface Answer {
    labelFR: string;
    labelEN: string;
    pointValue?: number;
}

interface Form {
    titleFR: string;
    titleEN: string;
    aboutFR: string;
    aboutEN: string;
    instructionsFR: string;
    instructionsEN: string;
    questions: Question[];
    answers: Answer[];
}

interface Props {
    form: Form;
    // eslint-disable-next-line
    onComplete: (title: string, data: any, points: number, topic: string) => void;
}

const UsabilityAcceptabilityFormGenerator: React.FC<Props> = ({ form, onComplete }) => {
    const { setLoading } = useFetchUser();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const generateSchema = () => {
        const conditions: any = {};
        form.questions.forEach((q, i) => {
            if (!q.commentEN) {
                conditions[i] = yup.string().required(requiredMessage);
                conditions[`comment${i}`] = yup.string().max(1500);
            }
        });
        return yup.object(conditions);
    };

    const generateDefaultValues = () => {
        const output: any = {};
        form.questions.forEach((q, i) => {
            if (!q.commentEN) {
                output[i] = "";
                output[`comment${i}`] = "";
            }
        });
        return output;
    };

    // Forms
    const {
        reset,
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
    } = useForm({ resolver: yupResolver(generateSchema()), defaultValues: generateDefaultValues() });

    const onSubmit = async (data: any) => {
        try {
            let points = 0;
            let topic = "";
            data = removeUndefinedInObject(dirtyValues(dirtyFields, data));
            setLoading(true);

            // Get topic from form
            if (form === usability) topic = "Usability";
            if (form === acceptability) topic = "Acceptability";

            Object.entries(dirtyValues(dirtyFields, data)).forEach(e => {
                const localPoints = getScoreByQuestion(topic, parseInt((e[0] as string).replace("question", "")), dirtyValues(dirtyFields, data));
                if (typeof localPoints !== "undefined" && localPoints !== "n/a") points += localPoints as number;
            });

            enqueueSnackbar(t("questionnaireCompleted", { title: localStorage.getItem("language") === "en" ? form.titleEN : form.titleFR }), {
                variant: "success",
            });
            onComplete(form.titleEN, dirtyValues(dirtyFields, data), points, topic);
            window.scrollTo(0, 0);
            setIsSafeToReset(true);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const id = Object.entries(errors)[0][0];
            const element = document.getElementById(id);

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    useEffect(() => {
        if (isSafeToReset) reset();
    }, [isSafeToReset]);

    return (
        <>
            <TopBarForm />
            <div className="forms__container">
                <Title>{localStorage.getItem("language") === "en" ? form.titleEN : form.titleFR}</Title>

                {form.aboutEN && (
                    <div className="forms__section">
                        <h6 className="forms__subtitle">{localStorage.getItem("language") === "en" ? "About" : "À propos"}</h6>
                        <p style={{ marginBottom: 20 }}>{localStorage.getItem("language") === "en" ? form.aboutEN : form.aboutFR}</p>
                    </div>
                )}

                {form.instructionsEN && (
                    <>
                        <h6>Instructions</h6>
                        <div className="forms__important__container">
                            <h6 className="forms__important">
                                {localStorage.getItem("language") === "en" ? form.instructionsEN : form.instructionsFR}
                            </h6>
                        </div>
                    </>
                )}

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e: any) => {
                        // Disable submit on "enter" key press
                        e.key === "Enter" && e.preventDefault();
                    }}
                    className="forms__content"
                >
                    <Grid item>
                        {form.questions.map((q, i) => {
                            if (q.commentEN) {
                                return (
                                    <div className="forms__important__container">
                                        <h6 className="forms__important">{localStorage.getItem("language") === "en" ? q.commentEN : q.commentFR}</h6>
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ paddingTop: 0 }}>
                                        <Controller
                                            key={q.questionEN && q.questionEN + i}
                                            control={control}
                                            name={i.toString()}
                                            render={({ field }) => (
                                                <FormControl
                                                    className="forms__removeMargin"
                                                    id={i.toString()}
                                                    component="fieldset"
                                                    error={!!errors[i]?.message}
                                                >
                                                    <FormLabel component="legend">
                                                        {localStorage.getItem("language") === "en"
                                                            ? q.questionEN && q.questionEN.trim()
                                                            : q.questionFR && q.questionFR.trim()}
                                                        <IconButton
                                                            color="primary"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedTypes(["radio", "input"]);
                                                                setDialogOpen(true);
                                                            }}
                                                        >
                                                            <GrCircleInformation />
                                                        </IconButton>
                                                    </FormLabel>
                                                    <RadioGroup row {...field}>
                                                        {!q.answers.length
                                                            ? form.answers.map(a => (
                                                                  <FormControlLabel
                                                                      key={a.labelEN + i}
                                                                      value={a.labelEN}
                                                                      control={<Radio />}
                                                                      label={localStorage.getItem("language") === "en" ? a.labelEN : a.labelFR}
                                                                  />
                                                              ))
                                                            : q.answers.map(a => (
                                                                  <FormControlLabel
                                                                      key={a.labelEN + i}
                                                                      value={a.labelEN}
                                                                      control={<Radio />}
                                                                      label={localStorage.getItem("language") === "en" ? a.labelEN : a.labelFR}
                                                                  />
                                                              ))}
                                                    </RadioGroup>
                                                    <FormHelperText style={{ fontSize: 20 }}>{errors[i]?.message}</FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    className="forms__comment"
                                                    multiline
                                                    variant="standard"
                                                    helperText={errors[`comment${i}`]?.message}
                                                    error={!!errors[`comment${i}`]?.message}
                                                    label={
                                                        localStorage.getItem("language") === "en" ? "Comment (optional)" : "Commentaire (optionnel)"
                                                    }
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                            name={`comment${i}`}
                                            control={control}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" className="questionnaires__cta" fullWidth>
                            {localStorage.getItem("language") === "en" ? "Submit" : "Soumettre"} <MdKeyboardArrowRight />
                        </Button>
                    </Grid>
                    <div id="spacer_for_button" style={{ minHeight: 30 }} />
                </form>
            </div>
            <InstructionDialog open={dialogOpen} setOpen={setDialogOpen} types={selectedTypes} />
        </>
    );
};

export default UsabilityAcceptabilityFormGenerator;
