import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Interfaces
import { Chapter } from "../../../../interfaces/Chapter";

// Custom components
import ParticipantTable from "../../../general/SimpleTable";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Firebase
import ChaptersTableRows from "./ChaptersTableRows";
import SearchBar from "../../../general/SearchBar";
import ChapterDialog from "./ChapterDialog";

const COLLECTION = "Chapters";

const Chapters: React.FC = () => {
    const hist = useHistory();
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { firstFetch, fetchNextPage, fetchLastPage } = useDb<Chapter>(COLLECTION, currentAdmin);
    const { onSnapshotWithDoc } = useDb<any>("", currentAdmin, null, "Metadata/Chapters");

    // States
    const [open, setOpen] = useState(false);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(0);

    const handleNextPage = async (item: any) => {
        if (chapters.length !== 0) {
            setLoading(true);
            await fetchNextPage(item, setChapters, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
            setLoading(false);
        }
    };

    const handlePreviousPage = async (item: any) => {
        setLoading(true);
        await fetchLastPage(item, setChapters, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
        setLoading(false);
    };

    const fetchCount = async () => {
        try {
            return onSnapshotWithDoc((data: any) => {
                setLoading(true);
                if (data) setCount(data.count);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        if (page < newPage) handleNextPage(chapters[chapters.length - 1]);
        else if (page > newPage) handlePreviousPage(chapters[0]);

        setPage(newPage);
    };

    const fetchFirstChapters = async () => {
        await firstFetch(setChapters, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
    };

    useEffect(() => {
        setLoading(true);
        fetchCount();
        fetchFirstChapters();
        setLoading(false);
    }, []);

    return (
        <>
            <SearchBar title="Chapters" />

            <ParticipantTable
                loading={loading}
                data={chapters}
                count={count}
                rows={ChaptersTableRows({ open, setOpen })}
                noMoreData={chapters.length <= count}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onChangePage={handlePageChange}
                onDoubleClick={(c: Chapter) => hist.push(`/dashboard/chapter/${c.id}`)}
            />
            {open && <ChapterDialog open={open} setOpen={setOpen} />}
        </>
    );
};

export default Chapters;
