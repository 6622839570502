import React, { useEffect, useState } from "react";

// MUI
import Tooltip from "@mui/material/Tooltip";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Utils
import { getDangerLevelFromValue, getGaugeInfoAndDangerLevel, getIconFromTopic, getTitleFromTopic } from "../../utils/topic";

// Firebase
import { collection, getDocs, query, where } from "firebase/firestore";
import { genDoc } from "../../firebase/requests";
import { firestore } from "../../firebase/firebase";

// Interfaces
import { ChapterTopic } from "../../interfaces/Chapter";
import { Stat, StatTopic } from "../../interfaces/Stat";

const height = 150;
const topBottom = 10;
const textSpacing = 50;
const lowRiskColor = "#6dbd45";
const mediumRiskColor = "#f69220";
const highRiskColor = "#eb3539";

interface Props {
    topic: ChapterTopic;
}

interface DangerLevel {
    value: number;
    riskLevel: "low" | "moderate" | "high";
}

const RiskDiagram: React.FC<Props> = ({ topic }) => {
    const { setLoading, currentParticipant } = useFetchUser();

    // States
    const [stage1, setStage1] = useState<DangerLevel | null>(null);
    const [stage2, setStage2] = useState<DangerLevel | null>(null);
    // eslint-disable-next-line
    const [stage3, setStage3] = useState<DangerLevel | null>({
        value: 0,
        riskLevel: "low",
    });
    // eslint-disable-next-line
    const [stage4, setStage4] = useState<DangerLevel | null>({
        value: 0,
        riskLevel: "low",
    });
    // eslint-disable-next-line
    const [stage5, setStage5] = useState<DangerLevel | null>({
        value: 0,
        riskLevel: "low",
    });

    const [stats, setStats] = useState<Stat[]>([]);

    const fetchStats = async () => {
        setLoading(true);
        if (currentParticipant) {
            const data = await getDocs(
                query(collection(firestore, "Stats"), where("participantId", "==", currentParticipant.id), where("topic", "==", topic))
            );

            setStats(data.docs.map(genDoc<Stat>()));
        }
        setLoading(false);
    };

    const getDangerLevels = async () => {
        const stage1Stats = stats.filter(s => s.stage === "stage1");
        if (stage1Stats && stage1Stats.length > 0) {
            const value = getGaugeInfoAndDangerLevel(topic as StatTopic, stage1Stats[0].score).gaugeValue;
            const riskLevel = getGaugeInfoAndDangerLevel(topic as StatTopic, stats.filter(s => s.stage === "stage1")[0].score).gaugeValue;

            if (value && riskLevel) {
                setStage1({
                    value: Math.round(value / 1.8),
                    riskLevel: getDangerLevelFromValue(riskLevel),
                });
            }
        }

        const stage2Stats = stats.filter(s => s.stage === "stage2");
        if (stage2Stats && stage2Stats.length > 0) {
            const value = getGaugeInfoAndDangerLevel(topic as StatTopic, stage2Stats[0].score).gaugeValue;
            const riskLevel = getGaugeInfoAndDangerLevel(topic as StatTopic, stats.filter(s => s.stage === "stage2")[0].score).gaugeValue;

            if (value && riskLevel) {
                setStage2({
                    value: Math.round(value / 1.8),
                    riskLevel: getDangerLevelFromValue(riskLevel),
                });
            }
        }

        // const stage3Stats = stats.filter(s => s.stage === "stage3");
        // if (stage3Stats && stage3Stats.length > 0) {
        //     const value = getValueFromScore(topic as StatTopic, stage3Stats[0].score);
        //     const riskLevel = getValueFromScore(topic as StatTopic, stats.filter(s => s.stage === "stage3")[0].score);

        //     if (value && riskLevel) {
        //         setStage3({
        //             value: Math.round(value / 1.8),
        //             riskLevel: getDangerLevelFromValue(riskLevel),
        //         });
        //     }
        // }

        // const stage4Stats = stats.filter(s => s.stage === "stage4");
        // if (stage4Stats && stage4Stats.length > 0) {
        //     const value = getValueFromScore(topic as StatTopic, stage4Stats[0].score);
        //     const riskLevel = getValueFromScore(topic as StatTopic, stats.filter(s => s.stage === "stage4")[0].score);

        //     if (value && riskLevel) {
        //         setStage4({
        //             value: Math.round(value / 1.8),
        //             riskLevel: getDangerLevelFromValue(riskLevel),
        //         });
        //     }
        // }

        // const stage5Stats = stats.filter(s => s.stage === "stage5");
        // if (stage5Stats && stage5Stats.length > 0) {
        //     const value = getValueFromScore(topic as StatTopic, stage5Stats[0].score);
        //     const riskLevel = getValueFromScore(topic as StatTopic, stats.filter(s => s.stage === "stage5")[0].score);

        //     if (value && riskLevel) {
        //         setStage5({
        //             value: Math.round(value / 1.8),
        //             riskLevel: getDangerLevelFromValue(riskLevel),
        //         });
        //     }
        // }
    };

    const getBackgroundColor = (riskLevel: "low" | "moderate" | "high") => {
        switch (riskLevel) {
            case "low":
            default:
                return lowRiskColor;
            case "moderate":
                return mediumRiskColor;
            case "high":
                return highRiskColor;
        }
    };

    useEffect(() => {
        fetchStats();
    }, []);

    useEffect(() => {
        if (stats && stats.length > 0) {
            getDangerLevels();
        }
    }, [stats]);

    return (
        <div className="riskDiagram" style={{ minHeight: height + textSpacing }}>
            <p className="riskDiagram__header">
                <img src={getIconFromTopic(topic)} alt={topic} className="riskDiagram__logo" />
                <span className="riskDiagram__title">{getTitleFromTopic(topic)}</span>
            </p>

            <div className="riskDiagram__container" style={{ minHeight: height + textSpacing }}>
                {stage1 && (
                    <div>
                        <Tooltip title={`${stage1.value}%`} placement="top">
                            <div
                                className="riskDiagram__content"
                                style={{
                                    height: stage1.value * (height / 100),
                                    backgroundColor: getBackgroundColor(stage1.riskLevel),
                                    marginTop: stage1.value ? height - stage1.value * (height / 100) : height - topBottom,
                                }}
                            ></div>
                        </Tooltip>
                        <p className="riskDiagram__stage">Start</p>
                    </div>
                )}

                {stage2 && (
                    <div>
                        <Tooltip title={`${stage2.value}%`} placement="top">
                            <div
                                className="riskDiagram__content"
                                style={{
                                    height: stage2.value * (height / 100),
                                    backgroundColor: getBackgroundColor(stage2.riskLevel),
                                    marginTop: stage2.value ? height - stage2.value * (height / 100) : height - topBottom,
                                }}
                            ></div>
                        </Tooltip>
                        <p className="riskDiagram__stage">3m</p>
                    </div>
                )}

                {stage3 && (
                    <div>
                        <Tooltip title={`${stage3.value}%`} placement="top">
                            <div
                                className="riskDiagram__content"
                                style={{
                                    height: stage3.value * (height / 100),
                                    backgroundColor: getBackgroundColor(stage3.riskLevel),
                                    marginTop: stage3.value ? height - stage3.value * (height / 100) : height - topBottom,
                                }}
                            ></div>
                        </Tooltip>
                        <p className="riskDiagram__stage">-</p>
                    </div>
                )}

                {stage4 && (
                    <div>
                        <Tooltip title={`${stage4.value}%`} placement="top">
                            <div
                                className="riskDiagram__content"
                                style={{
                                    height: stage4.value * (height / 100),
                                    backgroundColor: getBackgroundColor(stage4.riskLevel),
                                    marginTop: stage4.value ? height - stage4.value * (height / 100) : height - topBottom,
                                }}
                            ></div>
                        </Tooltip>
                        <p className="riskDiagram__stage">-</p>
                    </div>
                )}

                {stage5 && (
                    <div>
                        <Tooltip title={`${stage5.value}%`} placement="top">
                            <div
                                className="riskDiagram__content"
                                style={{
                                    height: stage5.value,
                                    backgroundColor: getBackgroundColor(stage5.riskLevel),
                                    marginTop: stage5.value ? height - stage5.value * (height / 100) : height - topBottom,
                                }}
                            ></div>
                        </Tooltip>
                        <p className="riskDiagram__stage">-</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RiskDiagram;
