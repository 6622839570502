import React, { useEffect, useState } from "react";

// Components
import LogTable from "../../../general/SimpleTable";
import LogsTableRows from "./LogsTableRows";
import settings from "../../../../settings.json";
import SearchBar from "../../../general/SearchBar";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Interfaces
import { Log } from "../../../../interfaces/Log";

const COLLECTION = "Logs";

const Logs: React.FC = () => {
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { firstFetch, fetchNextPage, fetchLastPage } = useDb<Log>(COLLECTION, currentAdmin);
    const { onSnapshotWithDoc } = useDb<any>("", currentAdmin, null, "Metadata/Logs");

    // States
    const [logs, setLogs] = useState<Log[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(0);

    const handleNextPage = async (item: any) => {
        if (logs.length !== 0) {
            setLoading(true);
            await fetchNextPage(item, setLogs, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
            setLoading(false);
        }
    };

    const handlePreviousPage = async (item: any) => {
        setLoading(true);
        await fetchLastPage(item, setLogs, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
        setLoading(false);
    };

    const fetchCount = async () => {
        try {
            return onSnapshotWithDoc((data: any) => {
                setLoading(true);
                if (data) setCount(data.count);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        if (page < newPage) handleNextPage(logs[logs.length - 1]);
        else if (page > newPage) handlePreviousPage(logs[0]);

        setPage(newPage);
    };

    const fetchFirstLogs = async () => {
        await firstFetch(setLogs, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
    };

    useEffect(() => {
        setLoading(true);
        fetchCount();
        fetchFirstLogs();
        setLoading(false);
    }, []);

    return (
        <>
            <SearchBar title="Logs" />

            <LogTable
                loading={loading}
                data={logs}
                count={count}
                noMoreData={logs.length <= count}
                rows={LogsTableRows()}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onChangePage={handlePageChange}
            />
        </>
    );
};

export default Logs;
