import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// React-router-dom
import { useParams, Link } from "react-router-dom";

// Components
import Title from "../../general/Title";

// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Assets
import { HiOutlineHeart, HiHeart } from "react-icons/hi";
import iconLocked from "../../../assets/icons/icon-locked.png";

// Interfaces
import { Chapter } from "../../../interfaces/Chapter";
import { Participant } from "../../../interfaces/Participant";
import { StatTopic } from "../../../interfaces/Stat";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { getIconFromTopic, getTitleFromTopic } from "../../../utils/topic";

interface paramsType {
    topic: StatTopic;
}

const Topic: React.FC = () => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();
    const chaptersRequest = useDb<Chapter>("Chapters", null, currentParticipant);
    const participantsRequest = useDb<Participant>("Participants", null, currentParticipant);
    const { topic } = useParams<paramsType>();

    // States
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [participant, setParticipant] = useState<Participant | null>(null);

    const handleSetFavourite = async (c: Chapter) => {
        if (currentParticipant && currentParticipant.id && c.id && participant) {
            let list: string[] = [];
            if (participant.favourites) list = participant.favourites;
            // if already favourited
            if (list.includes(c.id)) {
                list = list.filter(chap => chap !== c.id);
            } else {
                list.push(c.id);
            }
            participantsRequest.updateDoc({ ...participant, favourites: list } as Participant);
        }
    };

    const isChapterCompleted = (chapter: Chapter) => {
        if (
            chapter &&
            chapter.id &&
            currentParticipant &&
            currentParticipant.completedChapters &&
            (currentParticipant.completedChapters as any)[chapter.topic]
        ) {
            return currentParticipant.completedChapters[chapter.topic].includes(chapter.id);
        } else return false;
    };

    const isChapterUnlocked = (chapter: Chapter) => {
        if (
            chapter &&
            chapter.id &&
            currentParticipant &&
            currentParticipant.unlockedChapters &&
            (currentParticipant.unlockedChapters as any)[chapter.topic]
        ) {
            return currentParticipant.unlockedChapters[chapter.topic].includes(chapter.id);
        } else return false;
    };

    useEffect(() => {
        let unsubParticipants: any = null;

        const unsubChapters = chaptersRequest.onSnapshot({
            callback: setChapters,
            filters: [{ field: "topic", operator: "==", value: topic }],
            orderBy: { field: "chapter", direction: "asc" },
        });

        if (currentParticipant && currentParticipant.id) {
            unsubParticipants = participantsRequest.onSnapshot({
                callback: setParticipant,
                id: currentParticipant.id,
            });
        }

        return () => {
            unsubChapters();
            if (typeof unsubParticipants === "function") unsubParticipants();
        };
    }, []);

    return (
        <div className="topic">
            <header className="topic__header">
                <div className="topic__header__title">
                    <img src={getIconFromTopic(topic)} alt="" className="nofilterimg" />
                    <div className="topic__header__content">
                        <Link to="/topics" className="topic__header__back">
                            <h4>{t("topic")}</h4>
                        </Link>
                        <Title>{getTitleFromTopic(topic)}</Title>
                        <p>{t("topicDesc")}</p>
                    </div>
                </div>
                <div className="topic__header__legend">
                    <div className="topic__header__legend__item">
                        <div className="taskCard__fullHearth__legend">
                            <HiHeart />
                        </div>
                        <p>{t("fullHeartLegend")}</p>
                    </div>
                    <div className="topic__header__legend__item">
                        <div className="taskCard__outlineHeart__legend">
                            <HiOutlineHeart />
                        </div>
                        <p>{t("outlineHeartLegend")}</p>
                    </div>
                    <div className="topic__header__legend__item">
                        <div className="progressbar__complete__legend"></div>
                        <p>{t("greenCheckmarkLegend")}</p>
                    </div>
                    <div className="topic__header__legend__item">
                        <div className="progressbar__incomplete__legend"></div>
                        <p>{t("outlineCheckmarkLegend")}</p>
                    </div>
                </div>
            </header>
            <Grid container spacing={4}>
                {chapters &&
                    chapters.map((chapter: Chapter, index: number) => (
                        <Grid item xs={6} key={index}>
                            {isChapterUnlocked(chapter) ? (
                                <div
                                    className="taskCard"
                                    style={{
                                        opacity: !isChapterUnlocked(chapter) ? "0.5" : "1",
                                    }}
                                >
                                    <div
                                        className="taskCard__content"
                                        onClick={() =>
                                            isChapterCompleted(chapter) || isChapterUnlocked(chapter) ? hist.push(`/chapter/${chapter.id}`) : {}
                                        }
                                        style={{
                                            cursor: isChapterCompleted(chapter) || isChapterUnlocked(chapter) ? "pointer" : "",
                                        }}
                                    >
                                        <h4>
                                            {t("chapter")} {chapter.chapter}
                                        </h4>
                                        <h3>{localStorage.getItem("language") === "en" ? chapter.titleEN : chapter.titleFR}</h3>
                                    </div>
                                    <Box
                                        className="progressbar__container"
                                        sx={{ display: "flex", alignItems: "center" }}
                                        onClick={() => (isChapterCompleted(chapter) ? hist.push(`/chapter/${chapter.id}`) : {})}
                                        style={{ cursor: isChapterCompleted(chapter) ? "pointer" : "" }}
                                    >
                                        <div
                                            className={`progressbar__bar ${
                                                isChapterCompleted(chapter)
                                                    ? "progressbar__complete progressbar__bottom"
                                                    : "progressbar__bottom progressbar__incomplete"
                                            }`}
                                        ></div>
                                    </Box>
                                    <div className="taskCard__topRightCorner taskCard__pointer" onClick={() => handleSetFavourite(chapter)}>
                                        {participant && participant.favourites && chapter.id && participant.favourites.includes(chapter.id) ? (
                                            <div className="taskCard__fullHearth">
                                                <HiHeart />
                                            </div>
                                        ) : (
                                            <HiOutlineHeart />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="taskCard"
                                    style={{
                                        opacity: !isChapterUnlocked(chapter) ? "0.5" : "1",
                                    }}
                                >
                                    <div
                                        className="taskCard__content"
                                        onClick={() =>
                                            isChapterCompleted(chapter) || isChapterUnlocked(chapter) ? hist.push(`/chapter/${chapter.id}`) : {}
                                        }
                                        style={{
                                            cursor: isChapterCompleted(chapter) || isChapterUnlocked(chapter) ? "pointer" : "",
                                        }}
                                    >
                                        <h4>
                                            {t("chapter")} {chapter.chapter}
                                        </h4>
                                        <h3>{localStorage.getItem("language") === "en" ? chapter.titleEN : chapter.titleFR}</h3>
                                    </div>
                                    <Box
                                        className="progressbar__container"
                                        sx={{ display: "flex", alignItems: "center" }}
                                        onClick={() => (isChapterCompleted(chapter) ? hist.push(`/chapter/${chapter.id}`) : {})}
                                        style={{ cursor: isChapterCompleted(chapter) ? "pointer" : "" }}
                                    >
                                        <div
                                            className={`progressbar__bar ${
                                                isChapterCompleted(chapter) ? "progressbar__complete progressbar__bottom" : ""
                                            }`}
                                        ></div>
                                    </Box>
                                    <div>
                                        <img className="taskCard__locked" src={iconLocked} alt="" />
                                    </div>
                                </div>
                            )}
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
};

export default Topic;
