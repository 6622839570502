import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Firebase
import { firestore, auth } from "../../../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Mui
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import useDb from "../../../hooks/useDb";

// Settings
import settings from "../../../settings.json";

// Utils
import { getWeeklyChapters } from "../../../utils/topic";
import { isInTimeline } from "../../../utils/general";

// Interfaces
import { Participant } from "../../../interfaces/Participant";

// Components
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import NewChaptersDialog from "../../pages/portal/dialogs/NewChaptersDialog";
import Loading from "../../general/Loading";
import { areAllChaptersUnlocked } from "../../../utils/chapter";
import { stage5Completed } from "../../../utils/questionnaires";
import { t } from "i18next";
import SubDomainRedirection from "../../pages/portal/SubDomainRedirection";

const MainLayout: React.FC = ({ children }) => {
    const hist = useHistory();
    const { currentParticipant, currentWeek, loading } = useFetchUser();
    const participantsRequest = useDb<Participant>("Participants");

    // States
    const [width, setWidth] = useState(window.innerWidth);
    const [newChaptersDialogOpen, setNewChaptersDialogOpen] = useState(false);
    const [syn2DialogOpen, setSyn2DialogOpen] = useState(false);

    const handleResize = () => setWidth(window.innerWidth);

    // Window closing listener
    window.addEventListener("beforeunload", async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index !== -1) {
                    if (participant.uses[index]) {
                        participant.uses[index].end = new Date();

                        await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses],
                        });
                    }
                }
            }
        }
    });

    window.addEventListener("resize", handleResize);

    const updateTimeline = async () => {
        try {
            if (currentWeek && currentParticipant && currentParticipant.id && !(await areAllChaptersUnlocked(currentParticipant.id))) {
                for (let weekIndex = 2; weekIndex <= currentWeek; weekIndex++) {
                    // Refreshing the participant
                    const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
                    const participant = genDoc<Participant>()(data);

                    if (participant && participant.id) {
                        if (
                            (weekIndex > 1 && weekIndex < 10 && (participant.timeline as any)[`week0${weekIndex}`].length === 0) ||
                            (weekIndex >= 10 && (participant.timeline as any)[`week${weekIndex}`].length === 0)
                        ) {
                            const weeklyChapters = await getWeeklyChapters(weekIndex, participant.id);
                            const weeklyIds: string[] = [];
                            weeklyChapters.forEach(c => c.id && !isInTimeline(c.id, participant) && weeklyIds.push(c.id));

                            if (weekIndex > 1 && weekIndex < 10 && (participant.timeline as any)[`week0${weekIndex}`].length === 0) {
                                await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                                    timeline: { ...participant.timeline, [`week0${weekIndex}`]: weeklyIds },
                                });
                                if (currentParticipant.timeline.week01.length > 2) {
                                    console.log("updated timeline", `week0${weekIndex}`, weeklyIds);
                                }
                            }

                            if (weekIndex >= 10 && weekIndex <= 51 && (participant.timeline as any)[`week${weekIndex}`].length === 0) {
                                await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                                    timeline: { ...participant.timeline, [`week${weekIndex}`]: weeklyIds },
                                });
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchData = async () => {
        if (currentParticipant && currentParticipant.id) {
            // -------------------------- Forms -------------------------- //

            if (currentParticipant.deactivated && !currentParticipant.stages.stage5) {
                // FORCE TO STAGE5 Questionnaires
                return hist.push("/questionnaires/stage5");
            }

            if (currentParticipant.deactivated && currentParticipant.stages.stage5) {
                // set isDeleted + logout participant
                await participantsRequest.updateDoc({ ...currentParticipant, isDeleted: true });
                return auth.signOut();
            }

            if (!currentParticipant.completedChapters["Brain Overview"].includes("z9FrQXipd4aNRgDmTzXC")) {
                // 0
                return hist.push("/fullscreenChapter/z9FrQXipd4aNRgDmTzXC/false/false");
            }

            // stage1 questionnaires
            if (!currentParticipant.stages.stage1) {
                window.location.href = "/questionnaires/stage1";
            }

            if (!currentParticipant.completedChapters["Brain Overview"].includes("x6lmvU6hXZ9do1uM8zPn")) {
                // 1
                return hist.push("/fullscreenChapter/x6lmvU6hXZ9do1uM8zPn/false/false");
            }

            if (currentWeek) {
                if (currentWeek >= 12) {
                    if (!currentParticipant.stages.stage2) {
                        window.location.href = "/questionnaires/stage2";
                    }
                }
                if (currentWeek >= 23) {
                    if (!currentParticipant.stages.stage3) {
                        window.location.href = "/questionnaires/stage3";
                    }
                }
                if (currentWeek >= 34) {
                    if (!currentParticipant.stages.stage4) {
                        window.location.href = "/questionnaires/stage4";
                    }
                }
                if (currentWeek >= 45) {
                    if (!currentParticipant.stages.stage5 || !(await stage5Completed(currentParticipant))) {
                        window.location.href = "/questionnaires/stage5";
                    }
                }

                // -------------------------- Chapters -------------------------- //
                if (currentWeek >= 3 && currentParticipant.timeline.week03.length === 0) {
                    if (currentParticipant.completedChapters["Brain Overview"].includes("L9JEcW0LVHgdT90SfPmi")) {
                        // 5
                        if (currentParticipant.completedChapters["Brain Overview"].includes("dBjDojoMPDWv3gvJCDjt")) {
                            // 6.1
                            if (currentParticipant.prioritizedTopics.length > 0) {
                                // prioritizedTopics
                                if (currentParticipant.completedChapters["Brain Overview"].includes("h1BYNJTOhheE8tJ1khUk")) {
                                    // 6.2
                                    if (currentParticipant.goals.stage1.length > 0) {
                                        // goals
                                    } else return hist.push("/goals");
                                } else return hist.push("/chapter/h1BYNJTOhheE8tJ1khUk");
                            } else return hist.push("/prioritizedTopics");
                        } else return hist.push("/chapter/dBjDojoMPDWv3gvJCDjt");
                    } else return hist.push("/chapter/L9JEcW0LVHgdT90SfPmi");
                } else if ((currentParticipant.timeline as any)[`week${currentWeek < 10 ? `0${currentWeek}` : currentWeek}`].length === 0) {
                    await updateTimeline();
                }
            }
        }
    };

    const updateUses = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index === -1) {
                    await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                        uses: [...participant.uses, { start: new Date() }],
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (currentParticipant && currentParticipant.pscid?.includes("syn2")) {
            setSyn2DialogOpen(true);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (currentParticipant && currentParticipant.manualS5) return hist.push("manualQuestionnaires/manualS5");
    }, [currentParticipant]);

    useEffect(() => {
        if (currentParticipant && !currentParticipant.manualS5) fetchData();
        if (currentWeek > 0) updateUses();
    }, [currentWeek]);

    return (
        <>
            <SubDomainRedirection />
            <Loading loading={loading} />
            <div className="mainLayout__container">
                {width >= settings.page.maxScreenWidth ? <DesktopDrawer /> : <MobileDrawer />}
                <main className="mainLayout__content">
                    <div className="mainLayout__appBarSpacer" />
                    <div className="mainLayout__info">
                        <Paper style={{ padding: 24 }}>{children}</Paper>
                        {newChaptersDialogOpen && <NewChaptersDialog open={newChaptersDialogOpen} setOpen={setNewChaptersDialogOpen} />}
                    </div>
                </main>
            </div>
            <Dialog open={syn2DialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        window.location.href = "https://syn2.brainhealthpro.ca";
                    }}
                >
                    <DialogTitle id="alert-dialog-title" style={{ fontSize: 25 }}>
                        Synergic-2
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ fontSize: 20 }} id="alert-dialog-description">
                            {t("syn2Dialog")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions></DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default MainLayout;
