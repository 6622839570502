export const fr = {
    translation: {
        //---------------- PORTAL ----------------//
        // Home
        week: "Semaine",
        hi: "Bonjour",
        task: "Chapitre",
        toComplete: "à compléter cette semaine",
        achieved: "Complété",
        hasCompleted: "depuis le début de votre programme",
        achievements: "Exploits",
        achievements_tooltip: "Terminez vos chapitres et tâches hebdomadaires pour accumuler des points",
        earned: "Vous avez accumulé",
        inARow: "d’affilée",
        chapter: "Chapitre",
        skipChapter: "Sauter ce chapitre",
        syn2Dialog: "Le site Web du programme de Santé Cerveau pour l'étude SYNERGIC-2 a été mis à jour. Veuillez cliquer ici pour être dirigé vers la nouvelle URL.",
        // DrawerContent
        home: "Tableau de bord",
        formtest: "Tests de Formulaires",
        profile: "Profil",
        topics: "Thèmes",
        favourites: "Favoris",
        completed: "Complétés",
        notebook: "Carnet de notes",
        exportInfo: "Veuillez cliquer ici pour télécharger les notes de votre carnet de notes.",
        help: "Aide",
        logout: "Déconnexion",
        // PortalLayout
        textSize: "Taille du texte",
        // Topic titles
        brainOverviewTitle: "Aperçu sur la santé du cerveau",
        physicalTitle: "Activité physique",
        cognitiveTitle: "Engagement cognitif",
        nutritionTitle: "Nutrition",
        sleepTitle: "Sommeil",
        socialTitle: "Santé psychologique et sociale",
        vascularTitle: "Santé cardiovasculaire",
        visionTitle: "Vision et audition",
        efficacyTitle: "Perception de votre santé",
        dementiaTitle: "Comprendre les troubles neurocognitifs",
        attitudesTitle: "Votre vision des troubles neurocognitifs",
        // Risks
        risk: "RISQUE",
        lowRisk: "Risque bas",
        moderateRisk: "Risque modéré",
        highRisk: "Risque élevé",
        low: "BAS",
        high: "ÉLEVÉ",
        // Nav
        login: "Connexion",
        // Landing
        brainHealthPro: "Santé Cerveau PRO",
        brainHealthProDescription:
            "Vous pouvez faire des changements pour réduire votre risque de troubles neurocognitifs. Santé Cerveau PRO vous montrera comment faire.",
        register: "Inscription",
        registerText: "Commençez dès maintenant !",
        loginText: "Continuez d’apprendre sur le site de Santé Cerveau PRO",
        accountHelp: "Aide",
        accountHelpText: "Pour recevoir de l’aide pour vous connecter ou pour toute autre question",
        about: "Santé Cerveau PRO a été créé par des experts de la santé du cerveau et du vieillissement. Le programme vous donne des conseils essentiels sur la façon de maintenir votre cerveau et votre cognition en bonne santé à mesure que vous vieillissez.",
        aboutUs: "À propos de nous",
        aboutLink: "Lien pour s'inscrire afin d'être informé de la sortie publique ?",
        contact: "Contactez-nous",
        admin: "Administrateur",
        lang: "English",
        // Profile(Metrics)
        brainHealthProfileTitle: "Votre profil de santé du cerveau",
        overallBrainHealth: "Votre santé du cerveau",
        profileDesc1: "Sur la base de vos réponses aux questionnaires sur le mode de vie, voici vos profils de risque actuels.",
        profileDesc2: "Pour voir comment votre risque a changé pour un thème en particulier, cliquez dessus.",
        // Topics
        chapters: "chapitres",
        topicsDesc:
            "Voici votre progression dans chacun des huit thèmes de Santé Cerveau PRO. Cliquez sur l'un d'eux pour voir la liste des chapitres",
        of: "de",
        priorityTopic: "Thème prioritaire",
        // Notebook
        notebookInstructions:
            "Veuillez choisir le thème que vous voulez télécharger avec la flèche pointant vers le bas (⯆). Alternativement, vous pouvez aussi télécharger le carnet au complet en sélectionnant 'Tous les thèmes'",
        notebookInfo1: "Vous trouverez ici votre carnet de notes. Vous pouvez l'utiliser de trois manières différentes :",
        notebookInfo2: "Vous pouvez le télécharger sous forme de fichier .PDF et soit :",
        notebookInfo3: "le remplir électroniquement à l'aide d'Adobe Reader.",
        notebookInfo4: "l’imprimer pour rédiger vos notes.",
        notebookInfo5:
            "Pour l'une ou l'autre de ces options, cliquez sur la flèche vers le bas pour sélectionner le bloc-notes thématique que vous souhaitez télécharger. Alternativement, vous pouvez simplement cliquer sur « Tous les thèmes » pour télécharger le carnet de notes complet comprenant les huit thèmes.",
        notebookInfo6: "Créez le vôtre en utilisant celui de Santé Cerveau PRO comme modèle.",
        notebookInfo7:
            "Complétez-le à l'écran. Pour y avoir accès tout en visualisant un chapitre, vous devrez ouvrir deux onglets sur votre navigateur Internet, un avec le carnet de notes et un avec le chapitre que vous souhaitez visualiser.",
        everyTopic: "Tous les thèmes",
        // Help
        helpType: "Type d'aide",
        submit: "Envoyer mon courriel de support",
        faq: "Foire aux questions",
        replayIntro: "Rejouer le chapitre Orientation",
        replayFirstStep: "Rejouer le chapitre Premier pas",
        replayInteractiveActivities: "Rejouer le chapitre Activités interactives",
        question1: "1.  Quand puis-je changer mes priorités?",
        answer1: "Vous ne pouvez pas changer vos priorités une fois que vous les avez choisies",
        question2: "2.  Comment puis-je revoir les chapitres déjà visionnés?",
        answer2: `Cliquez sur "Complété" dans votre tableau de bord`,
        question3: "3.	Quelque chose est arrivé et je n'ai pas pu compléter mes chapitres la semaine dernière. Qu'est-ce que je peux faire?",
        answer3: "Ne vous inquiétez pas, les chapitres non complétés restent disponibles sur votre page d'accueil",
        help1: "Si vous avez besoin d'aide technique ou avez des questions sur Santé Cerveau Pro, vous êtes au bon endroit.",
        help2: "Dans cette section, vous pouvez:",
        help3: "Consultez le chapitre Orientation qui contient de nombreuses informations.",
        help4: "Lire les réponses aux questions fréquemment posées.",
        help5: "Toujours bloqué, vous pouvez nous écrire",
        // Login
        loginTitle: "Connexion",
        enterEmail: "Courriel",
        password: "Mot de passe",
        forgotPassword: "Mot de passe oublié",
        // Topic
        topic: "Thème",
        topicDesc: "Pour visualiser un chapitre non verrouillé, il suffit de cliquer dessus",
        fullHeartLegend: "Chapitre favori",
        outlineHeartLegend: "Chapitre non-favori",
        greenCheckmarkLegend: "Chapitre complété",
        outlineCheckmarkLegend: "Chapitre à compléter",
        // Register
        registerTitle: "Inscrivez-vous à Santé Cerveau PRO",
        step1: "Étape 1 : Entrez votre adresse courriel",
        next: "Suivant",
        youWillNeed: "Vouz aurez besoin de",
        yourEmail: "Votre adresse courriel",
        moreThanOne: "Si vous avez plus d'une adresse courriel, utilisez celle que vous nous avez donné.",
        "5min": "À peu près 5 min",
        "5minDesc": "Créer un compte prend environ 5 min",
        step2: "Étape 2 : Confirmez votre adresse courriel",
        correctEmail: "Est-ce bien votre adresse courriel?",
        confirm: "Confirmer",
        back: "Retour",
        step3: "Étape 3 : Entrez votre mot de passe",
        enterPassword: "Entrez votre mot de passe",
        confirmPassword: "Confirmez votre mot de passe",
        step4: "Étape 4 : Entrez l'information additionnelle",
        nickname: "Pseudonyme",
        dateOfBirth: "Date de naissance",
        language: "Langue",
        // Goals
        goal: "Objectif",
        goals: "Vos Objectifs",
        goalsComplete: "Avez-vous complété vos objectifs précédents?",
        goalsTitle: "Sélection des objectifs",
        pleaseEnter: "S'il vous plaît, entrez un",
        new: "nouvel",
        goalsTopics: "objectif pour chacun de ces thèmes",
        prioritizedTopic: "Thèmes Prioritaires",
        prioritizedTopics: "Il est temps de sélectionner vos trois thèmes prioritaires",
        selectTopics: "Veuillez choisir 3 thèmes",
        dangerTopics: "Les thèmes à risque sont présélectionnés",
        selectGoal1: "Cliquez pour sélectionner le premier objectif",
        selectGoal2: "Cliquez pour sélectionner le deuxième objectif",
        selectGoal3: "Cliquez pour sélectionner le troisième objectif",
        // Topic Picker
        desiredTopic1: "S'il vous plaît, choisissez le premier thème",
        desiredTopic2: "S'il vous plaît, choisissez le deuxième thème",
        desiredTopic3: "S'il vous plaît, choisissez le troisième thème",
        topicPicker: "Sélection du thème",
        topicInfo: "Déplacez votre souris ou votre doigt sur le nom d'un thème pour voir le profil de risque associé",
        // Goal Picker
        desiredGoal1: "S'il vous plaît, choisissez le premier objectif",
        desiredGoal2: "S'il vous plaît, choisissez le deuxième objectif",
        desiredGoal3: "S'il vous plaît, choisissez le troisième objectif",
        goalPicker: "Sélection de l'objectif",
        goalTitleInfo: "Il est temps de fixer un objectif pour chacun de vos thèmes prioritaires. Cliquez sur chaque case et écrivez votre objectif.",
        goalListInfo:
            "N'oubliez pas de fixer des objectifs importants, réalisables et agréables. Voici quelques questions qui peuvent vous aider à créer des objectifs spécifiques :",
        goalInfo1: "Que ferez-vous?",
        goalInfo2: "Dans quelle mesure le ferez-vous ?",
        goalInfo3: "À quelle fréquence le ferez-vous ?",
        goalInfo4: "Où et quand le ferez-vous ?",
        goalInfo5: "Avec qui le ferez-vous ?",
        goalInfo6: "Comment allez-vous suivre vos progrès ?",
        // Snackbars
        missingPermissions: "Permissions manquantes",
        emailSent: "Courriel envoyé avec succès",
        emailInvalid: "Courriel invalide",
        userNotFound: "Pas d'utilisateur avec ce courriel",
        wrongPassword: "Combinaison de courriel et mot de passe incorrecte",
        pscidMustBeDefined: "PSCID doit être défini",
        whitelistUserCreated: "Utilisateur liste blanche a été créé",
        whitelistUserUpdated: "Utilisateur liste blanche a été mis à jour",
        userUpdated: "Utilisateur mis à jour",
        userCreated: "Utilisateur créé",
        noRequiredRole: "Vous n'avez pas le rôle requis",
        chapterModified: "Chapitre modifié",
        chapterCreated: "Chapitre créé",
        "1pointCompletedGained": "Vous avez obtenu",
        "1pointCompletedChapter": "pour avoir complété le chapitre",
        "1pointCompletedGoals": "pour avoir complété vos objectifs",
        "1pointCompletedPrioritized": "pour avoir complété vos thèmes priorisés",
        "1pointCompletedQuestionnaire": "pour avoir complété le questionnaire",
        goalsSelected: "Vous avez sélectionné vos objectifs",
        selectTopic1: "Cliquez pour sélectionner le premier thème prioritaire",
        selectTopic2: "Cliquez pour sélectionner le deuxième thème prioritaire",
        selectTopic3: "Cliquez pour sélectionner le troisième thème prioritaire",
        stageCompleted: "Questionnaires {{stage}} complétés",
        participantCreated: "Participant créé",
        participantError: "Une erreur s'est produite lors de la création du participant",
        participantAlreadyExist: "Le participant existe déjà",
        participantNotWhitelisted: "Le participant n'est pas sur la liste blanche",
        questionnaireCompleted: "Questionnaire {{title}} complété",
        participantNotFound: "Participant manquant",
        participantDeleted: "Participant supprimé",
        participantDisabled: "Participant désactivé",
        networkError: "Erreur de réseau",
        // Questionnaire instructions
        questionnaireInstructions: "Instructions des questionnaires",
        yes: "Oui",
        no: "Non",
        questionnaireComplete: `Il est important de cliquer sur le bouton "Accueil", inclus dans le chapitre, sur la dernière diapositive, pour obtenir votre récompense et faire apparaître ce chapitre comme terminé sur votre page d'accueil`,
        // Completed
        completedDesc: "Voici vos chapitres terminés. Vous pouvez les revoir en cliquant dessus",
        // Favourites
        favDesc: "Voici vos chapitres favoris. Si vous voulez revoir l'un d'entre eux, il suffit de cliquer dessus",
        // disclaimer
        disclaimer: "Information importante",
        agree: "Accepter",
        // Forgot Password
        forgotPasswordDesc:
            "Si vous avez oublié votre mot de passe, veuillez entrer votre adresse courriel. Si ce courriel est valide, vous recevrez un message avec les démarches à suivre afin de réinitialiser votre mot de passe.",
        send: "Envoyer",
        // Metric
        stage1Title: "Début",
        stage2Title: "12 semaines",
        stage3Title: "23 semaines",
        stage4Title: "34 semaines",
        stage5Title: "45 semaines",
        current: "En Cours",
        // Overview
        continue: "Continuer",
        // Routes
        landing: "Accueil",
        routeLogin: "Connexion",
        completeChapter: "Terminer le chapitre",
        yourProfile: "Votre profil",
        favouriteTopic: "Chapitres favoris",
        completedTopics: "Chapitres complétés",
        overview: "Aperçu",
        finalOverview: "Aperçu final",
        myNotebook: "Mon cahier",
        formTest: "Form Test",
        questionnaires: "Questionnaires",
        // Final Overview
        finalOverviewInformation: "Information sur l'aperçu final",
        export: "Exporter",
        // Library
        library: "Bibliothèque",
        libraryInfo: "Cliquez sur le sujet pour afficher les chapitres",
    },
};
