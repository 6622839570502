import { forwardRef, useState, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

// Mui core
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Utils
import { getIconFromTopic, getTitleFromTopic } from "../../../../utils/topic";

// Interfaces
import { StatTopic } from "../../../../interfaces/Stat";

const GoalPickerDialog = forwardRef(({ setGoal, setValue, desiredGoal, topic, children }: any, ref) => {
    const { t } = useTranslation();

    // States
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [selectedGoal, setSelectedGoal] = useState("");

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        },
        close() {
            setOpen(false);
        },
    }));

    const properGoal = (desiredGoal: string) => {
        switch (desiredGoal) {
            case "goal1":
                return t("desiredGoal1");
            case "goal2":
                return t("desiredGoal2");
            case "goal3":
                return t("desiredGoal3");
            default:
                return t("desiredGoal1");
        }
    };

    const handleConfirm = () => {
        if (!selectedGoal) {
            setError(properGoal(desiredGoal));
        } else {
            setGoal(selectedGoal);
            setOpen(false);
        }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} style={{ minWidth: 800 }}>
            <div style={{ padding: 20 }}>
                <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>
                    {t("goalPicker")}
                </DialogTitle>
                <DialogContent>
                    <h4 style={{ textAlign: "center", marginBottom: 10 }}>{properGoal(desiredGoal)}</h4>
                    <h5 style={{ marginBottom: 10, textAlign: "justify" }}>{t("goalTitleInfo")}</h5>

                    <TextField
                        style={{ marginBottom: 10 }}
                        autoFocus
                        variant="outlined"
                        multiline
                        rows={3}
                        label={
                            <p style={{ display: "flex", alignItems: "center" }}>
                                <img src={getIconFromTopic(topic as StatTopic)} alt={topic} style={{ maxWidth: 35 }} />
                                <span style={{ paddingLeft: 10 }}>{getTitleFromTopic(topic as StatTopic)}</span>
                            </p>
                        }
                        value={selectedGoal}
                        onChange={e => {
                            setSelectedGoal(e.target.value);
                            setValue(desiredGoal, e.target.value);
                        }}
                        helperText={error}
                        error={!!error}
                        fullWidth
                    />
                    <h5 style={{ textAlign: "justify" }}>{t("goalListInfo")}</h5>
                    <ul style={{ fontSize: "0.83em" }}>
                        <li>{t("goalInfo1")}</li>
                        <li>{t("goalInfo2")}</li>
                        <li>{t("goalInfo3")}</li>
                        <li>{t("goalInfo4")}</li>
                        <li>{t("goalInfo5")}</li>
                        <li>{t("goalInfo6")}</li>
                    </ul>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleConfirm} className="questionnaires__cta" fullWidth>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
});

export default GoalPickerDialog;
