import React from "react";

// Mui core
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

interface Props {
    open: boolean;
    setOpen: any;
}

const NewChapterDialog: React.FC<Props> = ({ open, setOpen }) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">New Chapters</DialogTitle>
            <DialogContent>
                <p>You have new chapters available</p>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default NewChapterDialog;
