import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Cryptr from "cryptr";
import DateFnsUtils from "@date-io/moment";
import { useTranslation } from "react-i18next";

// Mui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

// Components
import Nav from "../../navigation/nav";
import LoginHero from "../../general/LoginHero";
import Footer from "../../navigation/footer";

// Icons
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

// Utils
import { emailRegex } from "../../../utils/regex";
import settings from "../../../settings.json";
import { changeLanguage } from "../../../utils/language";

// Firebase
import { httpsCallable } from "firebase/functions";
import { signInWithEmailAndPassword } from "firebase/auth";
import { functions, auth } from "../../../firebase/firebase";

// Interfaces
import { Participant } from "../../../interfaces/Participant";
import { WhiteListUser } from "../../../interfaces/WhitelistUser";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";

const cryptr = new Cryptr(settings.app.secret);

const Register: React.FC = () => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant, setLoading, resetStates } = useFetchUser();

    const participantRequests = useDb<Participant>("Participants", null, currentParticipant);
    const whitelistUserRequests = useDb<WhiteListUser>("WhitelistUsers", null, currentParticipant);

    // States
    const [errors, setErrors] = useState<any>(null);
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [language, setLanguage] = useState("");
    const [nickname, setNickname] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

    const handleStep1Submit = async (e: any) => {
        e.preventDefault();

        const localErrors: any = {};

        if (!email || (email && !email.match(emailRegex))) {
            localErrors.email = {
                isError: true,
                message: "Invalid email",
            };
        } else {
            delete localErrors.email;

            if (localErrors && Object.keys(localErrors).length === 0 && Object.getPrototypeOf(localErrors) === Object.prototype) {
                const participants = await participantRequests.getAll({ filters: [{ field: "email", operator: "==", value: email }] });
                const whitelistUsers = await whitelistUserRequests.getAll({ filters: [{ field: "email", operator: "==", value: email }] });

                if (participants.length && participants[0]) {
                    localErrors.email = {
                        isError: true,
                        message: "Email already in use",
                    };
                    setErrors(localErrors);
                    return enqueueSnackbar(t("participantAlreadyExist"), { variant: "error" });
                } else if (!whitelistUsers.length) {
                    localErrors.email = {
                        isError: true,
                        message: "Email not whitelisted",
                    };
                    setErrors(localErrors);
                    return enqueueSnackbar(t("participantNotWhitelisted"), { variant: "error" });
                } else {
                    delete localErrors.email;
                    setStep(1);
                }
            }
        }

        setErrors(localErrors);

        if (localErrors && Object.keys(localErrors).length === 0 && Object.getPrototypeOf(localErrors) === Object.prototype) {
            setStep(2);
        }
    };
    const handleStep2Submit = async (e: any) => {
        e.preventDefault();
        setStep(3);
    };

    const handleStep3Submit = async (e: any) => {
        e.preventDefault();

        const localErrors: any = {};

        if (!password || (password && password.length < 6)) {
            localErrors.password = {
                isError: true,
                message: "Invalid password (min 6 characters)",
            };
        }

        if (!passwordConfirmation || (passwordConfirmation && passwordConfirmation !== password)) {
            localErrors.passwordConfirmation = {
                isError: true,
                message: "Password should match",
            };
        }

        setErrors(localErrors);

        if (localErrors && Object.keys(localErrors).length === 0 && Object.getPrototypeOf(localErrors) === Object.prototype) {
            setStep(4);
        }
    };

    const handleStep4Submit = async (e: any) => {
        e.preventDefault();

        const localErrors: any = {};

        if (!nickname) {
            localErrors.nickname = {
                isError: true,
                message: "nickname is required",
            };
        } else delete localErrors.nickname;

        if (!language) {
            localErrors.language = {
                isError: true,
                message: "language is required",
            };
        } else delete localErrors.language;

        if (!dateOfBirth) {
            localErrors.dateOfBirth = {
                isError: true,
                message: "date of birth is required",
            };
        } else {
            if (isNaN(dateOfBirth.getTime())) {
                localErrors.dateOfBirth = {
                    isError: true,
                    message: "Invalid date",
                };
            } else delete localErrors.dateOfBirth;
        }

        setErrors(localErrors);

        if (localErrors && Object.keys(localErrors).length === 0 && Object.getPrototypeOf(localErrors) === Object.prototype) {
            setLoading(true);
            const createParticipant = httpsCallable(functions, "createParticipant");
            const createParticipantPayload = await createParticipant({ email, password: cryptr.encrypt(password), nickname, dateOfBirth, language });

            if (createParticipantPayload) {
                resetFields();

                // Setting base language
                changeLanguage(language, setLanguage);

                resetStates();

                await signInWithEmailAndPassword(auth, email, password);
                enqueueSnackbar(t("participantCreated"), { variant: "success" });
                setLoading(false);
                hist.push("/home");
            } else enqueueSnackbar(t("participantError"), { variant: "error" });
        }
    };

    const resetFields = () => {
        setEmail("");
        setNickname("");
        setDateOfBirth(null);
        setLanguage("");
    };

    if (step === 1) {
        return (
            <>
                <Nav />
                <LoginHero />
                <div className="login__container">
                    <div className="form__container">
                        <h2 className="login__title">{t("registerTitle")}</h2>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <form noValidate onSubmit={handleStep1Submit}>
                                    <h6>
                                        <u>{t("step1")}</u>
                                    </h6>
                                    <TextField
                                        id="email"
                                        margin="normal"
                                        value={email}
                                        placeholder={t("enterEmail")}
                                        variant="outlined"
                                        type="text"
                                        onChange={e => setEmail(e.currentTarget.value)}
                                        error={errors && errors.email && errors.email.isError}
                                        helperText={errors && errors.email && errors.email?.message}
                                        fullWidth
                                    />

                                    <Button className="form__submitButton" type="submit" variant="contained" style={{ float: "right" }}>
                                        <h6>{t("next")}</h6>
                                    </Button>
                                </form>
                            </Grid>
                            <Grid item className="register__text" md={4}>
                                <h6>{t("youWillNeed")}:</h6>
                                <br />
                                <h6>{t("yourEmail")}</h6>
                                <br />
                                <h6>{t("5min")}</h6>
                                <p>{t("5minDesc")}</p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </>
        );
    } else if (step === 2) {
        return (
            <>
                <Nav />
                <LoginHero />
                <div className="login__container">
                    <div className="form__container">
                        <h2 className="login__title">{t("registerTitle")}</h2>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <form noValidate onSubmit={handleStep2Submit}>
                                    <h6>
                                        <u>{t("step2")}</u>
                                    </h6>
                                    <br />
                                    <h5>{t("correctEmail")}</h5>
                                    <br />
                                    <h6 style={{ fontWeight: "bold" }}>{email}</h6>

                                    <div style={{ float: "right" }}>
                                        <Button
                                            className="form__backButton"
                                            variant="outlined"
                                            onClick={() => setStep(1)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <h6>{t("back")}</h6>
                                        </Button>

                                        <Button className="form__submitButton" type="submit" variant="contained">
                                            <h6>{t("confirm")}</h6>
                                        </Button>
                                    </div>
                                </form>
                            </Grid>
                            <Grid item className="register__text" md={4}>
                                <h6>{t("youWillNeed")}:</h6>
                                <br />
                                <h6>{t("yourEmail")}</h6>
                                <br />
                                <h6>{t("5min")}</h6>
                                <p>{t("5minDesc")}</p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </>
        );
    } else if (step === 3) {
        return (
            <>
                <Nav />
                <LoginHero />
                <div className="login__container">
                    <div className="form__container">
                        <h2 className="login__title">{t("registerTitle")}</h2>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <form noValidate onSubmit={handleStep3Submit}>
                                    <h6>{t("step3")}</h6>

                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            id="standard-adornment-password"
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            placeholder={t("enterPassword")}
                                            onChange={e => setPassword(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onMouseDown={() => setShowPassword(true)}
                                                        onMouseUp={() => setShowPassword(false)}
                                                    >
                                                        {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            error={errors && errors.password && errors.password.isError}
                                        />
                                    </FormControl>
                                    {errors && errors.password && errors.password.isError && (
                                        <div style={{ paddingLeft: 10, fontSize: 12, color: "#d32f2f" }}>{errors.password?.message}</div>
                                    )}
                                    <div style={{ marginBottom: 10 }}></div>

                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            id="standard-adornment-password"
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={passwordConfirmation}
                                            placeholder={t("confirmPassword")}
                                            onChange={e => setPasswordConfirmation(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onMouseDown={() => setShowConfirmPassword(true)}
                                                        onMouseUp={() => setShowConfirmPassword(false)}
                                                    >
                                                        {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            error={errors && errors.passwordConfirmation && errors.passwordConfirmation.isError}
                                        />
                                    </FormControl>

                                    {errors && errors.passwordConfirmation && errors.passwordConfirmation.isError && (
                                        <div style={{ paddingLeft: 10, fontSize: 12, color: "#d32f2f" }}>{errors.passwordConfirmation?.message}</div>
                                    )}
                                    <div style={{ marginBottom: 10 }}></div>

                                    <div style={{ float: "right" }}>
                                        <Button
                                            className="form__backButton"
                                            variant="outlined"
                                            onClick={() => setStep(2)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <h6>{t("back")}</h6>
                                        </Button>

                                        <Button className="form__submitButton" type="submit" variant="contained">
                                            <h6>{t("next")}</h6>
                                        </Button>
                                    </div>
                                </form>
                            </Grid>
                            <Grid item className="register__text" md={4}>
                                <h6>{t("youWillNeed")}:</h6>
                                <br />
                                <h6>{t("yourEmail")}</h6>
                                <br />
                                <h6>{t("5min")}</h6>
                                <p>{t("5minDesc")}</p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </>
        );
    } else if (step === 4) {
        return (
            <>
                <Nav />
                <LoginHero />
                <div className="login__container">
                    <div className="form__container">
                        <h2 className="login__title">{t("registerTitle")}</h2>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <form noValidate onSubmit={handleStep4Submit}>
                                    <h6>{t("step4")}</h6>

                                    <TextField
                                        margin="normal"
                                        placeholder={t("nickname")}
                                        label={t("nickname")}
                                        value={nickname}
                                        variant="outlined"
                                        type="text"
                                        onChange={e => setNickname(e.currentTarget.value)}
                                        fullWidth
                                        style={{ marginBottom: 10 }}
                                        error={errors && errors.nickname && errors.nickname.isError}
                                        helperText={errors.nickname?.message}
                                    />

                                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                                        <DesktopDatePicker
                                            openTo="year"
                                            disableFuture
                                            views={["year", "month"]}
                                            label={t("dateOfBirth")}
                                            inputFormat="MM-yyyy"
                                            value={dateOfBirth}
                                            onChange={(newValue: any) => {
                                                const date = new Date(newValue);
                                                date.setHours(0, 0, 0, 0);
                                                setDateOfBirth(date);
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField
                                                    fullWidth
                                                    error={errors && errors.dateOfBirth && errors.dateOfBirth.isError}
                                                    helperText={errors && errors.dateOfBirth && errors.dateOfBirth?.message}
                                                    style={{ marginBottom: 10 }}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>

                                    <FormControl fullWidth error={errors.language}>
                                        <InputLabel id="demo-simple-select-error-label">{t("language")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            value={language}
                                            label="Language"
                                            onChange={e => setLanguage(e.target.value)}
                                        >
                                            <MenuItem value="en">English</MenuItem>
                                            <MenuItem value="fr">Français</MenuItem>
                                        </Select>
                                        {errors && errors.language && errors.language.isError && (
                                            <FormHelperText>{errors && errors.language && errors.language?.message}</FormHelperText>
                                        )}
                                    </FormControl>

                                    <div style={{ float: "right" }}>
                                        <Button
                                            className="form__backButton"
                                            variant="outlined"
                                            onClick={() => setStep(3)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <h6>{t("back")}</h6>
                                        </Button>

                                        <Button className="form__submitButton" type="submit" variant="contained" style={{ float: "right" }}>
                                            <h6>{t("register")}</h6>
                                        </Button>
                                    </div>
                                </form>
                            </Grid>
                            <Grid item className="register__text" md={4}>
                                <h6>{t("youWillNeed")}:</h6>
                                <br />
                                <h6>{t("yourEmail")}</h6>
                                <br />
                                <h6>{t("5min")}</h6>
                                <p>{t("5minDesc")}</p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </>
        );
    } else {
        return <></>;
    }
};

export default Register;
