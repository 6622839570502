import React from "react";
import Typography from "@mui/material/Typography";

const Title: React.FC = ({ children }: any) => {
    return (
        <Typography component="h2" variant="h5" gutterBottom style={{ paddingBottom: 10, color: "#000" }}>
            {children}
        </Typography>
    );
};

export default Title;
