import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import Title from "../../../../general/Title";
import TopBarForm from "../TopBarForm";
import InstructionDialog from "../InstructionDialog";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// Hooks
import RadioGroup from "@mui/material/RadioGroup";
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { dirtyValues, removeUndefinedInObject } from "../../../../../utils/general";
import { getScoreByQuestion } from "../../../../../utils/score";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";

const requiredMessage = "Required field";

interface Props {
    // eslint-disable-next-line
    onComplete: (title: string, data: any, points: number, topic: string) => void;
}

const TITLE_FR = "Votre santé cardio-vasculaire";
const TITLE_EN = "Your Vascular Health";
const ABOUT_FR =
    "Ces questions nous aideront à mieux vous connaître et à suivre votre progression. Cela devrait prendre environ 15 minutes. Essayez de terminer le questionnaire en une seule séance.";
const ABOUT_EN =
    "These questions will help us to know you better and see your progress. This should take about 15 minutes. Try to complete this task in one session.";
const INSTRUCTION_FR = "Répondez à chaque question au mieux de vos capacités.";
const INSTRUCTION_EN = "Answer each question to the best of your abilities.";

const VascularHealthForm: React.FC<Props> = ({ onComplete }) => {
    const { t } = useTranslation();
    const { setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [showQuestion3, setShowQuestion3] = useState(false);
    const [showQuestion4, setShowQuestion4] = useState(false);
    const [showQuestion6, setShowQuestion6] = useState(false);
    const [showQuestion7, setShowQuestion7] = useState(false);
    const [showQuestion9, setShowQuestion9] = useState(false);
    const [showQuestion10, setShowQuestion10] = useState(false);
    const [showQuestion12, setShowQuestion12] = useState(false);
    const [showQuestion13, setShowQuestion13] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const generateSchema = () => {
        const output: any = {};
        output.question2 = yup.string().required(requiredMessage);
        if (showQuestion3) output.question3 = yup.string().required(requiredMessage);
        if (showQuestion4) output.question4 = yup.string().required(requiredMessage);
        output.question5 = yup.string().required(requiredMessage);
        if (showQuestion6) output.question6 = yup.string().required(requiredMessage);
        if (showQuestion7) output.question7 = yup.string().required(requiredMessage);
        output.question8 = yup.string().required(requiredMessage);
        if (showQuestion9) output.question9 = yup.string().required(requiredMessage);
        if (showQuestion10) output.question10 = yup.string().required(requiredMessage);
        output.question11 = yup.string().required(requiredMessage);
        if (showQuestion12) output.question12 = yup.string().required(requiredMessage);
        if (showQuestion13) output.question13 = yup.string().required(requiredMessage);

        return yup.object(output);
    };

    const generateDefaultValues = () => {
        const output: any = {};
        output.question2 = "";
        if (showQuestion3) output.question3 = "";
        if (showQuestion4) output.question4 = "";
        output.question5 = "";
        if (showQuestion6) output.question6 = "";
        if (showQuestion7) output.question7 = "";
        output.question8 = "";
        if (showQuestion9) output.question9 = "";
        if (showQuestion10) output.question10 = "";
        output.question11 = "";
        if (showQuestion12) output.question12 = "";
        if (showQuestion13) output.question13 = "";

        return output;
    };

    // Forms
    const {
        reset,
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
    } = useForm({
        resolver: yupResolver(generateSchema()),
        defaultValues: generateDefaultValues(),
    });

    const onSubmit = async (data: any) => {
        try {
            let points = 0;
            data = removeUndefinedInObject(dirtyValues(dirtyFields, data));
            setLoading(true);

            Object.entries(dirtyValues(dirtyFields, data)).forEach(e => {
                const localPoints = getScoreByQuestion(
                    "Vascular Health",
                    parseInt((e[0] as string).replace("question", "")),
                    dirtyValues(dirtyFields, data)
                );
                if (typeof localPoints !== "undefined" && localPoints !== "n/a") points += localPoints as number;
            });

            enqueueSnackbar(t("questionnaireCompleted", { title: localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR }), {
                variant: "success",
            });
            onComplete(TITLE_EN, dirtyValues(dirtyFields, data), points, "Vascular Health");
            window.scrollTo(0, 0);
            setIsSafeToReset(true);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const id = Object.entries(errors)[0][0];
            const element = document.getElementById(id);

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    useEffect(() => {
        if (isSafeToReset) reset();
    }, [isSafeToReset]);

    return (
        <>
            <TopBarForm />
            <div className="forms__container">
                <Title>{localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR}</Title>

                <div className="forms__section">
                    <h6 className="forms__subtitle">{localStorage.getItem("language") === "en" ? "About" : "À propos"}</h6>
                    <p>{localStorage.getItem("language") === "en" ? ABOUT_EN : ABOUT_FR}</p>
                </div>

                <div className="forms__section">
                    <h6 className="forms__subtitle">Instructions</h6>
                    <p>{localStorage.getItem("language") === "en" ? INSTRUCTION_EN : INSTRUCTION_FR}</p>
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e: any) => {
                        // Disable submit on "enter" key press
                        e.key === "Enter" && e.preventDefault();
                    }}
                    className="forms__content"
                >
                    <Grid item>
                        <div>
                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "The following questions are about your own risk for heart disease and stroke. If you have questions about your risk factors for heart disease and stroke, please ask your family doctor."
                                        : "Les questions suivantes portent sur votre risque face aux maladies cardiaques et accidents vasculaires cérébraux. Si vous avez des questions à propos de votre risque aux maladies cardiovasculaires et cérébrovasculaires, veuillez vous adresser à votre médecin de famille."}
                                </h6>
                            </div>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question2"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question2"
                                            component="fieldset"
                                            error={!!errors.question2?.message}
                                            {...field}
                                            onChange={(e: any) => {
                                                field.onChange(e);
                                                setShowQuestion3(e.target.value === "No" ? false : true);
                                                setShowQuestion4(e.target.value === "No" ? false : true);
                                            }}
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "Do you have high blood pressure?"
                                                    : "Faites-vous de l’hypertension?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                />
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question2?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            {showQuestion3 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question3"
                                        render={({ field }) => (
                                            <FormControl
                                                id="question3"
                                                component="fieldset"
                                                error={!!errors.question3?.message}
                                                {...field}
                                                onChange={(e: any) => {
                                                    field.onChange(e);
                                                    setShowQuestion4(e.target.value === "No" ? false : true);
                                                }}
                                            >
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "Have you been prescribed a medication or diet to help lower your blood pressure?"
                                                        : "Vous a-t-on prescrit des médicaments ou un régime alimentaire pour vous aider à réduire votre pression artérielle?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                                <FormHelperText style={{ fontSize: 20 }}>{errors.question3?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}

                            {showQuestion4 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question4"
                                        render={({ field }) => (
                                            <FormControl id="question4" component="fieldset" error={!!errors.question4?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the past 7 days, have you followed the dose or diet prescribed?"
                                                        : "Au cours des 7 derniers jours, avez-vous suivi la dose ou le régime prescrit?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                                <FormHelperText style={{ fontSize: 20 }}>{errors.question4?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question5"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question5"
                                            component="fieldset"
                                            error={!!errors.question5?.message}
                                            {...field}
                                            onChange={(e: any) => {
                                                field.onChange(e);
                                                setShowQuestion6(e.target.value === "No" ? false : true);
                                                setShowQuestion7(e.target.value === "No" ? false : true);
                                            }}
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "Do you have high cholesterol?"
                                                    : "Avez-vous un taux de cholestérol élevé?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                />
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question5?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            {showQuestion6 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question6"
                                        render={({ field }) => (
                                            <FormControl
                                                id="question6"
                                                component="fieldset"
                                                error={!!errors.question6?.message}
                                                {...field}
                                                onChange={(e: any) => {
                                                    field.onChange(e);
                                                    setShowQuestion7(e.target.value === "No" ? false : true);
                                                }}
                                            >
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "Have you been prescribed medication or diet to help lower your cholesterol?"
                                                        : "Vous a-t-on prescrit des médicaments ou un régime alimentaire pour vous aider à réduire votre taux de cholestérol?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                                <FormHelperText style={{ fontSize: 20 }}>{errors.question6?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}
                            {showQuestion7 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question7"
                                        render={({ field }) => (
                                            <FormControl id="question7" component="fieldset" error={!!errors.question7?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the past 7 days, have you followed the dose or diet prescribed?"
                                                        : "Au cours des 7 derniers jours, avez-vous suivi la dose ou le régime prescrit?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                                <FormHelperText style={{ fontSize: 20 }}>{errors.question7?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question8"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question8"
                                            component="fieldset"
                                            error={!!errors.question8?.message}
                                            {...field}
                                            onChange={(e: any) => {
                                                field.onChange(e);
                                                setShowQuestion9(e.target.value === "No" ? false : true);
                                            }}
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en" ? "Do you have diabetes?" : "Faites-vous du diabète?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                />
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question8?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            {showQuestion9 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question9"
                                        render={({ field }) => (
                                            <FormControl
                                                id="question9"
                                                component="fieldset"
                                                error={!!errors.question9?.message}
                                                {...field}
                                                onChange={(e: any) => {
                                                    field.onChange(e);
                                                    setShowQuestion10(e.target.value === "No" ? false : true);
                                                }}
                                            >
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "If yes, have you been prescribed medication or diet to help control your diabetes?"
                                                        : "Vous a-t-on prescrit des médicaments ou un régime alimentaire pour vous aider à contrôler votre diabète?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                                <FormHelperText style={{ fontSize: 20 }}>{errors.question9?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}
                            {showQuestion10 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question10"
                                        render={({ field }) => (
                                            <FormControl id="question10" component="fieldset" error={!!errors.question10?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the past 7 days, have you followed the dose or diet prescribed?"
                                                        : "Au cours des 7 derniers jours, avez-vous suivi la dose ou le régime prescrit?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                                <FormHelperText style={{ fontSize: 20 }}>{errors.question10?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="question11"
                                    render={({ field }) => (
                                        <FormControl
                                            id="question11"
                                            component="fieldset"
                                            error={!!errors.question11?.message}
                                            {...field}
                                            onChange={(e: any) => {
                                                field.onChange(e);
                                                setShowQuestion12(e.target.value === "No" ? false : true);
                                                setShowQuestion13(e.target.value === "No" ? false : true);
                                            }}
                                        >
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en" ? "Do you smoke?" : "Est-ce que vous fumez?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                />
                                                <FormControlLabel
                                                    value="No"
                                                    control={<Radio />}
                                                    label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                />
                                            </RadioGroup>
                                            <FormHelperText style={{ fontSize: 20 }}>{errors.question11?.message}</FormHelperText>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            {showQuestion12 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question12"
                                        render={({ field }) => (
                                            <FormControl id="question12" component="fieldset" error={!!errors.question12?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "How many cigarettes, pipes, or cigars on average per week?"
                                                        : "Combien de cigarettes, cigares ou pipes fumez-vous en moyenne par semaine?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["input"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <TextField
                                                        className="forms__numberInput"
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, max: 999 } }}
                                                        variant="standard"
                                                        helperText={errors.question12?.message}
                                                        error={!!errors.question12?.message}
                                                        label={localStorage.getItem("language") === "en" ? "Per week" : "Par semaine"}
                                                        {...field}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}
                            {showQuestion13 && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="question13"
                                        render={({ field }) => (
                                            <FormControl id="question13" component="fieldset" error={!!errors.question13?.message} {...field}>
                                                <FormLabel component="legend">
                                                    {localStorage.getItem("language") === "en"
                                                        ? "During the last 7 days, have you taken steps to reduce the amount you smoke or to quit smoking?"
                                                        : "Au cours des 7 derniers jours, avez-vous pris des mesures pour réduire la quantité de cigarettes, cigares ou pipes que vous fumez ou pour arrêter de fumer ?"}
                                                    <IconButton
                                                        color="primary"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedTypes(["radio"]);
                                                            setDialogOpen(true);
                                                        }}
                                                    >
                                                        <GrCircleInformation />
                                                    </IconButton>
                                                </FormLabel>
                                                <RadioGroup row>
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                    />
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio />}
                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            )}
                        </div>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" className="questionnaires__cta" fullWidth>
                            {localStorage.getItem("language") === "en" ? "Submit" : "Soumettre"} <MdKeyboardArrowRight />
                        </Button>
                    </Grid>
                    <div id="spacer_for_button" style={{ minHeight: 30 }} />
                </form>
            </div>
            <InstructionDialog open={dialogOpen} setOpen={setDialogOpen} types={selectedTypes} />
        </>
    );
};

export default VascularHealthForm;
