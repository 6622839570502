import React from "react";
import Box from "@mui/material/Box";

interface Props {
    children: any;
    value: any;
    index: any;
    other?: any;
}

export const TabPanel: React.FC<Props> = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
