import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { functions } from "../../../firebase/firebase";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { httpsCallable } from "firebase/functions";
import { useLocation } from "react-router-dom";

const SubDomainRedirection = () => {
    const query = useQuery();
    const { currentParticipant } = useFetchUser();
    const { pathname } = useLocation();

    // redirect to syn2 if pscid includes syn2
    useEffect(() => {
        const syn2Redirection = async () => {
            if (
                currentParticipant?.id &&
                currentParticipant.pscid &&
                currentParticipant.pscid.includes("syn2") &&
                !window.location.href.includes("completed/")
            ) {
                const tokenQuery = query.get("token");
                if (!tokenQuery) {
                    const createCustomToken = httpsCallable(functions, "createCustomToken");
                    const tokenPayload = await createCustomToken({ uid: currentParticipant.id });

                    window.location.href = `https://syn2.brainhealthpro.ca/?token=${tokenPayload.data}`;
                }
            }
        };
        syn2Redirection();
    }, [currentParticipant, pathname]);

    return <></>;
};

export default SubDomainRedirection;
