import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

// Utils
import { formatDateTime, getMillisecondsFrom2Dates, getTimeBetween2Dates, getTotalTimeFromMilliseconds } from "../../../../utils/date";
import settings from "../../../../settings.json";

// Custom components
import SearchBar from "../../../general/SearchBar";
import { exportAOADocument } from "../../../../utils/xlsx";

export const SpecificTracking: React.FC = () => {
    const { id } = useParams<any>();
    const { currentAdmin } = useFetchUser();
    const participantRequest = useDb<Participant>("Participants", currentAdmin);
    const metadataRequest = useDb<any>("Metadata", currentAdmin);

    // States
    const [participant, setParticipant] = useState<Participant>();
    const [selection, setSelection] = useState<number>(0);
    const [visitedTopics, setVisitedTopics] = useState<number>(0);
    const [completedTopics, setCompletedTopics] = useState<number>(0);
    const [completionPercentage, setCompletionPercentage] = useState<number>(0);
    const [websiteVisits, setWebsiteVisits] = useState<number>(0);
    const [websiteTimeSpent, setWebsiteTimeSpent] = useState<string>("");

    const fetchParticipant = async () => {
        setParticipant((await participantRequest.getById(id)) as Participant);
    };

    const getPercentCompletion = () => {
        if (participant && participant.completedChapters && participant.unlockedChapters) {
            let totalUnlockedChapters = 0;
            let totalCompletedChapters = 0;

            Object.values(participant.unlockedChapters).map(unlockedChapters => {
                if (unlockedChapters.length > 0) {
                    totalUnlockedChapters += unlockedChapters.length;
                }
            });

            Object.values(participant.completedChapters).map(completedChapters => {
                if (completedChapters.length > 0) {
                    totalCompletedChapters += completedChapters.length;
                }
            });

            return (totalCompletedChapters / totalUnlockedChapters) * 100;
        }
        return 0;
    };

    const formatData = () => {
        if (participant && participant.uses && participant.uses.length) {
            if (participant.pscid !== "test" && participant.pscid !== "pilot") {
                const dataArray: any[] = [];
                dataArray.push(["Date", "Time of use", "Chapters visited", "Chapters completed", "Completion %"]);
                participant.uses.forEach((u: any) => {
                    dataArray.push([
                        formatDateTime(u.start),
                        u.start && u.end ? getTimeBetween2Dates(u.end, u.start) : "0 second",
                        u.chaptersVisited ? u.chaptersVisited.length : 0,
                        u.chaptersCompleted ? u.chaptersCompleted.length : 0,
                        `${getPercentCompletion()} %`,
                    ]);
                });
                dataArray.push([]);
                dataArray.push(["Total time of use"]);
                dataArray.push([websiteTimeSpent]);

                return dataArray;
            } else return [];
        }
    };

    const exportToXLSX = async () => {
        if (participant && participant.uses && participant.uses.length) {
            const fileName = `specific_tracking_${moment(new Date()).format("DD-MM-YYYY")}`;
            const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
            exportAOADocument(formatData(), fileName, sheetName);
        }
    };

    const fetchWebsiteTimeSpent = () => {
        if (participant && participant.uses && participant.uses.length) {
            let ms = 0;
            Object.entries(participant.uses).map(u => {
                if (u[1] && u[1].start && u[1].end) {
                    ms += getMillisecondsFrom2Dates(u[1].end, u[1].start);
                }
            });

            setWebsiteTimeSpent(getTotalTimeFromMilliseconds(ms));
        }
    };

    const fetchWebsiteVisits = () => {
        if (participant && participant.uses && participant.uses.length) {
            let count = 0;
            Object.entries(participant.uses).map(u => {
                if (u[1] && u[1].start && u[1].end) count += 1;
            });
            setWebsiteVisits(count);
        }
    };

    const fetchChapterCount = async () => {
        const payload = await metadataRequest.getById("Chapters");
        setCompletionPercentage(completedTopics / (payload as any).count);
    };

    const fetchCompletedTopics = () => {
        if (participant && participant.uses && participant.uses.length) {
            let count = 0;
            Object.entries(participant.uses).map(u => {
                if (u[1] && u[1].chaptersCompleted) count += u[1].chaptersCompleted.length;
            });
            setCompletedTopics(count);
        }
    };

    const fetchVisitedTopics = () => {
        if (participant && participant.uses && participant.uses.length) {
            let count = 0;
            Object.entries(participant.uses).map(u => {
                if (u[1] && u[1].chaptersVisited) count += u[1].chaptersVisited.length;
            });
            setVisitedTopics(count);
        }
    };

    useEffect(() => {
        if (participant && participant.uses && participant.uses.length) {
            fetchVisitedTopics();
            fetchCompletedTopics();
            fetchChapterCount();
            fetchWebsiteVisits();
            fetchWebsiteTimeSpent();
        }
    }, [participant]);

    useEffect(() => {
        if (id) fetchParticipant();
    }, [id]);

    if (!selection) {
        return (
            <>
                <Grid item container>
                    <Grid item xs={10}>
                        <SearchBar title={`Tracking ${participant?.nickname} (${participant?.email})`} />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={() => exportToXLSX()} style={{ float: "right", marginTop: 20 }}>
                            Export
                        </Button>
                    </Grid>
                </Grid>

                <h5>Statistics</h5>
                <ul>
                    <li onClick={() => setSelection(1)} style={{ cursor: "pointer", textDecoration: "underline" }}>
                        Date and time of program use
                    </li>
                    <li>
                        Number of website visits: <span style={{ fontWeight: "bold" }}>{websiteVisits}</span>
                    </li>
                    <li>
                        Number of chapter visited: <span style={{ fontWeight: "bold" }}>{visitedTopics}</span>
                    </li>
                    <li>
                        Number of chapter completed: <span style={{ fontWeight: "bold" }}>{completedTopics}</span>
                    </li>
                    <li>
                        Total time of use: <span style={{ fontWeight: "bold" }}>{websiteTimeSpent}</span>
                    </li>
                    <li>
                        Percent completion of program assessments: <span style={{ fontWeight: "bold" }}>{completionPercentage.toPrecision(1)}%</span>
                    </li>
                </ul>
            </>
        );
    } else if (selection === 1) {
        return (
            <>
                <SearchBar title={`Tracking ${participant?.nickname} (${participant?.email})`} />
                <h5>Date and time of program use</h5>
                <br />
                {participant && participant.uses && participant.uses.length ? (
                    participant.uses.map((u, i) => {
                        return (
                            <React.Fragment key={i}>
                                {u.start && u.end && (
                                    <div style={{ paddingBottom: 10 }}>
                                        <div style={{ fontWeight: "bold" }}>{moment(u.start).format("DD-MM-YYYY hh:mm:ss")}</div>
                                        <div>{getTimeBetween2Dates(u.end, u.start)}</div>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <p>No uses</p>
                )}
                <Button variant="contained" onClick={() => setSelection(0)} style={{ marginTop: 20 }}>
                    Back
                </Button>
            </>
        );
    } else if (selection === 2) {
        return (
            <>
                <SearchBar title={`Tracking ${participant?.nickname} (${participant?.email})`} />
                <h5>Time of topic completion</h5>
                <br />

                <ul>
                    {settings.app.topics.map((t, i) => {
                        return <li key={i}>{t}: ...</li>;
                    })}
                </ul>

                <Button variant="contained" onClick={() => setSelection(0)} style={{ marginTop: 20 }}>
                    Back
                </Button>
            </>
        );
    } else {
        return <SearchBar title={`Tracking ${participant?.nickname} (${participant?.email})`} />;
    }
};

export default SpecificTracking;
