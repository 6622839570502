import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Mui
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

// Icons
import { MdArrowDropDown } from "react-icons/md";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";
import { Chapter } from "../../../../interfaces/Chapter";

// Custom components
import ParticipantTable from "../../../general/SimpleTable";
import SearchBar from "../../../general/SearchBar";
import ParticipantsTableRows from "./TrackingTableRows";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Utils
import { getChapterCompletionDate, getChapterVisitedCount, isChapterFavorited } from "../../../../utils/chapter";
import { exportAOADocumentWithMultipleTabs } from "../../../../utils/xlsx";

const COLLECTION = "Participants";
const options = ["Completed Chapters", "Favorite Chapters", "Reviewed Chapters"];

const Tracking: React.FC = () => {
    const hist = useHistory();
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { firstFetch, fetchNextPage, fetchLastPage, getAll } = useDb<Participant>(COLLECTION, currentAdmin);
    const { onSnapshotWithDoc } = useDb<any>("", currentAdmin, null, "Metadata/Participants");
    const chapterRequest = useDb<Chapter>("Chapters", currentAdmin);
    const anchorRef = useRef(null);

    // States
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [allParticipants, setAllParticipants] = useState<Participant[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [exportOpen, setExportOpen] = useState(false);
    const [selectedExportIndex, setSelectedExportIndex] = useState(0);
    const [chapters, setChapters] = useState<Chapter[]>([]);

    const fetchAllParticipants = async () => {
        setAllParticipants((await getAll({})) as Participant[]);
    };

    const handleNextPage = async (item: any) => {
        if (participants.length !== 0) {
            setLoading(true);
            await fetchNextPage(item, setParticipants, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
            setLoading(false);
        }
    };

    const handlePreviousPage = async (item: any) => {
        setLoading(true);
        await fetchLastPage(item, setParticipants, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
        setLoading(false);
    };

    const fetchCount = async () => {
        try {
            return onSnapshotWithDoc((data: any) => {
                setLoading(true);
                if (data) setCount(data.count);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        if (page < newPage) handleNextPage(participants[participants.length - 1]);
        else if (page > newPage) handlePreviousPage(participants[0]);

        setPage(newPage);
    };

    const fetchFirstUsers = async () => {
        await firstFetch(setParticipants, (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) ?? undefined);
    };

    const fetchChapters = async () => {
        setChapters((await chapterRequest.getAll({})) as Chapter[]);
    };

    const handleDoubleClick = (row: any) => {
        hist.push(`/dashboard/specificTracking/${row.id}`);
    };

    const exportCompletedChaptersData = async () => {
        setLoading(true);

        const fileName = `completed_chapters_${moment(new Date()).format("DD-MM-YYYY")}`;
        const dataArray: any[] = settings.app.topics.map(t => formatCompletedChaptersDataByTopic(t));
        exportAOADocumentWithMultipleTabs(dataArray, fileName, settings.app.topics);

        setLoading(false);
    };

    const exportFavoriteChaptersData = async () => {
        setLoading(true);

        const fileName = `favorite_chapters_${moment(new Date()).format("DD-MM-YYYY")}`;
        const dataArray: any[] = settings.app.topics.map(t => formatFavoriteChaptersDataByTopic(t));
        exportAOADocumentWithMultipleTabs(dataArray, fileName, settings.app.topics);

        setLoading(false);
    };

    const exportReviewedChaptersData = async () => {
        setLoading(true);

        const fileName = `reviewed_chapters_${moment(new Date()).format("DD-MM-YYYY")}`;
        const dataArray: any[] = settings.app.topics.map(t => formatReviewedChaptersDataByTopic(t));
        exportAOADocumentWithMultipleTabs(dataArray, fileName, settings.app.topics);

        setLoading(false);
    };

    const formatCompletedChaptersDataByTopic = (topic: string) => {
        const dataArray: any[] = [];
        if (allParticipants && allParticipants.length) {
            const chapterArray = [];

            chapterArray.push("PSCID");

            for (let i = 1; i <= chapters.filter(c => c.topic === topic).length; i++) {
                chapterArray.push(`Chapter ${i}`);
            }

            dataArray.push(chapterArray);

            for (const p of allParticipants) {
                if (p.pscid && p.pscid !== "test" && p.pscid !== "pilot") {
                    const participantArray = [];

                    participantArray.push(p.pscid);

                    for (let i = 1; i <= chapters.filter(c => c.topic === topic).length; i++) {
                        const chapter = chapters.filter(c => c.topic === topic && c.chapter === i)[0];

                        if (chapter && chapter.id) {
                            const date = getChapterCompletionDate(chapter.id, p);

                            if (date) participantArray.push(moment(date).format("DD/MM/YYYY"));
                            else participantArray.push("Not completed");
                        }
                    }

                    dataArray.push(participantArray);
                }
            }
        }
        return dataArray;
    };

    const formatFavoriteChaptersDataByTopic = (topic: string) => {
        const dataArray: any[] = [];
        if (allParticipants && allParticipants.length) {
            const chapterArray = [];

            chapterArray.push("PSCID");

            for (let i = 1; i <= chapters.filter(c => c.topic === topic).length; i++) {
                chapterArray.push(`Chapter ${i}`);
            }

            dataArray.push(chapterArray);

            for (const p of allParticipants) {
                if (p.pscid) {
                    const participantArray = [];

                    participantArray.push(p.pscid);

                    for (let i = 1; i <= chapters.filter(c => c.topic === topic).length; i++) {
                        const chapter = chapters.filter(c => c.topic === topic && c.chapter === i)[0];

                        if (chapter && chapter.id) {
                            if (isChapterFavorited(chapter.id, p)) participantArray.push("yes");
                            else participantArray.push("no");
                        }
                    }

                    dataArray.push(participantArray);
                }
            }
        }
        return dataArray;
    };

    const formatReviewedChaptersDataByTopic = (topic: string) => {
        const dataArray: any[] = [];
        if (allParticipants && allParticipants.length) {
            const chapterArray = [];

            chapterArray.push("PSCID");

            for (let i = 1; i <= chapters.filter(c => c.topic === topic).length; i++) {
                chapterArray.push(`Chapter ${i}`);
            }

            dataArray.push(chapterArray);

            for (const p of allParticipants) {
                if (p.pscid) {
                    const participantArray = [];

                    participantArray.push(p.pscid);

                    for (let i = 1; i <= chapters.filter(c => c.topic === topic).length; i++) {
                        const chapter = chapters.filter(c => c.topic === topic && c.chapter === i)[0];

                        if (chapter && chapter.id) {
                            participantArray.push(getChapterVisitedCount(chapter.id, p));
                        }
                    }

                    dataArray.push(participantArray);
                }
            }
        }
        return dataArray;
    };

    const handleExportMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedExportIndex(index);
        setExportOpen(false);
    };

    const handleExportClick = async () => {
        switch (options[selectedExportIndex]) {
            case "Completed Chapters":
            default:
                return exportCompletedChaptersData();
            case "Favorite Chapters":
                return exportFavoriteChaptersData();
            case "Reviewed Chapters":
                return exportReviewedChaptersData();
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchCount();
        fetchFirstUsers();
        fetchAllParticipants();
        fetchChapters();
        setLoading(false);
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={9}>
                    <SearchBar title="Tracking" />
                </Grid>

                <Grid item xs={3}>
                    <ButtonGroup style={{ marginTop: 20, float: "right" }} variant="contained" ref={anchorRef} aria-label="split button">
                        <Button onClick={handleExportClick}>{options[selectedExportIndex]}</Button>
                        <Button
                            size="small"
                            aria-controls={exportOpen ? "split-button-menu" : undefined}
                            aria-expanded={exportOpen ? "true" : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={() => setExportOpen(prevOpen => !prevOpen)}
                        >
                            <MdArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    <Popper className="notebook__popper" open={exportOpen} anchorEl={anchorRef.current} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => setExportOpen(false)}>
                                        <MenuList id="split-button-menu" defaultValue={options[0]}>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedExportIndex}
                                                    onClick={event => handleExportMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>

            <ParticipantTable
                loading={loading}
                data={participants}
                count={count}
                rows={ParticipantsTableRows()}
                noMoreData={participants.length <= count}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onChangePage={handlePageChange}
                onDoubleClick={handleDoubleClick}
            />
        </>
    );
};

export default Tracking;
