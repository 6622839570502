import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import useDb from "../../../../hooks/useDb";

// Settings
import settings from "../../../../settings.json";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Interfaces
import { Chapter as ChapterInterface } from "../../../../interfaces/Chapter";
import { useParams } from "react-router";

const COLLECTION = "Chapters";

// yup validation
const requiredMessage = "Required field";

const chapterSchema = yup.object({
    titleFR: yup.string().required(requiredMessage),
    titleEN: yup.string().required(requiredMessage),
    linkFR: yup.string().required(requiredMessage),
    linkEN: yup.string().required(requiredMessage),
    chapter: yup.number().required(requiredMessage),
    topic: yup.string().required(requiredMessage),
});

interface paramsType {
    id: string;
}

const Chapter: React.FC = () => {
    const isCancelled = useRef(false);
    const { id } = useParams<paramsType>();
    const { enqueueSnackbar } = useSnackbar();
    const { currentAdmin, setLoading } = useFetchUser();
    const { updateDoc, getById } = useDb<ChapterInterface>(COLLECTION, currentAdmin);

    // States
    const [chapter, setChapter] = useState<ChapterInterface | null>(null);

    const defaultValues = {
        titleFR: "",
        titleEN: "",
        linkFR: "",
        linkEN: "",
        chapter: 0,
        topic: "",
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(chapterSchema), defaultValues });

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            await updateDoc({ ...chapter, ...data, chapter: parseInt(data.chapter) });

            enqueueSnackbar("Chapter modified", { variant: "success" });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchChapter = async () => {
        if (!isCancelled.current) {
            const localChapter = await getById(id);
            setChapter(localChapter as ChapterInterface);
        }
    };

    useEffect(() => {
        fetchChapter();
        return () => {
            isCancelled.current = true;
        };
    }, []);

    useEffect(() => {
        if (chapter) {
            setValue("titleFR", chapter.titleFR);
            setValue("titleEN", chapter.titleEN);
            setValue("linkFR", chapter.linkFR);
            setValue("linkEN", chapter.linkEN);
            setValue("chapter", chapter.chapter);
            setValue("topic", chapter.topic);
        }
    }, [chapter]);

    return (
        <span className="chapter__container">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} style={{ minHeight: 400 }}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.titleFR?.message}
                            error={!!errors.titleFR?.message}
                            label="French title"
                            fullWidth
                            {...field}
                        />
                    )}
                    name="titleFR"
                    control={control}
                />

                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.titleEN?.message}
                            error={!!errors.titleEN?.message}
                            label="English title"
                            fullWidth
                            {...field}
                        />
                    )}
                    name="titleEN"
                    control={control}
                />

                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.linkFR?.message}
                            error={!!errors.linkFR?.message}
                            label="French link"
                            fullWidth
                            {...field}
                        />
                    )}
                    name="linkFR"
                    control={control}
                />

                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.linkEN?.message}
                            error={!!errors.linkEN?.message}
                            label="English link"
                            fullWidth
                            {...field}
                        />
                    )}
                    name="linkEN"
                    control={control}
                />

                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.chapter?.message}
                            error={!!errors.chapter?.message}
                            label="Chapter Number"
                            fullWidth
                            {...field}
                        />
                    )}
                    name="chapter"
                    control={control}
                />

                <Controller
                    name="topic"
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth variant="standard" error={!!errors.topic?.message}>
                            <InputLabel id="roles-id">Topic</InputLabel>
                            <Select
                                labelId="roles-id"
                                {...field}
                                inputProps={{ readOnly: currentAdmin && currentAdmin.roles.includes(settings.app.highestRole) ? false : true }}
                            >
                                {settings.app.topics.map((r: string) => (
                                    <MenuItem key={r} value={r}>
                                        {r}
                                    </MenuItem>
                                ))}
                            </Select>
                            {!!errors.topic?.message && <FormHelperText>{errors.topic?.message}</FormHelperText>}
                        </FormControl>
                    )}
                />

                <Button variant="contained" type="submit" color="primary" className="d-block mr-0 ml-auto">
                    Modify
                </Button>
            </form>
        </span>
    );
};

export default Chapter;
