import { createTheme } from "@mui/material/styles";
import styles from "./scss/parts/_exports.module.scss";

const theme = createTheme({
    palette: {
        primary: {
            main: styles.primary,
        },
        secondary: {
            main: styles.secondary,
        },
    },
});

export default theme;
