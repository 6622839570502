import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// React-router-dom
import { useParams } from "react-router-dom";

// Components
import Title from "../../general/Title";
import GaugeCard from "../../general/GaugeCard";

// MUI
import Grid from "@mui/material/Grid";

// Interfaces
import { Stat, StatTopic } from "../../../interfaces/Stat";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";

// Utils
import { getTitleFromTopic } from "../../../utils/topic";

interface paramsType {
    topic: StatTopic;
}

const Metric: React.FC = () => {
    const { currentParticipant } = useFetchUser();
    const { topic } = useParams<paramsType>();
    const { getAll } = useDb<Stat>("Stats", null, currentParticipant);
    const { t } = useTranslation();

    // States
    const [stage1, setStage1] = useState<Stat | null>(null);
    const [stage2, setStage2] = useState<Stat | null>(null);
    const [stage3, setStage3] = useState<Stat | null>(null);
    const [stage4, setStage4] = useState<Stat | null>(null);
    const [stage5, setStage5] = useState<Stat | null>(null);
    const [currentStageNumber, setCurrentStageNumber] = useState(0);
    const [fetchingState, setFetchingState] = useState("not fetching");

    // eslint-disable-next-line
    const fetchStage = async (participantId: string, stage: string, callback: (stage: Stat) => void) => {
        const stats = await getAll({
            filters: [
                { field: "topic", operator: "==", value: topic },
                { field: "participantId", operator: "==", value: participantId },
                { field: "stage", operator: "==", value: stage },
            ],
        });
        callback(stats[0] as Stat);
    };

    const fetchStages = async () => {
        if (currentParticipant && currentParticipant.id) {
            setFetchingState("starting");
            if (currentParticipant.stages.stage1) await fetchStage(currentParticipant.id, "stage1", setStage1);
            if (currentParticipant.stages.stage2) await fetchStage(currentParticipant.id, "stage2", setStage2);
            if (currentParticipant.stages.stage3) await fetchStage(currentParticipant.id, "stage3", setStage3);
            if (currentParticipant.stages.stage4) await fetchStage(currentParticipant.id, "stage4", setStage4);
            if (currentParticipant.stages.stage5) await fetchStage(currentParticipant.id, "stage5", setStage5);
            setFetchingState("completed");
        }
    };

    const getLatestStat = () => {
        if (stage5) setCurrentStageNumber(5);
        else {
            if (stage4) setCurrentStageNumber(4);
            else {
                if (stage3) setCurrentStageNumber(3);
                else {
                    if (stage2) setCurrentStageNumber(2);
                    else setCurrentStageNumber(1);
                }
            }
        }
    };

    const displayGauges = () => {
        switch (currentStageNumber) {
            case 1:
            default:
                return (
                    <>
                        {displayGauge(1, stage1, true)}
                        {displayGauge(2, stage2)}
                        {displayGauge(3, stage3)}
                        {displayGauge(4, stage4)}
                        {displayGauge(5, stage5)}
                    </>
                );
            case 2:
                return (
                    <>
                        {displayGauge(2, stage2, true)}
                        {displayGauge(1, stage1)}
                        {displayGauge(3, stage3)}
                        {displayGauge(4, stage4)}
                        {displayGauge(5, stage5)}
                    </>
                );
            case 3:
                return (
                    <>
                        {displayGauge(3, stage3, true)}
                        {displayGauge(1, stage1)}
                        {displayGauge(2, stage2)}
                        {displayGauge(4, stage4)}
                        {displayGauge(5, stage5)}
                    </>
                );
            case 4:
                return (
                    <>
                        {displayGauge(4, stage4, true)}
                        {displayGauge(1, stage1)}
                        {displayGauge(2, stage2)}
                        {displayGauge(3, stage3)}
                        {displayGauge(5, stage5)}
                    </>
                );
            case 5:
                return (
                    <>
                        {displayGauge(5, stage5, true)}
                        {displayGauge(1, stage1)}
                        {displayGauge(2, stage2)}
                        {displayGauge(3, stage3)}
                        {displayGauge(4, stage4)}
                    </>
                );
        }
    };

    const displayGauge = (stageNumber: number, stage: Stat | null, isCurrent = false) => {
        if (currentParticipant && (currentParticipant as any).stages[`stage${stageNumber}`] && stage) {
            return (
                <Grid item xs={4}>
                    <GaugeCard
                        topic={stage.topic}
                        score={stage.score}
                        title={
                            isCurrent
                                ? `${t(`stage${stageNumber.toString()}Title`)} (${t("current")})`
                                : `${t(`stage${stageNumber.toString()}Title`)}`
                        }
                    />
                </Grid>
            );
        } else return <></>;
    };

    useEffect(() => {
        if (currentParticipant && fetchingState === "completed") {
            getLatestStat();
        }
    }, [stage1, currentParticipant, fetchingState]);

    useEffect(() => {
        fetchStages();
    }, [currentParticipant]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Title>{getTitleFromTopic(topic)}</Title>
                    <div className="profile__summary">
                        <h6>{t("profileDesc1")}</h6>
                    </div>
                </Grid>

                {displayGauges()}
            </Grid>
        </>
    );
};

export default Metric;
