import React from "react";

// Mui components
import Typography from "@mui/material/Typography";

interface Props {
    title: string;
    searchFields?: any[];
    hideSearch?: boolean;
}

const SearchBar: React.FC<Props> = ({ title }) => {
    return (
        <div className="searchbar__container">
            <Typography className="searchbar__title" variant="h5" gutterBottom color="primary">
                {title}
            </Typography>
        </div>
    );
};

export default SearchBar;
