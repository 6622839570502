import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Assets
import logo from "../../assets/footer/BHP.png";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="footer">
            <div className="footer__container content__spacing">
                <div className="footer__content">
                    <div className="footer__links">
                        <div className="footer__links__col1">
                            <Link to="/">
                                <h5>{t("home")}</h5>
                            </Link>
                            <Link to="/register">
                                <h5>{t("register")}</h5>
                            </Link>
                            <Link to="/login">
                                <h5>{t("login")}</h5>
                            </Link>
                        </div>
                        <div className="footer__links__col2">
                            <Link to="/accountHelp">
                                <h5>{t("contact")}</h5>
                            </Link>
                            <Link to="/dashboard">
                                <h5>{t("admin")}</h5>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="footer__logo nofilterimg">
                    <Link to="/">
                        <img src={logo} alt="" />
                    </Link>
                </div>
            </div>
            <div className="footer__legals">
                <p>© 2020 CRIUGM CIUSSS-CSMTL – ALL RIGHTS RESERVED</p>
            </div>
        </div>
    );
};

export default Footer;
