import i18n from "../utils/i18n";

// Route props
import { CustomRouteProps } from "./route";

// Components
import Login from "../components/pages/portal/Login";
import Home from "../components/pages/portal/Home";
import Chapter from "../components/pages/portal/Chapter";
import Help from "../components/pages/portal/Help";
import Landing from "../components/pages/Landing";
import Register from "../components/pages/portal/Register";
import Profile from "../components/pages/portal/Metrics";
import Topics from "../components/pages/portal/Topics";
import Topic from "../components/pages/portal/Topic";
import TopicsFavourited from "../components/pages/portal/TopicsFavourited";
import Notebook from "../components/pages/portal/notebook/Notebook";
import PortalLayout from "../components/layout/portalLayout/PortalLayout";
import BaseLayout from "../components/layout/base/BaseLayout";
import FormTest from "../components/pages/portal/forms/FormTest";
import Questionnaires from "../components/pages/portal/Questionnaires";
import Metric from "../components/pages/portal/Metric";
import TopicsCompleted from "../components/pages/portal/TopicsCompleted";
import CompleteChapter from "../components/pages/portal/CompleteChapter";
import Goals from "../components/pages/portal/Goals";
import PrioritizedTopics from "../components/pages/portal/PrioritizedTopics";
import MetricsOverview from "../components/pages/portal/MetricsOverview";
import AccountHelp from "../components/pages/AccountHelp";
import FinalOverview from "../components/pages/portal/FinalOverview";
import ManualQuestionnaires from "../components/pages/portal/ManualQuestionnaires";
import Library from "../components/pages/portal/Library";

const Routes: Array<CustomRouteProps> = [
    {
        Component: Landing,
        path: "/",
        exact: true,
        title: i18n.t("landing"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: Login,
        path: "/login",
        exact: true,
        title: i18n.t("routeLogin"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: Register,
        path: "/register",
        exact: true,
        title: i18n.t("register"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: AccountHelp,
        path: "/accountHelp",
        exact: true,
        title: i18n.t("accountHelp"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: Home,
        path: "/home",
        exact: true,
        title: i18n.t("home"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Chapter,
        path: "/chapter/:chapterId",
        exact: false,
        title: i18n.t("chapter"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Chapter,
        path: "/fullscreenChapter/:chapterId/:hasMenu/:isLibrary",
        exact: false,
        title: i18n.t("chapter"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: CompleteChapter,
        path: "/completed/:chapterId/:isLibrary",
        exact: false,
        title: i18n.t("completeChapter"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: CompleteChapter,
        path: "/completed/:chapterId",
        exact: false,
        title: i18n.t("completeChapter"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Goals,
        path: "/goals",
        exact: true,
        title: i18n.t("goals"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: PrioritizedTopics,
        path: "/prioritizedTopics",
        exact: true,
        title: i18n.t("prioritizedTopics"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Profile,
        path: "/profile",
        exact: true,
        title: i18n.t("yourProfile"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Metric,
        path: "/profile/:topic",
        exact: false,
        title: i18n.t("yourProfile"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Help,
        path: "/help",
        exact: true,
        title: i18n.t("help"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Topics,
        path: "/topics",
        exact: true,
        title: i18n.t("topics"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Topic,
        path: "/topic/:topic",
        exact: true,
        title: i18n.t("topic"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: TopicsFavourited,
        path: "/favouritesTopics",
        exact: true,
        title: i18n.t("favouriteTopics"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: TopicsCompleted,
        path: "/completedTopics",
        exact: true,
        title: i18n.t("completedTopics"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: MetricsOverview,
        path: "/overview",
        exact: false,
        title: i18n.t("overview"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: FinalOverview,
        path: "/finalOverview",
        exact: false,
        title: i18n.t("finalOverview"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Notebook,
        path: "/notebook",
        exact: true,
        title: i18n.t("myNotebook"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: FormTest,
        path: "/formtest",
        exact: true,
        title: i18n.t("formTest"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Questionnaires,
        path: "/questionnaires/:stage",
        exact: false,
        title: i18n.t("questionnaires"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: ManualQuestionnaires,
        path: "/manualQuestionnaires/:stage",
        exact: false,
        title: i18n.t("questionnaires"),
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Library,
        path: "/library",
        exact: false,
        title: i18n.t("library"),
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
];

export default Routes;
