import moment from "moment";
import "moment/locale/fr-ca";

// Locale
moment.locale(localStorage.getItem("language") === "en" ? "en-ca" : "fr-ca");
const locale = localStorage.getItem("language") === "en" ? "en-ca" : "fr-ca";

export const formatDateTimeStripe = (datetime: Date) => {
    return moment(datetime).format("DD/MM/YY HH:mm");
};

export const formatDateTime = (datetime: Date) => {
    if (localStorage.getItem("language") === "en") {
        return `${moment(datetime).format("MMMM D")}th ${moment(datetime).format("HH:mm:ss")}`;
    } else {
        return moment(datetime).format("DD MMMM HH:mm:ss");
    }
};

export const parseTimestampToDate = (timestamp: any, localeOptions: any = locale) => {
    // if timestamp is in milliseconds
    if (timestamp < 9999999999) timestamp *= 1000;

    if (timestamp._seconds) {
        return new Date(timestamp._seconds * 1000).toLocaleString(locale, localeOptions);
    } else {
        return new Date(timestamp).toLocaleString(locale, localeOptions);
    }
};

export const formatDate = (datetime: Date) => moment(datetime).format("DD/MM/YYYY");

export const formatDateIntoElapsedTime = (date: Date) => {
    if (typeof date == "number") {
        const timestamp: any = date;
        const timestampInMilliseconds = timestamp;
        const newDate = new Date(timestampInMilliseconds);
        date = newDate;
    }
    const MINUTE_IN_SECONDS = 60;
    const HOUR_IN_SECONDS = 360;
    const DAY_IN_SECONDS = 86400;

    const now = Date.now();
    const diff = now - date.getTime();
    const diffSeconds = diff / 1000;

    if (diffSeconds < MINUTE_IN_SECONDS) {
        return `${Math.ceil(diffSeconds)} second${Math.ceil(diffSeconds) > 1 ? "s" : ""} ago`;
    } else if (diffSeconds < HOUR_IN_SECONDS) {
        return `${Math.ceil(diffSeconds / 60)} minute${Math.ceil(diffSeconds / 60) > 1 ? "s" : ""} ago`;
    } else if (diffSeconds < DAY_IN_SECONDS) {
        return `${Math.ceil(diffSeconds / 3600)} hour${Math.ceil(diffSeconds / 3600) > 1 ? "s" : ""} ago`;
    } else {
        return formatDateTime(date);
    }
};

export const calculateAge = (birthday: Date, comparisonDate: Date = new Date()) => {
    const ageDifMs = comparisonDate.getTime() - birthday.getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const weeksBetween = (d1: Date, d2: Date) => {
    const week = Math.floor(Math.abs(d2.getTime() - d1.getTime()) / (7 * 24 * 60 * 60 * 1000));

    // console.log("raw week", Math.abs((d2.getTime() - d1.getTime()) / (7 * 24 * 60 * 60 * 1000)));
    // console.log("week", week);

    if (week <= 49) return week + 2;
    else return 51;
};

export const getNextMonday = (date: Date) => {
    const modifiedDate = new Date(date);

    // Set day to next monday if not already.
    modifiedDate.setDate(modifiedDate.getDate() + ((1 + 7 - modifiedDate.getDay()) % 7 || 7));

    // Set the time to be exactly the same as received date.
    modifiedDate.setHours(0, 0, 0, 0);

    return modifiedDate;
};

export const getLastMonday = (date: Date) => {
    if (date.getDay() !== 1) {
        const modifiedDate = new Date(date);

        // Set day to next monday if not already.
        modifiedDate.setDate(modifiedDate.getDate() - ((1 + 7 - modifiedDate.getDay()) % 7 || 7));

        // Set the time to be exactly the same as received date.
        modifiedDate.setHours(date.getHours());

        return modifiedDate;
    } else return date;
};

export const getTimeBetween2Dates = (date1: any, date2: any) => {
    let diffInMilliSeconds = Math.abs(date2 - date1) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds) % 60;
    diffInMilliSeconds -= seconds * 60;

    let difference = "";
    if (days > 0) difference += days === 1 ? `${days} day, ` : `${days} days, `;
    if (hours !== 0) difference += `${hours} hour${hours > 1 ? "s" : ""}, `;
    if (minutes !== 0) difference += `${minutes} minute${minutes > 1 ? "s" : ""}, `;
    difference += `${seconds} second${seconds > 1 ? "s" : ""}`;

    return difference;
};

export const getMillisecondsFrom2Dates = (date1: any, date2: any) => {
    return Math.abs(date2 - date1) / 1000;
};

export const getTotalTimeFromMilliseconds = (ms: number) => {
    let diffInMilliSeconds = ms;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds) % 60;
    diffInMilliSeconds -= seconds * 60;

    let difference = "";
    if (days > 0) difference += days === 1 ? `${days} day, ` : `${days} days, `;
    if (hours !== 0) difference += `${hours} hour${hours > 1 ? "s" : ""}, `;
    if (minutes !== 0) difference += `${minutes} minute${minutes > 1 ? "s" : ""}, `;
    difference += `${seconds} second${seconds > 1 ? "s" : ""}`;

    return difference;
};
