import React, { useState } from "react";
import { useSnackbar } from "notistack";

// Mui core
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";

// Forms
import { selfEfficacy, dementiaLiteracy, attitudesTowardDementia } from "./attitudeAwareness/attitudeAwarenessForms";
import { usability, acceptability } from "./usabilityAcceptability/usabilityAcceptabilityForms";
import { cognitiveEngagement, yourNutrition, visionHearing } from "./lifestyle/lifestyleForms";

// Custom components
import AttitudeAwarenessFormGenerator from "./attitudeAwareness/AttitudeAwarenessFormGenerator";
import UsabilityAcceptabilityFormGenerator from "./usabilityAcceptability/UsabilityAcceptabilityFormGenerator";
import PhysicalActivityForm from "./lifestyle/PhysicalActivityForm";
import SleepForm from "./lifestyle/SleepForm";
import SocialPsychologicalHealthForm from "./lifestyle/SocialPsychologicalHealthForm";
import VascularHealthForm from "./lifestyle/VascularHealthForm";
import { getScoreByQuestion } from "../../../../utils/score";
import { Participant } from "../../../../interfaces/Participant";

const Home: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState<any>({
        selfEfficacy: false,
        dementiaLiteracy: false,
        attitudesTowardDementia: false,
        usability: false,
        acceptability: false,
        physicalActivity: false,
        cognitiveEngagement: false,
        yourNutrition: false,
        sleep: false,
        social: false,
        vascular: false,
        visionHearing: false,
    });

    const handleComplete = (title: string, data: any, points: number, topic: string, participant?: Participant | null) => {
        try {
            console.log("-------------------------------------------");
            console.log("title: ", title);
            console.log("raw data: ", data);
            console.log("total: ", points);
            enqueueSnackbar(`${points} points`, { variant: "success" });

            Object.entries(data)
                .sort((a, b) => a[0].localeCompare(b[0], "en", { numeric: true }))
                .forEach((e: any) => {
                    const localPoints = getScoreByQuestion(topic, parseInt((e[0] as string).replace("question", "")), data, participant);
                    console.log(e[0] + ": ");
                    console.log("- answer: ", e[1].toString());
                    console.log("- points: ", localPoints === "n/a" ? 0 : localPoints);
                });

            console.log("-------------------------------------------");
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            {/* ---------------------------------- Controls ---------------------------------- */}
            <Grid container item>
                <Grid xs={12}>
                    <h6>Attitude Awareness</h6>
                </Grid>
                <Grid xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{selfEfficacy.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    selfEfficacy: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.selfEfficacy}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{dementiaLiteracy.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    dementiaLiteracy: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.dementiaLiteracy}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{attitudesTowardDementia.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    attitudesTowardDementia: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.attitudesTowardDementia}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <h6>Lifestyle</h6>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>Physical Activity</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    physicalActivity: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.physicalActivity}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>{cognitiveEngagement.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    cognitiveEngagement: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.cognitiveEngagement}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>{yourNutrition.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    yourNutrition: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.yourNutrition}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>Sleep</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    sleep: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.sleep}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>Social & Phychological health</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    social: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.social}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>Vascular Health</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    vascular: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.vascular}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>{visionHearing.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    visionHearing: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.visionHearing}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <h6>Usability / Acceptability</h6>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>{usability.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    usability: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.usability}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl>
                        <FormLabel>{acceptability.titleEN}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    acceptability: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.acceptability}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>

            {/* ---------------------------------- Forms ---------------------------------- */}
            {values && values.selfEfficacy && <AttitudeAwarenessFormGenerator form={selfEfficacy} onComplete={handleComplete} />}
            {values && values.dementiaLiteracy && <AttitudeAwarenessFormGenerator form={dementiaLiteracy} onComplete={handleComplete} />}
            {values && values.attitudesTowardDementia && (
                <AttitudeAwarenessFormGenerator form={attitudesTowardDementia} onComplete={handleComplete} />
            )}
            {values && values.physicalActivity && <PhysicalActivityForm onComplete={handleComplete} />}
            {values && values.cognitiveEngagement && <AttitudeAwarenessFormGenerator form={cognitiveEngagement} onComplete={handleComplete} />}
            {values && values.yourNutrition && <AttitudeAwarenessFormGenerator form={yourNutrition} onComplete={handleComplete} />}
            {values && values.sleep && <SleepForm onComplete={handleComplete} />}
            {values && values.social && <SocialPsychologicalHealthForm onComplete={handleComplete} />}
            {values && values.vascular && <VascularHealthForm onComplete={handleComplete} />}
            {values && values.visionHearing && <AttitudeAwarenessFormGenerator form={visionHearing} onComplete={handleComplete} />}
            {values && values.usability && <UsabilityAcceptabilityFormGenerator form={usability} onComplete={handleComplete} />}
            {values && values.acceptability && <UsabilityAcceptabilityFormGenerator form={acceptability} onComplete={handleComplete} />}
        </>
    );
};

export default Home;
