import React from "react";

// Mui
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// Component
import TableLoader from "./TableLoader";

interface Props {
    data?: any[];
    loading?: boolean;
    rows?: any[];
    count?: number;
    noMoreData?: boolean;
}

const SimpleTableWithoutPagination: React.FC<Props> = ({ data = [], loading = false, rows = [], count = data.length, noMoreData = false }) => {
    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {!!rows.length &&
                            rows.map(r => (
                                <TableCell key={r.header} align="center">
                                    {r.header}
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading || !count ? (
                        <TableLoader data={data} loading={loading} noMoreData={noMoreData} />
                    ) : (
                        data.map(row => (
                            <TableRow hover className="onHover" key={row.id}>
                                {!!rows.length &&
                                    rows.map(r => (
                                        <TableCell align="center" key={r.header}>
                                            {r.body(row)}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default SimpleTableWithoutPagination;
